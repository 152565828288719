import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Button, TextField, Tooltip, CircularProgress, Link, Divider, Tabs, Tab, Paper, InputAdornment} from '@material-ui/core';
import {connect} from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClientListComponent from './ClientListComponent';
import ClientDetails from './clients_info/ClientDetails';
import AddIcon from "@material-ui/icons/Add";
import ClientCustomFieldComponent from './ClientCustomFieldComponent';
import {AccessLevel} from '../util/AppUtil';
import ContactComponent from './clients_info/ContactComponent';
import SetupCompanyDrawer from './SetupCompanyDrawer';
import {setHeightForComponent} from '../util/AppUtil';
import { usePropsContext } from './context';
import useRestoreComponentState from './useRestoreComponentState';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
    },
    container: {
        marginTop: "8px"
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    MuiButtonlabel : {
        fontSize: "12px"
    }, 
    buttonPlusIcon: {
        //marginLeft:'8px', 
        marginRight: "8px",
        fontSize:'1.2rem', 
        alignItems:'center'
    },    
    buttonStyle : {
        justifyContent:'center', 
        padding:'8px 15px',
        marginRight:'8px'
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    iconAttach: {
        marginLeft:'6px', 
        fontSize:'0.8rem', 
        height:'20px', 
        color:'white'
    },
    }
));

const ButtonInTabs = ({ className, onClick, children, disabled }) => {
    return <Button 
                className={className} 
                onClick={onClick} 
                children={children}
                disabled={disabled}
                style={{color:'white'}}
            />;
};

const ClientsComponent = (props) => {
    const classes = useStyles();
    const is_email_verified = props.selectedAccount?.is_email_verified;
    const isMount = useIsMount();

    const [value, setValue] = useState(0);
    const [showClientInfo, setShowClientInfo] = useState(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawer1, setOpenDrawer1] = useState(false);
    const [openContactDrawer, setOpenContactDrawer] = useState(false);
    const [openCompanySetupDrawer, setOpenCompanySetupDrawer] = useState(false);
    const [clientComponentObj, setClientComponentObj] = useState(undefined);
    const [scrollContainer, setScrollContainer] = useState(null);

    const { fullName, handleClientDrawerOpen, invoicesComponentState, transactionsComponentState } = usePropsContext();

    const { restoreState } = useRestoreComponentState(
        invoicesComponentState || transactionsComponentState, 
    );

    const restoredState = restoreState();

    useEffect(() => {
        if(Object.keys(restoredState).length > 0 && scrollContainer) {
            setShowClientInfo(restoredState?.clientInfo);
            console.log('clientScollPosition', restoredState?.clientScrollPosition)
            scrollContainer.scrollTop = restoredState?.clientScrollPosition
        }
    }, [restoredState, scrollContainer]);

    // useEffect(() => {
    //         if(showClientInfo && Object.keys(restoredState).length > 0) {
    //             scrollContainer.scrollTop = restoredState?.clientScrollPosition
    //         }
    // }, [showClientInfo, restoredState])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        document.title = `Clients - ${fullName}`;
    }, []);

    
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const handleDrawerOpenClose = (open) => {
        // if(value === 0) { 
        //     setOpenDrawer(open);
        // } else {
        //     setOpenDrawer1(open)
        // }
        setOpenDrawer1(open)
    }

    const handleContactDrawerOpen = () => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenContactDrawer(true);
    }

    const handleContactDrawerClose=()=>{
        setOpenContactDrawer(false);
    }

    const handleOpenCompanySetupDrawer = () => {// open company setup
        setOpenCompanySetupDrawer(true);
    }

    const handleCloseCompanySetupDrawer = () => {// close company setup
        setOpenCompanySetupDrawer(false);
    }

    return (
        <div className={classes.mainContent} 
            style={{height:'100%'}}
            ref={(node) => setScrollContainer(node)}>
                {!showClientInfo ?
                <Container className={classes.container} maxWidth="xl" style={{padding: "0px 12px"}}>

                    <div style={{marginTop:'16px'}}>
                    <Grid item container direction="column" style={{border:"1px solid rgba(0,0,0,0.12)", borderBottom:"0px"}}>
                        <Grid item container>
                            <Paper className={classes.tab} elevation={1}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Clients" />
                                    <Tab label="Custom Fields" />
                                    <Tab label="Contacts" />

                                    <Grid item container spacing={1} justifyContent='flex-end'>
                                        <Grid item>
                                            {value === 0 && !accessLevelViewer &&
                                            <ButtonInTabs
                                                onClick={handleClientDrawerOpen}
                                                className={classes.addButton}
                                                disabled={accessLevelViewer}
                                            >

                                                <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                                Add Client
                                            </ButtonInTabs>}
                                        </Grid>

                                        <Grid item>
                                            {value === 0 && !accessLevelViewer &&
                                            <ButtonInTabs
                                                onClick={handleOpenCompanySetupDrawer}
                                                className={classes.addButton}
                                                disabled={accessLevelViewer}
                                            >
                                                Import From Excel Sheet
                                                <i className={`flaticon-attachment ${classes.iconAttach}`}></i>
                                            </ButtonInTabs>}
                                        </Grid>
                                    </Grid>
                                            
                                    <Grid item>
                                        {value === 1 && !accessLevelViewer &&
                                        <ButtonInTabs
                                            onClick={() => handleDrawerOpenClose(true)}
                                            className={classes.addButton}
                                            disabled={accessLevelViewer}
                                        >
                                        <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                            Add Custom Field
                                        </ButtonInTabs>}
                                    </Grid>

                                    <Grid item>
                                        {value === 2 && !accessLevelViewer &&
                                        <ButtonInTabs
                                            onClick={handleContactDrawerOpen}
                                            className={classes.addButton}
                                        >
                                        <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                            Add Contact
                                        </ButtonInTabs>}
                                    </Grid>
                                </Tabs>
                            
                            </Paper>
                        </Grid>
                        <Grid item xs={12}
                            style={{backgroundColor:"white", display:'flex', marginBottom:"1rem"}}>
                            {value === 0 && 
                            <ClientListComponent openDrawer={openDrawer}
                                showClientInfo={showClientInfo}
                                setShowClientInfo={setShowClientInfo}
                                accessLevelViewer={accessLevelViewer}
                                setClientComponentObj={setClientComponentObj}
                                scrollContainer={scrollContainer}
                            />}
                            {value === 1 && 
                                <ClientCustomFieldComponent openDrawer1={openDrawer1}
                                    handleCloseFilterDrawer={handleDrawerOpenClose}
                                    accessLevelViewer={accessLevelViewer}
                                    />}

                            {value === 2 && 
                                <ContactComponent fromContactsComponent={true}
                                    openContactDrawer={openContactDrawer}
                                    handleContactDrawerOpen={handleContactDrawerOpen}
                                    handleContactDrawerClose={handleContactDrawerClose}
                                />}
                        </Grid>
                    </Grid>
                </div>
                </Container>
                :
                <ClientDetails showClientInfo={showClientInfo} 
                    setShowClientInfo={setShowClientInfo}
                    handleInvoiceDialogOpen={props.handleInvoiceDialogOpen}
                    updateTranscItemFromParent={props.updateTranscItemFromParent}
                    setUpdateTranscItemFromParent={props.setUpdateTranscItemFromParent}
                    setAddTransaction={props.setAddTransaction}
                    addTransaction={props.addTransaction}
                    is_entity_email_unverified={props.is_entity_email_unverified}
                    updatedInvoiceObj={props.updatedInvoiceObj}
                    setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                    clientComponentObj={clientComponentObj}
                    scrollContainer={scrollContainer}
                    setScrollContainer={setScrollContainer}
                />
            }

            {
                value === 0 &&
                <SetupCompanyDrawer openCompanySetupDrawer={openCompanySetupDrawer}
                    handleCloseCompanySetupDrawer={handleCloseCompanySetupDrawer}
                    //clientList={clientList}
                />
            }
        </div>

    )
};

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
})

export default connect(mapStateToProps)(ClientsComponent);