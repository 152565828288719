import React, {useEffect, useState, createRef, useRef, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Container, Typography, TextField, Button, Avatar, Popper, 
    ClickAwayListener, CircularProgress, Link, Paper, Popover, MenuItem, InputAdornment,
    Tooltip} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {setCreateInvoiceAccount, setInvoiceItemObj, setCustomerObject} from '../actions/invoiceAccount';
import {connect} from 'react-redux';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { getInvoiceNumberApi, createInvoiceApi, updateInvoiceApi, getItemListApi } from '../services/authService';
import { consoleToLog, parseString, displayInitials} from '../util/AppUtil';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SendIcon from '@material-ui/icons/Send';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { logout } from '../actions/invoiceAuth';
import { setClearInvoiceAccounts, setEditInvoiceObj, setShowSendInvoiceScreenType, SendEmailType, setShowPreviewInvoiceScreen } from '../actions/invoiceAccount';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddEditInvoiceItemsDrawer from './AddEditInvoiceItemsDrawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent, AccessLevel, DiscountTypes} from '../util/AppUtil';
import CloseIcon from '@material-ui/icons/Close';
import Calender from './Calender';
import DatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AddEditClientDrawer from './AddEditClientDrawer';
import country from "../data/country.json";
import state from "../data/state.json";
import InvoiceModalComponent from './modals/InvoiceModalComponent';
import { useIsMount } from './useIsMount';
import {getTDSTypeListApi} from '../services/authService';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {otherTaxesTypes, intraStateTaxesArr, interStateTaxesArr} from '../util/AppUtil';
import { setEntityObj } from '../actions/invoiceAccount';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {updateEntityEsignApi} from '../services/authService';
import EnableInvoiceESignModal from './modals/EnableInvoiceESignModal';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import {getImageDownloadUrlApi} from '../services/authService';
import RecurringInvoiceSettings from './RecurringInvoiceSettings';
import {enableInvoiceRecurringDateApi} from '../services/authService';
import {updateInvoiceEntityEsignApi} from '../services/authService';
import IconButton from '@material-ui/core/IconButton';
import { onlyNumberValidate } from '../util/AppUtil';
import UpdateNumberModal from './modals/UpdateNumberModal';
import {getNewInvoiceNumberApi} from '../services/authService';
import countries from '../data/countries.json';
import Flags from "country-flag-icons/react/3x2";
import { currencySymbols } from '../util/currencyUtil';
import {getCurrencyObjApi} from '../services/authService';
import { extractFlagCode } from '../util/AppUtil';
import { formatAmount } from '../util/currencyUtil';
import { isInvoiceAccountCountryIndia, getUrlAccordingToSelectedComponent } from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import SetUPIDrawer from './SetUPIDrawer';
import { QRCode } from 'react-qr-code';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Divider} from '@material-ui/core';
import EnableDisableReminders from './EnableDisableReminders';
import { InoviceStatus } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "16px",
        marginBottom:'32px',
        padding:'8px 8px 8px 16px'
    },
    createFormContainer : {
        background:'white',
        padding:'0px 0px 32px 0px',
        borderRadius:'5px'
    },
    firstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'10px 0px',
        display: 'flex',
        alignItems:'center',
        flexWrap: 'wrap'
    },
    backIconContainer : {
        flexBasis:'42%',
        maxWidth:'42%,',
        // [theme.breakpoints.down(1400)] : {
        //     flexBasis:'43%',
        //     maxWidth:'43%'
        // },
        // [theme.breakpoints.down(1350)] : {
        //     flexBasis:'45.5%',
        //     maxWidth:'45.5%'
        // }
    },
    backIcon: {
        margin:'3px 0px 0px 20px',
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
    },
    secondRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'18px 16px 18px 24px'
    },
    email: {
        padding:'4px',
        borderRadius:'4px',
        border:'1px solid rgba(0,0,0, .1)',
        fontSize:'14px'
    },
    thirdRow: {
        padding:'24px 24px 0px 24px'
    },
    customerName: {
        color:'#4a5568',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '26px',
        display:'flex',
        alignItems:'center'
    },
    address : {
        width:'40ch'
    },
    avatarStyle: {
        width:"80px",
        height:"80px",
        fontSize:'35px',
        fontWeight:'600'
    },
    invoiceItemsHeadings: {
        marginTop: '8px',
        flexBasis:'95%',
        maxWidth:'95%',
        marginBottom:'4px',
        background:'#eaeaea', 
        padding: '4px 0px 4px 8px',
    },
    invoiceHeading : {
        fontSize:'32px',
        fontWeight:'600',
        [theme.breakpoints.down(1400)] : {
            fontSize:'27px',
            maxWidth:'50%'
        }

    },
    invoiceItems: {
        //background: '#f6f8fb',
        //padding:'8px 0px 8px 8px',
        borderRadius:'4px',
        flexBasis:'95%',
        maxWidth:'95%',
    },
    invoiceItemsContainer : {
        marginTop:'12px',
        marginBottom:'8px',
        '&:last-child' : {
            marginBottom:'0px !important'
        }
    },
    taxPresetHeading : {
        letterSpacing:'0.8px',
        fontSize:'13px',
        fontWeight:'600'
    },
    secondColumnContainer: {
        marginLeft:'16px',
        flexBasis:'23%',
        maxWidth:'23%'
    },
    dateLabel : {
        marginRight:'8px'
    },
    errorText: {
        fontSize:'12px', 
        color:'red', 
        position:'relative',
        top:'-6px',
        left:'4px'
    },
    buttonStyles: {
        marginBottom:'8px',
    },
    MuiTextFieldroot: {
        background:'#fff',
        '& .MuiOutlinedInput-input': {
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    },
    addLineButton: {
        borderRadius:'6px',
        fontWeight:'500 !important'
    },
    addIconStyle: {
        margin: '-0.8px -5px 0px 0px',
        fontSize:'16px !important'
    },
    paymentClientLabelStyles: {
        color: '#4a5568',
        fontSize:'14px',
        fontWeight: 400,
        lineHeight: '1.2rem !important',
    },
    taxesLabel: {
        marginRight: '6px',
        color: '#4c51bf',
        fontSize:'14px',
        fontWeight: 500
    },
    clientOwnerName: {
        color: '#4a5568',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '26px',
    },
    dateContainerColumn: {
        paddingRight:'16px',
        flexBasis:'58%',
        maxWidth:'58%,',
        // [theme.breakpoints.down(1400)] : {
        //     paddingRight:'0px',
        //     flexBasis:'55%',
        //     maxWidth:'55%'
        // },
        // [theme.breakpoints.down(1350)] : {
        //     paddingRight:'0px',
        //     flexBasis:'53%',
        //     maxWidth:'53%'
        // },
        // [theme.breakpoints.down(1300)] : {
        //     paddingRight:'8px',
        //     flexBasis:'54.5%',
        //     maxWidth:'54.5%'
        // },
    },
    invoiceText: {
        flexBasis:'36%',
        maxWIdth:'36%'
    },
    invoiceNumberStyle: {
        width: '80%',
        marginTop:'-1px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0px 3px',
        borderRadius: '4px',
        background:'#fff',
        '& .MuiAutocomplete-input' : {
            padding:'7.5px 4px'
        }
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    createItemButton: {
        margin:'16px auto 4px 6px', 
        width:'95%', 
        color:'#1bac3b', 
        borderColor:'#d4d8de'
    },
    createItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
    textInline: {
        display:'inline'
    },
    moreHorizIconStyle: {
        marginLeft:'8px', 
        color:'#4a5568',
        cursor:'pointer'
    },
    moreActionIconStyle: {
        color: '#b5bbc2',
        marginRight:'6px'
    },
    customMarginLeft: {
        marginLeft:'auto'
    },
    labelRoot: {
        right: 0,
    },
    shrink: {
        transformOrigin: "top right"
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    discountStyle: {

    },
    MuiTabsroot: {
        display:'flex',
        alignItems:'center',
        background:'#f0f3f7',
        borderRadius:'3px',
        width:"100%",
        minHeight:"38px !important"
    },
    MuiTabroot: {
        fontSize:'13px',
        fontWeight:'500',
        color: '#4a5568',
        minHeight:'0px !important',
        padding:'4px 0px !important',
        minWidth: '60px',
    },
    Muiselected: {
        background:'#fff',
        borderRadius:'3px',
        color: '#4a5568 !important',
    },
    MuiTabsindicator: {
        backgroundColor: 'transparent !important',
        boxShadow: '0 1px 1px rgba(74, 85, 104, .2)',
        '& .PrivateTabIndicator-root-29' : {
            backgroundColor: '#fff !important'
        }
    },
    dollarStyle: {
        color: '#9fa6b2',
        fontSize:'18px'
    },
    percentStyle: {
        color: '#9fa6b2',
        fontSize:'15px'
    },
    MuiTextFieldroot1: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '6px'
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: '6px'
        }
    },
    discountTextStyle: {
        color: '#0da72f !important'
    },
    discountButton: {
        color: '#4c51bf',
        fontSize: '14px',
        cursor: 'pointer',
        marginTop:'8px',
        fontWeight:500
    },
    rupeeSymbolStyle: {
        color: '#9fa6b2',
        fontSize:'17px'
    },
    moreHorizIconStyle1: {
        marginLeft:'8px', 
        color:'#4a5568',
        cursor:'pointer',
        position:'relative',
        top:'4px'
    },
    billToStyle: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '13px',
        color: '#4a5568',
        lineHeight: '26px',
        fontWeight: 400
    },
    eSignStyle: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'192px',
        height:'72px',
        padding:'16px',
        borderRadius:'5px',
        border:'1px solid rgba(0,0,0,0.1)',
        boxSizing:"border-box"
    },
    customColumn1: {
        maxWidth:'28%',
        flexBasis:'28%'
    },
    customColumn2: {
        maxWidth:'10%',
        flexBasis:'10%'
    },
    customColumn3: {
        maxWidth:'11%',
        flexBasis:'11%'
    },
    settingsButton: {
        fontSize:'17px',
        position:"relative",
        top:'1px',
        left:'5px',
        cursor:'pointer',
        '&:hover' : {
            color:'#007bfb'
        }
    },
    customColumn4: {
        maxWidth:'28%',
        flexBasis:'28%'
    },
    setUpiButton: {
        position:"absolute",
        top:'45px',
        left:'28px',
        padding:0,
        fontSize:'13px'
    },
    taxItems: {
        width:'auto',
        display: 'inline-block',
        border: '1px solid transparent',
        borderRadius: '6px',
        background: '#e3e8f5',
        fontSize: '9px',
        color: '#3c51be',
        whiteSpace: 'nowrap',
        fontWeight: 500,
        marginLeft:'8px',
        '&:first-child' : {
            marginLeft:'0px'
        },
        '&:hover': {
            background: 'rgba(60, 82, 190, 0.108)'
        }
    },
    itemHeading: {
        fontSize:'14px',
        fontWeight:500,
        fontSize: '13px',
        fontWeight: 600,
        color:' #333333'
    },
    binIcon: {
        color: '#e5e7eb',
        cursor: 'pointer',
        position: 'relative',
        top:'4px',
        '&:hover': {
            color:"red"
        }
    },
    dividerForItem: {
        margin: '24px 0px 16px 0px',
        '&:last-child': {
            display:'none'
        }
    },
    titleColumns: {
        maxWidth:'39%',
        flexBasis:'39%'
    }
}))

const CreateUpdateInvoiceComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    const focusTextInput = createRef();
    const myDivToFocus = createRef();
    const focusDiscountRef = useRef();

    const [showAddTaxMenu, setShowAddTaxMenu] = useState(false);
    const [dropdownIndex, setDropDownIndex] = useState(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showPaymentTerms, setShowPaymentTerms] = useState(props.selectedAccount?.display_settings.show_payment_terms);
    const [showClientNotes, setShowClientNotes] = useState(props.selectedAccount?.display_settings.show_client_notes);
    const [showPaymentStub, setShowPaymentStub] = useState(props.selectedAccount?.display_settings.show_payment_stub);
    const [previewAndSend, setPreviewAndSend] = useState(false);
    const [openAddEditItemsDrawer, setOpenAddEditItemsDrawer] = useState(false);
    const [showUpiQrCode, setShowUpiQrCode] = useState(true);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const [invoiceDescription, setInvoiceDescription] = useState('');
    const [itemDetails, setItemDetails] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState(0);
    const [errorType, setErrorType] = useState(0);
    //1 invoice description error
    const [errorMessage, setErrorMessage] = useState('');
    const [itemIndex, setItemIndex] = useState(itemDetails.length);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [itemList, setItemList] = useState([]);
    //const [tdsTypeList, setTdsTypeList] = useState([]);
    const [tdsAmount, setTDSAmount] = useState(0);
    const [tdsTypeObj, setTDSTypeObj] = useState(null);
    const [fullInvoiceDiscount, setFullInvoiceDiscount] = useState([{
                                                                    type: DiscountTypes.PERCENT,
                                                                    value: parseFloat(0.00),
                                                                    amount: parseFloat(0.00)
                                                                    }]);
    const [taxPresetsArr, setTaxPresetsArr] = useState([]);
    const [tcsAmount, setTCSAmount] = useState(0);
    const [tcsTypeObj, setTCSTypeObj] = useState(null);

    const [clientName, setClientName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [clientCountry, setClientCountry] = useState('');
    const [clientState, setClientState] = useState('');
    const [clientMobile, setClientMobile] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientUserDefinedFields, setClientUserDefinedField] = useState('');
    const [editClientObj, setEditClientObj] = useState(undefined);
    const [intervalDays, setIntervalDays] = useState(undefined);

    //discount
    const [showDiscountMenu, setShowDiscountMenu] = useState(false);
    const [showDiscountMenu1, setShowDiscountMenu1] = useState(false);
    const [dropdownIndex1, setDropDownIndex1] = useState(undefined);

    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [discountType, setDiscountType] = useState(DiscountTypes.PERCENT);

    const [openEditClientDrawer, setOpenEditClientDrawer] = useState(false);
    const [openChangeCustomerModal, setOpenChangeCustomerModal] = useState(false);
    const [openChangeEntityModal, setOpenChangeEntityModal] = useState(false);
    const [entityOrCustomerChange, setEntityOrCustomerChange] = useState(false);
    const [openClientDrawer, setOpenClientDrawer] = useState(false);

    const [selectedEntityObj, setSelectedEntityObj] = useState(undefined);
    const [openESignDialog, setOpenESignDialog] = useState(false);
    const [eSignUrl, setESignUrl] = useState(undefined);
    const [originalNumber, setOriginalNumber] = useState(0);
    const [invoiceNumberString, setInvoiceNumberString] = useState(undefined);
    const [openUpdateNumberDialog, setOpenUpdateNumberDialog] = useState(false);
    const [dontCallApi, setDontCallApi] = useState(false);
    const [entityNextInvoiceNumber, setEntityNextInvoiceNumber] = useState(0);
    const [oldIssueDate, setOldIssueDate] = useState('');
    const [isCurrentFinancialYear, setIsCurrentFinancialYear] = useState(true);
    const [referenceNumber, setReferenceNumber] = useState('');
    
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const [issuedDate, setIssuedDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [clientNotes, setClientNotes] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [emails, setEmails] = useState([]);

    const [showUpiDrawer, setShowUpiDrawer] = useState(false);

    let other_taxes = otherTaxesTypes(props.selectedAccount?.tax_presets);
    let inter_state_taxes = interStateTaxesArr(props.selectedAccount?.tax_presets);
    let intra_state_taxes = intraStateTaxesArr(props.selectedAccount?.tax_presets);

    
    useEffect(() => {
        getItemList();
        setTaxPresetsArrForItems();
    }, []);

    useEffect(() => {
        if(myDivToFocus.current) {
            myDivToFocus.current.scrollIntoView({
                scrollY:'0px'
            })
        }
    }, [myDivToFocus.current]);

    useEffect(() => {
        const client_contact_array = Object.keys(props.customerObj).length !== 0 ? props.customerObj.contacts : props.editInvoiceObj.client?.contacts;
        const client_email = client_contact_array && client_contact_array.length > 0 ? client_contact_array.map((client) => client.email) : props.editInvoiceObj?.email_array;

        if(Object.keys(props.editInvoiceObj).length === 0) {
            setOldIssueDate(moment().format("YYYY-MM-DD"));
            setIssuedDate(moment().format("YYYY-MM-DD"));
            setDueDate(moment().add(7,'days').format('YYYY-MM-DD'));
            setPaymentTerms(props.entityObj?.default_payment_terms);
            setClientNotes(props.entityObj?.default_client_notes);
            getInvoiceNumber(entityObj);
            getCurrencies(currenciesObj?.name, true);

            setClientName(props.customerObj?.name);
            setClientAddress(props.customerObj?.address);
            setClientCountry(props.customerObj?.country);
            setClientState(props.customerObj?.state);
            setClientMobile(props.customerObj?.phone_number);
            setClientEmail(client_email && client_email.length > 0 ? client_email : []);
            setEmails(client_email && client_email.length > 0 ? client_email : []);
            setClientUserDefinedField(props.customerObj?.user_defined_field);
           //setSelectedCurrency(countries_currency.find(country => country.code === props.selectedAccount?.country));
        } else {
                //let pt = (payTerms !== '') ? payTerms : defaultPaymentTerms;
                //let cn = (clNotes !== '') ? clNotes : defaultClientNotes;
                getNewInvoiceNumber(moment(props.editInvoiceObj?.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
                setOldIssueDate(moment(props.editInvoiceObj?.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
                setIssuedDate(moment(props.editInvoiceObj?.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
                setDueDate(moment(props.editInvoiceObj?.due_date, "YYYY-MM-DD").format("YYYY-MM-DD"))
                setInvoiceNumber(props.editInvoiceObj?.invoice_sequence_str);
                setInvoiceDescription(props.editInvoiceObj?.invoice_description);
                setPaymentTerms(props.editInvoiceObj?.entity?.default_payment_terms);
                setClientNotes(props.editInvoiceObj?.entity?.default_client_notes);
                setItemIndex(props.editInvoiceObj?.invoice_items.length - 1);
                setShowPaymentTerms(props.editInvoiceObj?.show_payment_terms);
                setShowClientNotes(props.editInvoiceObj?.show_client_notes);
                setShowPaymentStub(props.editInvoiceObj?.show_payment_stub);
                setTDSTypeObj(props.editInvoiceObj?.tds_type_object);
                setTDSAmount(props.editInvoiceObj?.tds_amount);
                if(props.editInvoiceObj?.discount.length === 0) {
                    setFullInvoiceDiscount([{
                        type: DiscountTypes.PERCENT,
                        value: parseFloat(0.00),
                        amount: parseFloat(0.00)
                    }]);
                } else {
                    setFullInvoiceDiscount(props.editInvoiceObj?.discount);
                }
                setInvoiceNumberFromInvoiceSeqString(props.editInvoiceObj?.invoice_sequence_str);

                setShowUpiQrCode(props.editInvoiceObj?.show_upi_qr_code)

                const invoice_tcs_obj = props.editInvoiceObj?.tcs
                const tcs_obj = props.selectedAccount?.tcs && props.selectedAccount?.tcs.length > 0 ? 
                props.selectedAccount?.tcs?.find((obj) => obj?.name === invoice_tcs_obj?.name) : null;
                
                setTCSTypeObj(tcs_obj);
                setTCSAmount(Number(invoice_tcs_obj?.value ? invoice_tcs_obj?.value : 0));
                setReferenceNumber(props.editInvoiceObj?.reference_number);
                setSelectedCurrency(props.editInvoiceObj?.currencies);

                const invoiceClientObj = props.editInvoiceObj?.client;

                setClientName(invoiceClientObj?.name);
                setClientAddress(invoiceClientObj?.address);
                setClientCountry(invoiceClientObj?.country);
                setClientState(invoiceClientObj?.state);
                setClientMobile(invoiceClientObj?.phone_number);
                setClientEmail(client_email && client_email.length > 0 ? client_email : []);
                setEmails(client_email && client_email.length > 0 ? client_email : []);
                setClientUserDefinedField(invoiceClientObj?.user_defined_field);
            }   
    }, []);

    useEffect(() => {
        if(Object.keys(props.editInvoiceObj).length === 0) {
            const newObj = {
                item_id:'',
                item_name:'',
                item_description:'',
                cost: parseFloat(0.00),
                quantity: Number(0.00),
                hsn_code:'',
                price:  parseFloat(0.00),
                tax:[],
                checklist_ids: [],
                discount_type: DiscountTypes.PERCENT,
                discount_value: parseFloat(0.00),
                discount_amount: parseFloat(0.00)
            }
            setItemDetails([...itemDetails, newObj])
        }  else {
            if(!entityOrCustomerChange) {
                const updated_array = invoice_items && invoice_items.length > 0 ? 
                invoice_items.map((item) => {   
                    if(item.discount && item.discount.length > 0) {
                        item.discount.map((disc, index) => {
                            item.discount_type = disc.type;
                            item.discount_value = disc.value;
                            item.discount_amount = disc.amount;
                            return item
                        })
                        
                    } else {
                        item.discount_type = DiscountTypes.PERCENT;
                        item.discount_value = parseFloat(0.00);
                        item.discount_amount = parseFloat(0.00);
                    }
                    delete item.discount;
                    return item;

                }) : [];

                //console.log('updated array', updated_array);
                setItemDetails(updated_array);
            }
        }
    }, [props.editInvoiceObj]);

    useEffect(() => {
        if(!isMount) {
            if(props.editInvoiceObj?.entity) {
                !dontCallApi && getInvoiceNumber(props.editInvoiceObj?.entity);
                if(props.editInvoiceObj?.entity.country === 'IN' && props.editInvoiceObj?.entity.tax_id && 
                    props.editInvoiceObj?.entity.country !== clientCountry) {

                    if(props.editInvoiceObj?.entity.state === clientState) {
                        setTaxPresetsArr([...intra_state_taxes, ...other_taxes]);
                        const updatedArray = updateTaxFieldForItemDetails(true);
                        setItemDetails([...updatedArray]);
                        setEntityOrCustomerChange(false);
                    }
        
                    if(props.editInvoiceObj?.entity.state !== clientState) {
                        setTaxPresetsArr([...inter_state_taxes, ...other_taxes]);
                        const updatedArray = updateTaxFieldForItemDetails(false);
                        setItemDetails([...updatedArray]);
                        setEntityOrCustomerChange(false);
                    }
                } else {
                    setTaxPresetsArr([...props.selectedAccount?.tax_presets]);
                    const updatedArray = updateTaxFieldForItemDetails(false);
                    setItemDetails([...updatedArray]);
                    setEntityOrCustomerChange(false);
                }
            }
        }
    }, [props.editInvoiceObj?.entity]);

    useEffect(() => {
        if(!isMount) {
            if(props.editInvoiceObj?.client) {
                if(props.editInvoiceObj?.client.state !== props.editInvoiceObj?.entity.state) {
                        setTaxPresetsArr([...inter_state_taxes, ...other_taxes]);
                        const updatedArray = updateTaxFieldForItemDetails(false);
                        setItemDetails([...updatedArray]);
                        setEntityOrCustomerChange(false);
                } else {
                        setTaxPresetsArr([...intra_state_taxes, ...other_taxes]);
                        const updatedArray = updateTaxFieldForItemDetails(true);
                        setItemDetails([...updatedArray]);
                        setEntityOrCustomerChange(false);
                }
            }
        }
    }, [props.editInvoiceObj?.client]);

    useEffect(() => {
        if(props.entityObj !== -1) {
            const selectedObj = props.selectedAccount.entities?.find((entity) => entity.id === props.entityObj?.id);
            setSelectedEntityObj(selectedObj);
        } else {
            const selectedObj = props.selectedAccount.entities?.find((entity) => entity.id === props.editInvoiceObj?.entity?.id);
            setSelectedEntityObj(selectedObj);
        }
    }, [props.entityObj, props.editInvoiceObj?.entity]);


    useEffect(() => {
        if(!isMount) {
            if(selectedEntityObj && !dontCallApi) {
                if(selectedEntityObj?.enable_esign) {
                    getDownloadSignedUrl(selectedEntityObj);
                }
            }
        }
    }, [selectedEntityObj]);

    const invoiceObj = props.editInvoiceObj;
    const hsn_code_4_digit = props.selectedAccount.general_settings?.hsn_code_4_digit;
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const tcsList = props.selectedAccount?.tcs;
    const proforma_enabled = props.selectedAccount.general_settings?.proforma_enabled;
    const currenciesObj = props.selectedAccount?.currencies;
    const invoice_items = props.editInvoiceObj?.invoice_items ;
    const amountPaid = props.editInvoiceObj?.paid;
    const entityObj = props.entityObj !== -1 ? props.entityObj : invoiceObj?.entity;
    const client_contact_array = Object.keys(props.customerObj).length !== 0 ? props.customerObj.contacts : props.editInvoiceObj.client?.contacts;
    const invoiceAccountCountry = props.selectedAccount?.country;
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;
    const reminderObj = invoiceObj?.invoice_reminders;

    //issue date popover
    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'issue-date-popover' : undefined;

    //due date popover
    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'due-date-popover' : undefined;

    //more option popover
    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'edit_client_popover' : undefined;

    //discount popover
    const open4 = Boolean(anchorEl4);
    const id4 = open4 ? 'discount_popover' : undefined;

    //full invoice discount popover
    const open5 = Boolean(anchorEl5);
    const id5 = open5 ? 'full_invoice_discount_popover' : undefined;

    // const currencyFormatter = new Intl.NumberFormat(selectedCurrency?.locale, {
    //     style:'currency',
    //     currency: selectedCurrency?.currency_code ? selectedCurrency?.currency_code : 'INR',
    // });

    const handleIssueDatePopoverClick = (event) => {
        setOldIssueDate(moment(issuedDate).format('YYYY-MM-DD'));
        setAnchorEl1(event.currentTarget);
    };

    const handleIssueDatePopoverClose = () => {
            setAnchorEl1(null);
    }

    const handleDueDatePopoverClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleDueDatePopoverClose = () => {
            setAnchorEl2(null);
    }

    const handleEditClientPopoverClick = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleEditClientPopoverClose = () => {
            setAnchorEl3(null);
    }

    const handleEditClientDrawerOpen = () => {
        setOpenClientDrawer(true);
        setEditClientObj(Object.keys(props.customerObj).length !== 0 ? props.customerObj : props.editInvoiceObj.client);
        setAnchorEl3(null);
    };

    const handleEditClientDrawerClose = () => {
        setOpenClientDrawer(false);
        setEditClientObj(undefined);
    }

    const handleChangeCustomerModalOpen = () => {
        setOpenChangeCustomerModal(true);
        setAnchorEl3(null);
        setEntityOrCustomerChange(true);
    }

    const handleChangeCustomerModalClose = () => {
        setOpenChangeCustomerModal(false)
    }

    const handleShowUpiDrawerOpen = () => {
        setShowUpiDrawer(true)
    }

    const handleShowUpiDrawerClose = () => {
        setShowUpiDrawer(false)
    }

    const handleDueDatePopOver = () => {
        return (<Popover
            id={id2}
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleDueDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender issueDate={issuedDate} 
                dueDate={dueDate} 
                setDueDate={setDueDate}
                handleDueDatePopoverClose={handleDueDatePopoverClose}
                />
        </Popover>)
    }

    const onCustomDateChange = (date) => {
         // let customDate = moment(dates).format('YYYY-MM-DD');
        getNewInvoiceNumber(date);
        setIssuedDate(date);
        setAnchorEl1(null);
    }

    const handleIssueDatePopOver = () => {
        return (<Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleIssueDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <DatePicker
                selected={moment(issuedDate).toDate()}
                onChange={(date) => onCustomDateChange(date)}
                //selectsRange
                inline
            />
        </Popover>)
    }

    const handleEditClientPopOver = () => {
        return (<Popover
            id={id3}
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleEditClientPopoverClose}
            style={{marginTop:'4px'}}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
            <MenuItem 
                onClick={handleEditClientDrawerOpen}
                style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}> 
                <EditOutlinedIcon fontSize='small' className={classes.moreActionIconStyle}/>
                Edit
            </MenuItem>
            {Object.keys(props.editInvoiceObj).length > 0 &&
            <MenuItem
                onClick={handleChangeCustomerModalOpen}
                >
                <PersonOutlineOutlinedIcon
                        fontSize='small' className={classes.moreActionIconStyle}/>
                Change Customer
            </MenuItem> }
        </Popover>)
    }

    const setTaxPresetsArrForItems = () => {
        if(entityObj.country === 'IN' && entityObj.tax_id && entityObj.country === clientCountry) {
            if(entityObj.state === clientState) {
                setTaxPresetsArr([...intra_state_taxes, ...other_taxes]);
            }

            if(entityObj.state !== clientState) {
                setTaxPresetsArr([...inter_state_taxes, ...other_taxes]);
            }
        } else {
            setTaxPresetsArr([...props.selectedAccount?.tax_presets]);
        }
    }

    //console.log('client email', client_email);

    const getInvoiceNumber = (entityObj) => {
        const invoice_account_id = props.selectedAccount?.id;

        getInvoiceNumberApi(invoice_account_id, entityObj?.id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvocieNumber: ', res);

                setInvoiceNumberFromInvoiceSeqString(res.invoice_sequence_str);

            })
            .catch((e) => {
                consoleToLog('Error getInvocieNumber', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
        });
    }

    const getNewInvoiceNumber = (issueDate) => {
        const invoice_account_id = props.selectedAccount?.id;
        const new_issue_date = moment(issueDate).format('YYYY-MM-DD');
        const old_issue_date = oldIssueDate;
        const invoice_type = props.showCreateInvoice ? props.selectedAccount.general_settings?.proforma_enabled ? 'proforma' : 'tax' : 
            props.editInvoiceObj?.invoice_type === 'none' || props.editInvoiceObj?.invoice_type === 'proforma'  ? 'proforma' : 'tax';

        getNewInvoiceNumberApi(invoice_account_id, entityObj?.id, old_issue_date, new_issue_date, invoice_type)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getNewInvoiceNumberApi: ', res);
                
                setIsCurrentFinancialYear(res.is_current_financial_year);
                res.invoice_sequence_str && res.invoice_number && setInvoiceNumberFromInvoiceSeqString(res.invoice_sequence_str);
            })
            .catch((e) => {
                consoleToLog('Error getNewInvoiceNumberApi', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                } 
        });
    }

    const getItemList = () => {
        const invoice_account_id = props.selectedAccount?.id;
        getItemListApi(invoice_account_id)
            .then((respose) => {
                const res = respose.data;
                consoleToLog('Response getItemListApi: ', res);

                setItemList(res);
            })
            .catch((e) => {
                consoleToLog('Error getItemListApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const getCurrencies = (query, setCurrencyObj) => {
        console.log('query', query);
        const invoice_account_id = props.selectedAccount?.id;
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })

    }


    const setInvoiceNumberFromInvoiceSeqString = (sequence_str) => {
        let resultString = sequence_str.replace(/-\d+$/, '');
        setInvoiceNumberString(resultString);

        // Split the string by "-"
        let parts = sequence_str.split("-");
        // Extract the last part
        let lastPart = parts[parts.length - 1];
        setInvoiceNumber(lastPart);
        setOriginalNumber(lastPart);
    }

    const updateTaxFieldForItemDetails = (sameState) => {
        // Create a hash map for quick lookups based on item_id
        const itemListMap = new Map();
        itemList.forEach(item => {
            itemListMap.set(item.id, item.tax);
        });
        // Update tax field in array1 based on array2Map
        const itemDetailsCopy = [...itemDetails];
        itemDetailsCopy.forEach((item, index) => {
            const updatedTax = itemListMap.get(item.item_id);
            const discount_type = item.discount_type;
            const discount_value = isNaN(item.discount_value) ? parseFloat(0.00) : item.discount_value;
            if(isInvoiceAccountCountryIndia(invoiceAccountCountry) && entityObj?.country === 'IN' && entityObj?.tax_id) {
                if (updatedTax !== undefined) {
                    if(sameState) {
                        item.tax = updatedTax.intra_state && updatedTax.intra_state.length > 0 ? updatedTax.intra_state?.map((taxItem) =>{
                                        taxItem.amount = discount_value > 0 ? taxAmountWithDiscount(taxItem.rate, index, discount_type, discount_value) :  taxAmount(taxItem.rate, index)
                                        return taxItem
                                    }) : [];
                    } else {
                        item.tax = updatedTax.inter_state && updatedTax.inter_state.length > 0 ? updatedTax.inter_state?.map((taxItem) =>{
                                        taxItem.amount =  discount_value > 0 ? taxAmountWithDiscount(taxItem.rate, index, discount_type, discount_value) :  taxAmount(taxItem.rate, index)
                                        return taxItem
                                    }) : [];
                    }
                    
                }
            } else {
                item.tax = updatedTax?.states && updatedTax?.states.length > 0 ? updatedTax.states?.map((taxItem) =>{
                    taxItem.amount =  discount_value > 0 ? taxAmountWithDiscount(taxItem.rate, index, discount_type, discount_value) :  taxAmount(taxItem.rate, index)
                    return taxItem
                }) : [];
            }
        });
        
        return itemDetailsCopy;
    }

    // useEffect(() => {
    //     if(previewAndSend) {
    //         onCreateUpdateInvoiceClick(false);
    //     }

    // }, [previewAndSend]);

    const handleDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create And Edit - Add Tax preset button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    const handleAddEditItemDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - create item clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenAddEditItemsDrawer(true)
    }

    const handleAddEditItemDrawerClose = () => {
        setOpenAddEditItemsDrawer(false)
    }

    const onshowPaymentTermsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Show payment terms clicked', `${props.user.firstname} ${props.user.lastname}`);
        setShowPaymentTerms(!showPaymentTerms);
    }

    const itemDetailsValidated = () => {
        if (itemDetails[itemIndex]?.item_name === '') {
            setErrorType(2);
            setErrorMessage('*The name field is required');
            return;
        } else if (itemDetails[itemIndex]?.cost === '') {
            setErrorType(3);
            setErrorMessage('*The cost field is required');
            return;
        } else if (itemDetails[itemIndex]?.quantity === '') {
            setErrorType(4);
            setErrorMessage('*The quantity field is required');
            return;
        } //else if (itemDetails[i].item_description === '') {
        //     setErrorType(5);
        //     setErrorMessage('The description field is required');
        //     break;
        //} 
        else {
            setItemIndex(itemIndex => itemIndex + 1);
            onAddLineClick(); 
        }
    }

    const onAddLineClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Add line button clicked', `${props.user.firstname} ${props.user.lastname}`);
        let newObj = {
                    item_id:'',
                    item_name:'',
                    item_description:'',
                    cost: parseFloat(0.00),
                    quantity: Number(0.00),
                    hsn_code: '',
                    price: parseFloat(0.00),
                    tax:[],
                    checklist_ids: [],
                    discount_type: DiscountTypes.PERCENT,
                    discount_value: parseFloat(0.00),
                    discount_amount: parseFloat(0.00)
                    }
        setItemDetails([...itemDetails, newObj]);
    }

    const onDeleteLine = (index) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Invoice task item deleted', `${props.user.firstname} ${props.user.lastname}`);

        setItemIndex(itemIndex => itemIndex - 1);
        setErrorType(0);
        setErrorMessage('');

        if (index > -1) {
            itemDetails.splice(index, 1);
            setItemDetails([...itemDetails]);
            //getTaxDetails();
        }
    }

    const onAddTaxClick = (event, index) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Add Tax preset clicked', `${props.user.firstname} ${props.user.lastname}`);
    
        setShowAddTaxMenu(!showAddTaxMenu);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setDropDownIndex(index);
        if(dropdownIndex !== undefined && anchorEl) {
            setDropDownIndex(undefined);
            setAnchorEl(null);
        }
    }

    const onTaxItemClick = (taxItems, index) => {
        let updatedArray = itemDetails;
        const discount_type = updatedArray[index].discount_type;
        const discount_value = isNaN(updatedArray[index].discount_value) ? parseFloat(0.00) : updatedArray[index].discount_value;

        let {rate, id, name} = taxItems;
        let newObj = {
            tax_preset_id: id,
            name: name,
            rate: rate,
            amount: discount_value > 0 ? taxAmountWithDiscount(rate, index, discount_type, discount_value) :  taxAmount(rate, index)
        }
        updatedArray[index]?.tax.push(newObj);
        setItemDetails([...updatedArray]);
        //setAnchorEl(null);
        //setDropDownIndex(undefined);
        //setShowAddTaxMenu(false);
        //console.log('itemDetails', itemDetails);
    }


    const taxAmount = (rate, index) => {
        return parseFloat((rate * itemDetails[index].cost * itemDetails[index].quantity) / 100);
    }

    const taxAmountWithDiscount = (rate, index, discount_type, discount_value) => {
        let taxValue = parseFloat((rate * itemDetails[index].cost * itemDetails[index].quantity) / 100);
        return parseFloat(discount_type === DiscountTypes.PERCENT ? taxValue - taxValue * (discount_value / 100) : taxValue - (rate * discount_value)/100)
    }

    const onDeleteAppliedTaxItem = (tax_id, index, invoiceItem) => {
        let updatedArray = itemDetails;
        updatedArray[index].tax = itemDetails[index].tax.filter(taxItem => taxItem.tax_preset_id !== tax_id);
        //console.log('new updated arr', updatedArray);
        setItemDetails([...updatedArray]);
        //setAnchorEl(null);
        //setDropDownIndex(undefined);
        //setShowAddTaxMenu(false);
    }

    const onshowClientNotesClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Show client notes clicked', `${props.user.firstname} ${props.user.lastname}`);
        setShowClientNotes(!showClientNotes);
    }

    const onInvoiceItemNameChanged = (e, invoice, index) => {
        setErrorType(0);
        setErrorMessage('');
        const updatedArray = itemDetails;
        updatedArray[index].item_name = invoice.title;
        setItemDetails([...updatedArray]);
    }

    const onInvoiceItemCostChanged = (e, invoice, index) => {
        setErrorType(0);
        setErrorMessage('');
        const updatedArray = itemDetails;
        updatedArray[index].cost = parseFloat(e.target.value);
        updatedArray[index].discount_amount =  updatedArray[index].discount_type === DiscountTypes.PERCENT ? parseFloat((e.target.value * updatedArray[index].quantity * updatedArray[index].discount_value) / 100) : parseFloat(updatedArray[index].discount_value);
        setItemDetails([...updatedArray]);
        onInvoiceItemPriceChanged(e, invoice, index);
        updateAmountInTax(index, false);
        //getTaxDetails();
    }

    const onInvoiceItemQuantityChanged = (e, invoice, index) => {
        setErrorType(0);
        setErrorMessage('');
        const updatedArray = itemDetails;
        var quantity=0;
        quantity = e.target.value < 0 ? 0 : e.target.value;

        updatedArray[index].quantity = parseFloat(quantity);
        updatedArray[index].discount_amount =  updatedArray[index].discount_type === DiscountTypes.PERCENT ? parseFloat((updatedArray[index].cost * updatedArray[index].quantity * updatedArray[index].discount_value) / 100) : parseFloat(updatedArray[index].discount_value);
        setItemDetails([...updatedArray]);
        onInvoiceItemPriceChanged(e, invoice, index);
        updateAmountInTax(index, false);
        //getTaxDetails();
    }

    const onInvoiceItemPriceChanged = (e, invoice, index) => {
        const updatedArray = itemDetails;
        const discount = updatedArray[index].discount_amount ? updatedArray[index].discount_amount : 0
        updatedArray[index].price = parseFloat((updatedArray[index].cost * updatedArray[index].quantity) - discount);
        setItemDetails([...updatedArray]);
    }

    const onInvoiceItemDescriptionChanged = (e, invoice, index) => {
        setErrorType(0);
        setErrorMessage('');
        const updatedArray = itemDetails;
        updatedArray[index].item_description = e.target.value;
        setItemDetails([...updatedArray]);
    }

    const onInvoiceItemHSNCodeChanged = (e, invoiceItem, index) => {
        const updatedArray = itemDetails;
        updatedArray[index].hsn_code = e.target.value;
        setItemDetails([...updatedArray]);
    }

    const onInvoiceItemDiscountChange =  (e, invoiceItem, index) => {
        const updatedArray = itemDetails;
        const value = e.target.value;
        const type = updatedArray[index].discount_type;
        updatedArray[index].discount_value = parseFloat(value);
        updatedArray[index].discount_amount =  type === DiscountTypes.PERCENT ? parseFloat((updatedArray[index].cost * updatedArray[index].quantity * value) / 100) : parseFloat(value);
    
        setItemDetails([...updatedArray]);
        onInvoiceItemPriceChanged(e, invoiceItem, index);
        updateAmountInTax(index, true);
    }

    const updateAmountInTax = (index, fromPriceChange) => {
        const updatedArray = itemDetails;
        if(fromPriceChange) {
            const discount_type = updatedArray[index].discount_type;
            const discount_value = isNaN(updatedArray[index].discount_value) ? parseFloat(0.00) : updatedArray[index].discount_value;
            const discount_amount = updatedArray[index].discount_amount;

            updatedArray[index].tax = itemDetails[index].tax.map((taxItem, i) => {
                const taxValue = (itemDetails[index].tax[i].rate * updatedArray[index].cost * updatedArray[index].quantity) / 100;
                return {
                    ...taxItem,
                    amount: parseFloat(discount_type === DiscountTypes.PERCENT ? taxValue - taxValue * (discount_value / 100) : taxValue - (itemDetails[index].tax[i].rate * discount_value)/100)
                }
            })
        } else {
            updatedArray[index].tax = itemDetails[index].tax.map((taxItem, i) => {
                    return {
                        ...taxItem,
                        amount: parseFloat((itemDetails[index].tax[i].rate * updatedArray[index].cost * updatedArray[index].quantity) / 100)
                    }
            })
        }
        setItemDetails([...updatedArray]);
    }

    const getTotalPrice = () => {
        const totalPrice = itemDetails.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
        return totalPrice ? totalPrice : 0
    }

    const getTaxDetailsUPdatedArray = (arr, taxItem) => {
        if(taxItem !== undefined) {
            arr.push({
                tax_preset_id: taxItem.tax_preset_id,
                name: taxItem.name,
                rate: taxItem.rate,
                amount: taxItem.amount
            })
        }
        return arr;
    }

    const getTaxDetails = () => {
        let taxDetailsArray = [];
        let updatedArray = itemDetails;
        for(var i=0; i < itemDetails.length; i++) {
            for(var j = 0; j < itemDetails[i].tax?.length; j++) {
                var taxItem = itemDetails[i].tax[j];
                if(taxDetailsArray && taxDetailsArray.length > 0) {
                    let index = taxDetailsArray.findIndex(x => x.tax_preset_id === taxItem.tax_preset_id);
                     //console.log('index', index);
                    if(index === -1) {
                        getTaxDetailsUPdatedArray(taxDetailsArray, taxItem);

                    } else {
                        taxDetailsArray[index].amount = taxDetailsArray[index].amount + taxItem.amount;
                        //console.log('add amount', total_amount);
                    } 
                } else {
                    getTaxDetailsUPdatedArray(taxDetailsArray, taxItem);
                }
            }
        }
        //console.log('updated tax details array 2', taxDetailsArray);
        return taxDetailsArray;
    }

    const getInvoiceTotal = () => {
        let taxTotal = getTaxDetails().map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
        let subtotal = itemDetails.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
        let invoice_discount = fullInvoiceDiscount[0]?.amount;

       // console.log('taxTotal', taxTotal);
        let invoiceTotal = subtotal + taxTotal - invoice_discount;
        return invoiceTotal ? invoiceTotal : 0;
    }
    //console.log('itemDetails array', itemDetails);

    const getDueAmount = () => {
        let amountDue = getInvoiceTotal() - amountPaid;
    
        return amountDue ? amountDue : 0;
    }


    const showTotalTaxUi = () => {
        return ( 
            <>
            {
                getTaxDetails().map((taxItem) => {
                return <Grid item container justifyContent='space-between'
                            key={taxItem.tax_preset_id}
                            className="item_marginTop">
                            <Typography className='list_item_styles'>
                                {taxItem?.name}
                            </Typography>

                            <Typography className='list_item_styles'>
                                {`${isNaN(taxItem?.amount.toFixed(2))? `₹` + 0.00.toFixed(2) : 
                                    formatAmount(taxItem?.amount, selectedCurrency)}`}
                            </Typography>
                        </Grid>
                })
            }
            </>
       )
    }

    const onShowPaymentStubClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Show payment stub clicked',  `${props.user.firstname} ${props.user.lastname}`);
        setShowPaymentStub(!showPaymentStub);
    }

    const downloadableInvoice = (downloadableURL) => {
        //initiate download
        document.getElementById('invoice_download').href = downloadableURL;
        document.getElementById('invoice_download').click();
    }


    const checkInvoiceNumberChangedOrNot = () => {
        if(props.showCreateInvoice) {
            if(Number(originalNumber) !== Number(invoiceNumber) && Number(invoiceNumber) === Number(entityObj.next_invoice_number) || Number(originalNumber) === Number(invoiceNumber)) {
                return false
            } else if(Number(originalNumber) !== Number(invoiceNumber)) {
                return true
            }
        } else {
            if(Number(originalNumber) !== Number(invoiceNumber)) {
                return true
            } else {
                return false;
            }
        }
    }

    const goToInvoicePreview = (invoiceObj, previewAndSendClicked, fromCreate) => {
        if(previewAndSendClicked) {
            props.setInvoiceItemObj(invoiceObj);
            props.setEditInvoiceObj({});
            props.setShowSendInvoiceScreenType(SendEmailType.TAX);
        } else {
            props.setInvoiceItemObj(invoiceObj);
            props.setEditInvoiceObj({});
            props.setShowPreviewInvoiceScreen(true);
        }
        fromCreate &&  props.setCreateInvoiceAccount(false);
        if(props.drawerState === SelectedDrawerState.SHOW_INVOICES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) { 
            props.setUpdatedInvoiceObj(invoiceObj);
        }
        history.push(`/invoice/${invoiceObj.slug}/preview`);
    }

    const onCreateUpdateInvoiceClick = (downloadInvoice, previewAndSendClicked, createAndEditClicked) => {

        if(downloadInvoice) trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Download button clicked',  `${props.user.firstname} ${props.user.lastname}`);
        if(previewAndSendClicked) trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Preview and Send button clicked',  `${props.user.firstname} ${props.user.lastname}`);


        const email_array = emails; //document.getElementById('email').value;
        //console.log('emails array', email_array);
        // if (email_array && email_array.length === 0) {
        //     enqueueSnackbar('Email can not be empty Or Enter valid email', {
        //         variant:'error'
        //     });
        //     return;
        // }

        const issue_date = moment(issuedDate, "YYYY-MM-DD").format('YYYY-MM-DD');
        const due_date = moment(dueDate, "YYYY-MM-DD").format('YYYY-MM-DD');

        const invoice_description = invoiceDescription;
        // if(invoice_description === '') {
        //     setErrorType(1);
        //     setErrorMessage('The job description field is required');
        //     return;
        // }
        if (itemDetails[itemIndex]?.item_name === '') {
            setErrorType(2);
            setErrorMessage('*The name field is required');
            return;
        } else if (!itemDetails[itemIndex]?.cost) {
            setErrorType(3);
            setErrorMessage('*The cost field is required');
            return;
        } else if (!itemDetails[itemIndex]?.quantity) {
            setErrorType(4);
            setErrorMessage('*The quantity field is required');
            return;
        } else {
        const subtotal = getTotalPrice();
        const total_tax = getTaxDetails();
        const total = getInvoiceTotal() + tcsAmount;
        const payment_terms = paymentTerms ? paymentTerms.replaceAll(/(\n)/g, "<br/>") : '';
        const client_notes = clientNotes ? clientNotes.replaceAll(/(\n)/g, "<br/>") : '';
        const approval_status = true;
        const invoice_account_id = props.selectedAccount?.id;
        const client_id = props.customerObj?.id;
        console.log('client_id', client_id, props.customerObj);
        const invoice_id = invoiceObj?.id;
        const show_client_notes = showClientNotes;
        const show_payment_terms = showPaymentTerms;
        const show_payment_stub = showPaymentStub;
        const download = downloadInvoice;
        const tds_type_id = tdsTypeObj ? tdsTypeObj?.id : null;
        const tds_amount = tdsAmount;
        const discount = fullInvoiceDiscount[0].value === 0 || fullInvoiceDiscount[0].amount === 0 ? [] : fullInvoiceDiscount;
        const items_details = itemDetails.map((item, index) => {
            if(item.discount_value > 0) {
                item.discount = [
                    {
                        type: item.discount_type,
                        value: item.discount_value,
                        amount: item.discount_amount
                    }
                ]
            } else {
                item.discount = [];
            }
            delete item.discount_type; delete item.discount_value; delete item.discount_amount;
            itemList.map((itemObj, index) => {
                if(item.hsn_code === itemObj.hsn_code || item.hsn_code === hsn_code_4_digit) {
                    item.hsn_code = ''
                } else {
                    item.hsn_code = item.hsn_code;
                }
            })
            return item;
        });
        const entity_id = entityObj?.id;
        const enable_esign = selectedEntityObj?.enable_esign;
        const set_number_manually =  checkInvoiceNumberChangedOrNot();
        const invoice_number = Number(invoiceNumber);
        const tcs = tcsTypeObj ? {name: tcsTypeObj?.name, value: Number(tcsAmount)} : null;
        const reference_number = referenceNumber;
        const currency_id = selectedCurrency?.id;
        const show_upi_qr_code = showUpiQrCode;

        createAndEditClicked && setInvoiceLoading(true);
        previewAndSendClicked && setPreviewAndSend(true);
        if(Object.keys(props.editInvoiceObj).length !== 0) {
            callUpdateInvoiceApi(invoice_account_id, invoice_id, email_array, invoice_description, subtotal, total_tax,  total, 
            approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes,
            show_payment_terms, show_payment_stub, download, previewAndSendClicked, tds_type_id, tds_amount, discount, entity_id, invoiceObj?.client.id, 
            enable_esign, set_number_manually, invoice_number, tcs, reference_number, currency_id, show_upi_qr_code);
        } else {
            createInvoiceApi(invoice_account_id, entity_id, client_id, email_array, invoice_description, subtotal, total_tax,
            total, approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes,
            show_payment_terms, show_payment_stub, download, tds_type_id, tds_amount, discount, enable_esign, set_number_manually,
            invoice_number, tcs, reference_number, currency_id, show_upi_qr_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createInvoiceApi:', res);
                setInvoiceLoading(false);
                
                if(res.document_signed_url) {
                    downloadableInvoice(res.document_signed_url);
                }

                intervalDays && enableInvoiceRecurringDate(res.invoice);

                let accountObj = {
                    ...props.selectedAccount,
                    can_create_invoice: res.can_create_invoice
                }

                let accountList = props.invoiceAccountsList.map((ia) => {
                    if (ia.id === accountObj.id) return accountObj;
                        else return ia;
                    });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(accountObj);

                
                // if(previewAndSendClicked) {
                //     props.setInvoiceItemObj(res.invoice);
                //     props.setEditInvoiceObj({});
                //     props.setShowSendInvoiceScreenType(SendEmailType.TAX);
                //     props.setCreateInvoiceAccount(false);
                // } else {
                //     props.setInvoiceItemObj(res.invoice);
                //     props.setEditInvoiceObj({});
                //     props.setShowPreviewInvoiceScreen(true);
                //     props.setCreateInvoiceAccount(false);
                // }

                props.entityObj !== -1 && props.setEntityObj(-1);

                goToInvoicePreview(res.invoice, previewAndSendClicked, true);

                enqueueSnackbar('Invoice Created Successfully', {
                    variant:"success"
                });

                trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Invoice created', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error createInvoiceApi', e);
                setInvoiceLoading(false);
                setPreviewAndSend(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
            }
        }

    }

    const callUpdateInvoiceApi = (invoice_account_id, invoice_id, email_array, invoice_description, subtotal,
        total_tax, total, approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes,
        show_payment_terms, show_payment_stub, download, previewAndSendClicked, tds_type_id, tds_amount, discount, entity_id, client_id, enable_esign, 
        set_number_manually, invoice_number, tcs, reference_number, currency_id, show_upi_qr_code) => {

            updateInvoiceApi(invoice_account_id, invoice_id, email_array, invoice_description, subtotal,
            total_tax, total, approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes,
            show_payment_terms, show_payment_stub, download, tds_type_id, tds_amount, discount, entity_id, client_id, enable_esign,
            set_number_manually, invoice_number, tcs, reference_number, currency_id, show_upi_qr_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoiceApi:', res);
                setInvoiceLoading(false);

                if(res.document_signed_url) {
                    downloadableInvoice(res.document_signed_url);
                }

                // if(previewAndSendClicked) {
                //     props.setInvoiceItemObj(res);
                //     props.setEditInvoiceObj({});
                //     props.setShowSendInvoiceScreenType(SendEmailType.TAX);
                // } else {
                //     props.setInvoiceItemObj(res);
                //     props.setEditInvoiceObj({});
                //     props.setShowPreviewInvoiceScreen(true);
                // }

                // if(props.drawerState === SelectedDrawerState.SHOW_INVOICES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                //     props.setUpdatedInvoiceObj(res);
                // }
                //props.drawerState === SelectedDrawerState.SHOW_CLIENTS && props.updateInvoiceItemListInClients(res);
                goToInvoicePreview(res, previewAndSendClicked, false);

                enqueueSnackbar('Invoice Updated Successfully', {
                    variant:"success"
                });

                trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Invoice updated', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error updateInvoiceApi', e);
                setInvoiceLoading(false);
                setPreviewAndSend(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });

    }

    // const onPreviewAndSendClick = () => {
    //     setPreviewAndSend(true);
    // }

    const CreateItemButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
          <Button fullWidth
            className={classes.createItemButton}
            variant="outlined"
            color="primary"
            onMouseDown={handleAddEditItemDrawerOpen}
          >
            <AddCircleOutlineIcon fontSize='small' className={classes.createItemIcon}/>  
            Create Item
          </Button>
          {children}
        </Paper>
      );

      const onItemClick = (option, index) => {
        const updatedArray = itemDetails;
        
        updatedArray[index].item_id = option.id;
        updatedArray[index].item_name = option.title;
        updatedArray[index].cost = parseFloat(option.cost);
        updatedArray[index].hsn_code = option.hsn_code !== '' ? option.hsn_code : hsn_code_4_digit;
        updatedArray[index].item_description = option.description;
        updatedArray[index].price = parseFloat(option.cost * updatedArray[index].quantity);
        updatedArray[index].discount_type = DiscountTypes.PERCENT;
        updatedArray[index].discount_value = parseFloat(0.00);
        updatedArray[index].discount_amount = parseFloat(0.00);
        focusTextInput.current?.focus();

        if(isInvoiceAccountCountryIndia(invoiceAccountCountry)) {
            if(entityObj.country === 'IN' && entityObj.tax_id && clientCountry === 'IN') {
                if(entityObj.state === clientState) {
                    updatedArray[index].tax = option.tax.intra_state.map((taxItem) =>{
                        taxItem.amount = taxAmount(taxItem.rate, index)
                        return taxItem
                        });
                }

                if(entityObj.state !== clientState) {
                    updatedArray[index].tax = option.tax.inter_state.map((taxItem) =>{
                        taxItem.amount = taxAmount(taxItem.rate, index)
                        return taxItem
                        });
                }
            }
        } else {
            updatedArray[index].tax = option.tax.states.map((taxItem) =>{
                taxItem.amount = taxAmount(taxItem.rate, index)
                return taxItem
            });
        }
        setItemDetails([...updatedArray]);
    }

    const onItemClearClick = (index) => {
        const updatedArray = itemDetails;
        
        updatedArray[index].item_id = '';
        updatedArray[index].item_name = '';
        updatedArray[index].cost = parseFloat(0);
        updatedArray[index].hsn_code = '';
        updatedArray[index].item_description = '';
        updatedArray[index].tax = [];
        updatedArray[index].discount_type = DiscountTypes.PERCENT;
        updatedArray[index].discount_value = parseFloat(0.00);
        updatedArray[index].discount_amount = parseFloat(0.00);
        setItemDetails([...updatedArray]);
        
    }

    const renderOptions = (option, index) => {
        return (
            <Grid item md={12} className={classes.itemOptionStyle}>
                <Grid item container alignItems='center' style={{flexWrap:'wrap'}}>
                    <Grid item md={9} >
                        <Typography variant='body2' >
                            <span style={{color:"#333"}}>{option.title}</span>
                            {option.tax && option.tax.length > 0 &&
                            <Typography style={{fontSize:'12px', maxWidth:'80%'}}>
                                {option.tax.map((taxItem) => `${taxItem.name} ${taxItem.rate}%`).join(', ')}
                            </Typography>}
                        </Typography>
                    </Grid> 
                    
                    <Grid item md={2}>
                        <Typography variant='body2'>
                            {formatAmount(option.cost, currenciesObj)}
                        </Typography>
                    </Grid>
                </Grid>    

            </Grid>
            );
    }

    const  getSelectedItem = (index) => {
        const itemObj = itemList?.find((item) => {
            if(item.title === itemDetails[index].item_name) return item
        });
        //console.log('**********item', itemObj);

        return itemObj || {};
    }

        const onAddItemsClick = (obj) => {
            //console.log('add to invoice item list');
            setItemList(oldItems => [...oldItems, obj]);
        }

        const showCountries = (val) => {
            const {countries} = country;
            const country = countries?.find((country) => country.code === val);
            return country.name
            //console.log('getcountry', getCountry)
        }

        const showStates = (val) => {
            const {states} = state;
            const getState = states?.find((st) => st.code === val);
            console.log('getState', getState)
            return getState.name
            
        }

        const renderOptions1 = (option) => {
            return (
                <div className={classes.itemOptionStyle}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant='body2'>
                            {option.tds_display}
                        </Typography> 
    
                        <Typography variant='body2'>
                            {option.rate}
                        </Typography>
                    </Grid>    
    
                </div>
                );
        }

        const renderOptions2 = (option) => {
            return (
                <div className={classes.itemOptionStyle}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant='body2'>
                            {option.name}
                        </Typography> 
    
                        <Typography variant='body2'>
                            {option.rate}
                        </Typography>
                    </Grid>    
    
                </div>
                );
        }

        const onTDSItemChanged = (e, newValue) => {
            setTDSTypeObj(newValue);
            let calculateTDSAmount =  parseFloat((newValue.rate * getTotalPrice()) / 100);
            setTDSAmount(calculateTDSAmount);
        }

        const onTCSItemChanged = (e, newValue) => {
            setTCSTypeObj(newValue);
            let calculateTCSAmount =  parseFloat((newValue.rate * getInvoiceTotal()) / 100);
            setTCSAmount(calculateTCSAmount);
        }

        const onItemClearClick1 = () => {
            setTDSTypeObj(undefined);
            setTDSAmount(0);
        }

        const onItemClearClick2 = () => {
            setTCSTypeObj(undefined);
            setTCSAmount(0);
        }

        const onAddDiscountClick = (event, index) => {
            trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Add Discount clicked', `${props.user.firstname} ${props.user.lastname}`);

            setShowDiscountMenu(!showDiscountMenu);
            setAnchorEl4(event.currentTarget);
            setDropDownIndex1(index);
            if(dropdownIndex1 !== undefined && anchorEl4) {
                setDropDownIndex1(undefined);
                setAnchorEl4(null);
            }
        }

        const closeInvoiceDiscount = () => {
            setShowDiscountMenu(!showDiscountMenu);
            setAnchorEl4(null);
            setDropDownIndex1(undefined);
        }

        const handleChange = (event, newValue, invoiceItem, index) => {
            //console.log('new value', newValue);
            const updatedArray = itemDetails;

            updatedArray[index].discount_type = newValue;
            updatedArray[index].discount_value = parseFloat(0.00);
            updatedArray[index].discount_amount = parseFloat(0.00);
            setItemDetails([...updatedArray]);
            onInvoiceItemPriceChanged(event, invoiceItem, index);
            updateAmountInTax(index, true);
            // if(newValue === 0) {
            //     updatedArray[index].discount.map((discountItem) => {
            //         discountItem.type = 'percent';
            //         discountItem.value = discountItem.value;
            //         discountItem.amount = (updatedArray[index].price * discountItem.value) / 100;
            //         onInvoiceItemPriceChanged(event, '', index, (updatedArray[index].price * discountItem.value) / 100)
            //     })
                
                
            //     setItemDetails([...updatedArray]);
            // } else {
            //     updatedArray[index].discount.map((discountItem) => {
            //         discountItem.type = 'flat';
            //         discountItem.value = discountItem.value;
            //         discountItem.amount = discountItem.value;

            //         onInvoiceItemPriceChanged(event, '', index, discountItem.value)
            //     })
                
            //     setItemDetails([...updatedArray]);
            // }

        };


        const onClearDiscount = (e, invoiceItem, index) => {
            const updatedArray = itemDetails;
            updatedArray[index].discount_type = DiscountTypes.PERCENT;
            updatedArray[index].discount_value = parseFloat(0.00);
            updatedArray[index].discount_amount = parseFloat(0.00);
            setItemDetails([...updatedArray]);
            onInvoiceItemPriceChanged(e, invoiceItem, index);
            updateAmountInTax(index, true);
        }

        const onApplyClick = (e, invoiceItem, index) => {
            const updatedArray = itemDetails;

            updatedArray[index].discount_type = (updatedArray[index].discount_amount > 0 || updatedArray[index].discount_value > 0) ? 
            updatedArray[index].discount_type : DiscountTypes.PERCENT;
            
            updatedArray[index].discount_value = (updatedArray[index].discount_amount > 0 || updatedArray[index].discount_value > 0) 
            ? updatedArray[index].discount_value : parseFloat(0.00);
            
            updatedArray[index].discount_amount =  updatedArray[index].discount_amount > 0 ? updatedArray[index].discount_amount : parseFloat(0.00);
            
            //console.log('updatedArray******', updatedArray);
            setItemDetails([...updatedArray]);
            setAnchorEl4(null);
            setShowDiscountMenu(false);
            setDropDownIndex1(undefined);
        }

        

        const displayDiscountValue = (invoiceItem, index) => {
            return `- ${formatAmount(itemDetails[index].discount_amount, selectedCurrency)}`;
            
        }

        const displayTaxAmount = (rate, invoiceItem, index) => {
            let itemQuantity = invoiceItem.quantity ? invoiceItem.quantity : 0;
            let itemCost = invoiceItem.cost ? invoiceItem.cost : 0;

            const discount_type = invoiceItem?.discount_type;
            const discount_value = invoiceItem?.discount_value;
            const taxValue = (rate * itemCost * itemQuantity) / 100;

            if(discount_value > 0) {
                return discount_type === DiscountTypes.PERCENT ? taxValue - taxValue * (discount_value / 100) : taxValue - (rate * discount_value)/100
            } else {
                return (rate * itemCost * itemQuantity) / 100;
            }
        }

        const handleOpenFullDiscountMenu = (event) => {
            setShowDiscountMenu1(!showDiscountMenu1);
            setAnchorEl5(event.currentTarget);
        }

        const handleChange1 = (event, newValue) => {
            const updatedArray = fullInvoiceDiscount;
            updatedArray[0].type = newValue;
            updatedArray[0].value = parseFloat(0.00);
            updatedArray[0].amount = parseFloat(0.00);
            setFullInvoiceDiscount([...updatedArray]);
        }

        const showFullInvoiceDiscount = () => {
            return <Grid item xs={12}>
                    {showDiscountMenu1 && anchorEl5 &&
                        <Popover id={id5} open={open5} anchorEl={anchorEl5}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            style={{width:'300px'}}
                            onClose={closeFullDiscountMenu}
                            >
                            <Grid item md={12} xs={12} style={{padding:"8px"}}>
                                <Tabs
                                    value={fullInvoiceDiscount[0].type}
                                    onChange={handleChange1}
                                    variant="fullWidth"
                                    textColor="secondary"
                                    aria-label="icon label tabs example"
                                    className={classes.tabStyle}
                                    classes={{
                                        root: classes.MuiTabsroot,
                                        indicator: classes.MuiTabsindicator
                                    }}
                                >
                                    <Tab
                                        style={{marginLeft:'5px'}} 
                                        value={DiscountTypes.PERCENT}
                                        classes={{
                                        root: classes.MuiTabroot,
                                        selected: classes.Muiselected
                                    }} label="Percentage (%)" />
                                    <Tab style={{marginRight:'5px'}} 
                                        value={DiscountTypes.FLAT}
                                        classes={{
                                        root: classes.MuiTabroot,
                                        selected: classes.Muiselected
                                    }} label="Flat (₹)" />
                                </Tabs>

                                <Grid item container alignItems='center'
                                    justifyContent='space-between' style={{marginTop:'2px'}}>
                                    <Grid item md={2}>
                                        <Typography variant='subtitle1'>
                                            Discount:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={8}>
                                        <TextField variant='outlined'
                                            fullWidth
                                            margin='dense'
                                            classes={{
                                                root: classes.MuiTextFieldroot1
                                            }}
                                            type='number'
                                            value={fullInvoiceDiscount[0].value}
                                            style={{position:'relative',  }}
                                            InputProps={{
                                                
                                                startAdornment: fullInvoiceDiscount[0].type === DiscountTypes.FLAT && (
                                                <InputAdornment position="start">
                                                    <Typography className={classes.rupeeSymbolStyle}>
                                                        ₹
                                                    </Typography>
                                                </InputAdornment>
                                                ),
                
                                                endAdornment: fullInvoiceDiscount[0].type === DiscountTypes.PERCENT &&(
                                                    <InputAdornment position='end'>
                                                        <Typography className={classes.percentStyle}>
                                                            %
                                                        </Typography>
                                                    </InputAdornment>
                                                )
                
                                            }}
                                            onClick={(e) => console.log('e', e)}
                                            onChange={onFullInvoiceItemDiscountChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item md={12} style={{marginTop:'4px'}}>
                                        <Button fullWidth
                                            onClick={onApplyClick1}
                                            variant='contained' className='button_purple'>
                                            Apply Discount
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                        </Popover>
                        }
                </Grid>
        }

        const closeFullDiscountMenu = () => {
            setAnchorEl5(null);
            setShowDiscountMenu1(false);
        }

        const onFullInvoiceItemDiscountChange = (e) => {
            const updatedArray = fullInvoiceDiscount;
            const type = updatedArray[0].type;
            const value = e.target.value;
            updatedArray[0].value = parseFloat(value);
            updatedArray[0].amount =  type === DiscountTypes.PERCENT ? parseFloat((value * getTotalPrice()) / 100) : parseFloat(value);
            setFullInvoiceDiscount([...updatedArray]);
        }

        const onApplyClick1 = (e) => {
            const updatedArray = fullInvoiceDiscount;
            updatedArray[0].type = (updatedArray[0].amount > 0 || updatedArray[0].value > 0) ? 
            updatedArray[0].type : DiscountTypes.PERCENT;
            updatedArray[0].value = (updatedArray[0].amount > 0 || updatedArray[0].value > 0) ? updatedArray[0].value : parseFloat(0.00);
            updatedArray[0].amount =  updatedArray[0].amount > 0 ? updatedArray[0].amount : parseFloat(0.00);

            //console.log('updatedArray1***********', updatedArray);
            setFullInvoiceDiscount([...updatedArray]);
            closeFullDiscountMenu();
        }


        const onBackArrowClick = () => {
            history.push(getUrlAccordingToSelectedComponent(props.drawerState));
            props.setCreateInvoiceAccount(false);
            props.setInvoiceItemObj({});
            props.setCustomerObject({});
            props.setEditInvoiceObj({});
            props.entityObj !== -1 && props.setEntityObj(-1);
            //props.getInvoiceList(1);
            props.handleInvoiceDialogClose();
        }

        const handleEditEntityPopoverOpen = (event) => {
            setAnchorEl6(event.currentTarget);
        };
    
        const handleEditEntityPopoverClose = () => {
            setAnchorEl6(null);
        }

        const showEditEntityPopover = () => {
            return (<Popover
                id={'echange-entity-popover'}
                open={Boolean(anchorEl6)}
                anchorEl={anchorEl6}
                onClose={handleEditEntityPopoverClose}
                style={{marginTop:'4px'}}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                {Object.keys(props.editInvoiceObj).length > 0 &&
                <MenuItem
                    onClick={handleChangeEntityModalOpen}
                    >
                    <PersonOutlineOutlinedIcon
                            fontSize='small' className={classes.moreActionIconStyle}/>
                    Change Entity
                </MenuItem> }
            </Popover>)
        }

        const handleChangeEntityModalOpen = () => {
            setOpenChangeEntityModal(true);
            setAnchorEl6(null);
            setEntityOrCustomerChange(true);
            dontCallApi && setDontCallApi(false);
        }
    
        const handleChangeEntityModalClose = () => {
            setOpenChangeEntityModal(false)
        }

        const handleEnableInvoiceESignModalOpen = () => {
            setOpenESignDialog(true);
        }
    
        const handleEnableInvoiceESignModalClose = () => {
            setOpenESignDialog(false);
        }

        const handleESignChange = () => {
            dontCallApi && setDontCallApi(false);
            if(selectedEntityObj?.enable_esign && selectedEntityObj?.signature) {
                updateEntityESign(false);
            } else if(!selectedEntityObj?.enable_esign && selectedEntityObj?.signature) {
                updateEntityESign(true);
            } else {
                handleEnableInvoiceESignModalOpen();
            }
        }
    
        const updateEntityESign = (enable_esign) => {
            
            const invoice_account_id = props.selectedAccount?.id;
            const signature = entityObj?.signature;
            const entity_id = entityObj?.id;
    
            updateEntityEsignApi(invoice_account_id, entity_id, signature, enable_esign)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response updateEntityEsignApi', res);
    
                    enqueueSnackbar('Invoice e-sign settings updated successfully', {variant:'success'});
    
                    let account = {
                        ...props.selectedAccount,
                        entities: props.selectedAccount?.entities.map((item) => {
                            if(item.id === entity_id) {
                                item.signature = res.signature;
                                item.enable_esign = res.enable_esign;
                            }
                            return item
                        })
                    };

                    let obj = {
                        ...selectedEntityObj,
                        signature: res.signature,
                        enable_esign: res.enable_esign
                    }

                    setSelectedEntityObj(obj);

                    let accountList = props.invoiceAccountsList.map((ia) => {
                    if (ia.id === account.id) return account;
                        else return ia;
                    });
    
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);

                    updateInvoiceEntityEsign(res.enable_esign);
    
                })
                .catch((e) => {
                    consoleToLog("Error uploadESignImageApi: ", e);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })
        }

        const updateInvoiceEntityEsign = (enable_esign) => {
            const invoice_account_id = props.selectedAccount?.id;
            const invoice_id = props.editInvoiceObj?.id;
    
            updateInvoiceEntityEsignApi(invoice_account_id, invoice_id, enable_esign)
                .then((response) => {
                    const res =  response.data;
                    consoleToLog('Response updateInvoiceEntityEsignApi: ', res);
    
                    const obj = {
                        ...props.editInvoiceObj,
                        enable_esign: res.enable_esign
                    }
    
                    props.setEditInvoiceObj(obj);
                    props.drawerState === SelectedDrawerState.SHOW_INVOICES && props.setUpdatedInvoiceObj(obj);
                })
                .catch((e) => {
                    consoleToLog("updateInvoiceEntityEsignApi error", e.response);
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
        }

        const getDownloadSignedUrl = (entityObj) => {
            const invoice_account_id = props.selectedAccount?.id;

            getImageDownloadUrlApi(invoice_account_id, entityObj?.signature, 'signature')
                .then((response) => {
                    const res =  response.data;
                    consoleToLog('Response getImageDownloadUrlApi: ', res);
    
                    setESignUrl(res.download_signed_url);
                })
                .catch((e) => {
                    consoleToLog("invoiceAccountUpdateApi error", e.response);
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
        }

        const enableInvoiceRecurringDate = (res) => {
            const invoice_account_id = props.selectedAccount?.id;
            const invoice_id = res?.id;
            const interval_days = intervalDays;
            const send_invoice_mail = false;
    
            enableInvoiceRecurringDateApi(invoice_account_id, invoice_id, interval_days, send_invoice_mail)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response enableInvoiceRecurringDateApi: ', res);
    
                    if(Object.keys(props.editInvoiceObj).length > 0) {
                        props.setEditInvoiceObj(res.invoice);
                    } else {
                        props.setInvoiceItemObj(res.invoice);
                    }
                    if(props.drawerState === SelectedDrawerState.SHOW_INVOICES) { 
                        props.setUpdatedInvoiceObj(res.invoice);
                    }
                })
                .catch((e) => {
                    consoleToLog('Error enableInvoiceRecurringDateApi: ', e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                })
        }

        const handleUpdateNumberDialogOpen = () => {
            setOpenUpdateNumberDialog(true);
        }

        const handleUpdateNumberDialogClose = () => {
            setOpenUpdateNumberDialog(false);
        }

        const renderCurrencyOptions = (option) => {
            const flagCode =  extractFlagCode(option.flag);
            const Flag = Flags[flagCode];
            return (
                <Grid item md={12} className={classes.itemOptionStyle}>
                    <Grid item container alignItems='center'> 
                        <Grid item md={1}>
                            <div style={{position:'relative', top:'1px'}}>
                                <Flag width='28px' height='22px'/>
                            </div>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant='body1' style={{marginLeft:'8px'}}>
                                {`${option.name}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                );
        }

        const onSelectedCurrencyChange = (e, newValue) => {
            setSelectedCurrency(newValue);
            console.log('selected currency', newValue);
        }

        const showFlagStartAdornment = () => {
            const flagCode =  extractFlagCode(selectedCurrency?.flag);
            const Flag = Flags[flagCode];

            if (!Flag) {
                console.error(`Flag component for code ${selectedCurrency?.code} not found.`);
                return null; // Return null if the Flag is undefined
            }
        

            return ( 
                    <InputAdornment position="start">
                        <Grid item container alignItems='center'>
                            <Grid item style={{paddingLeft:'6px', position:"relative", top:'1px'}}>
                                <Flag width='25px'/>
                            </Grid>
                        </Grid>
                    </InputAdornment>
                )
        }

        const handleInputChangeForCurrency = (e, newValue) => {
            const value = e?.target.value;
            value && getCurrencies(newValue, false);

            setInputText(newValue);
                if (!newValue) {
                    setShowDropdown(false);
                }
        }

        const onShowQrCodeClick = () => {
            trackGAEvent(props.selectedAccount.name, 'Invoice Create and Edit - Show client notes clicked', `${props.user.firstname} ${props.user.lastname}`);
            setShowUpiQrCode(!showUpiQrCode);
        }

    return (
        <Container className={classes.container} maxWidth="xl" ref={myDivToFocus}>
            <Grid item container>
                <Grid item sm={9} className={classes.createFormContainer}>
                    <Grid item className={classes.firstRow}>

                        <Grid item md={5} className={classes.backIconContainer}>
                            <Grid item container  alignItems='center'>
                                <Grid item sm={2}>
                                    <ArrowLeftIcon className={classes.backIcon}
                                        onClick={onBackArrowClick}
                                    />
                                </Grid>

                                <Grid item sm={8}>
                                    <Grid item container alignItems='center'>
                                        <Typography variant="body2">
                                            Invoice # {invoiceNumberString} -
                                        </Typography>
                                        <TextField  className={classes.invoiceNumberStyle}
                                            variant='outlined'
                                            type='text'
                                            value={invoiceNumber}
                                            classes={{
                                                root: classes.MuiTextFieldroot
                                            }}
                                            style={{width:'32%', marginLeft:'6px'}}
                                            onChange={(e) => setInvoiceNumber(e.target.value)}
                                            onKeyPress={onlyNumberValidate}
                                            InputProps={{
                                                
                                                endAdornment: (
                                                    proforma_enabled || !isCurrentFinancialYear ? <div></div> :
                                                    <InputAdornment position='end' disablePointerEvents={proforma_enabled}>
                                                            <i  onClick={handleUpdateNumberDialogOpen}
                                                                className={`flaticon-settings ${classes.settingsButton}`}></i>
                                                    </InputAdornment>
                                                )
                
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {/* <Grid item sm={3}>        
                                    <TextField  className={classes.invoiceNumberStyle}
                                        variant='outlined'
                                        type='number'
                                        value={invoiceNumber}
                                        classes={{
                                            root: classes.MuiTextFieldroot
                                        }}
                                        fullWidth
                                        onChange={(e) => setInvoiceNumber(e.target.value)}
                                    />
                                </Grid>     */}
                            </Grid>
                        </Grid>

                        <Grid item md={7} className={classes.dateContainerColumn}>
                            <Grid item container  justifyContent='flex-end'>
                                <Grid item style={{display:'flex', alignItems:'center', marginRight:'8px'}}>
                                    {/* <Typography variant="subtitle1"
                                        className={classes.dateLabel}
                                    >
                                        Issued:
                                    </Typography>
                                    <TextField variant='outlined'
                                        type="date"
                                        defaultValue={issuedDate}
                                        onChange={(e) => setIssuedDate(e.target.value)}
                                        classes={{
                                            root: classes.MuiTextFieldroot
                                        }}
                                    /> */}
                                    <Button variant="outlined" color="primary" 
                                        style={{display:'flex', alignItems:'center', color:'#6a7685', padding:'5px'}}
                                        onClick={handleIssueDatePopoverClick}>
                                    <DateRangeIcon fontSize='small' style={{marginRight:'6px', marginTop:'-2px'}}/> 
                                            Issued: {moment(issuedDate).format('DD/MM/YYYY')}
                                    </Button>
                                    {handleIssueDatePopOver()}
                                    {/* {console.log('issue date', issuedDate)} */}
                                </Grid>

                                <Grid item style={{display:'flex', alignItems:'center'}}>
                                    <Button variant="outlined" color="primary" 
                                        style={{display:'flex', alignItems:'center', color:'#6a7685', padding:'5px'}}
                                        onClick={handleDueDatePopoverClick}>
                                        <DateRangeIcon fontSize='small' style={{marginRight:'6px', marginTop:'-2px',}}/> 
                                            Due: {moment(dueDate).format('DD/MM/YYYY')}
                                    </Button>
                                    {handleDueDatePopOver()}
                                    {/* {console.log('due date', dueDate)} */}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    
                    <Grid item md={12} className={classes.secondRow}>
                        <Grid item container alignItems='center' spacing={2}>

                            <Grid item md={7}>
                                <Grid item container alignItems='center'>
                                    <Grid item sm={2} style={{flexBasis:'11%', maxWidth:'11%'}}>
                                        <Typography>
                                            Email To:
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={10}> 
                                        <ReactMultiEmail
                                            className={emails && emails.length > 0 ? "client_email" : ""}
                                            style={{
                                                borderRadius: "5px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: "#DBDBDB",
                                                fontSize:"14px"
                                            }}
                                            placeholder="Type Email Address And Press Enter"
                                            emails={emails}
                                            //onChange={(emails) => setEmails(emails)}
                                            validateEmail={email => {
                                                return isEmail(email); // return boolean
                                            }}
                                            onChange={(emails) => {
                                                setEmails(emails);
                                            }}
                                            getLabel={(
                                                email,
                                                index,
                                                removeEmail = (index) => {
                                                    const arr = emails.splice(index, 1);
                                                    setEmails(arr);
                                                }
                                            ) => {
                                                return (
                                                    <div data-tag key={index} className="return__email">
                                                        {email}
                                                        {<span data-tag-handle onClick={() => removeEmail(index)}>
                                                            x
                                                        </span>}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>    
                                </Grid>
                            </Grid>

                            <Grid item md={5}>
                                <Grid item container alignItems='center'>
                                    <Grid item sm={2}>
                                        <Typography>
                                            Currency
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={10}> 
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={currencies}
                                            classes={{
                                                root: classes.MuiAutocompleteinputRoot
                                            }}
                                            open={showDropdown}
                                            onOpen={() =>  {
                                                if(inputText) {
                                                    setShowDropdown(true)
                                                }
                                            }}
                                            onClose={() => setShowDropdown(false)}
                                            value={selectedCurrency}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option) => renderCurrencyOptions(option)}
                                            onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
                                            onInputChange = {(e, newValue) => handleInputChangeForCurrency(e, newValue)}
                                            renderInput={params => (
                                                <TextField {...params} variant="outlined" 
                                                placeholder='Search Currency' 
                                                fullWidth
                                                classes={{
                                                    root: classes.customTextField
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <>
                                                        {showFlagStartAdornment()}
                                                        {params.InputProps.startAdornment}
                                                        </>
                                                    ),
                                                }}
                                                    />
                                            )}
                                            
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item className={classes.thirdRow}>
                        <Grid item container
                            justifyContent='space-between'
                            alignItems='flex-start'
                            style={{borderBottom:'1px solid rgba(0,0,0,.1)', paddingBottom:'24px'}}>
                            <Grid item sm={4}>
                                    <Typography className='bill_label'>
                                        Bill To:
                                    </Typography>
                                    <Typography className={classes.customerName}>
                                        {clientName}
                                        {
                                            <MoreHorizIcon fontSize='small' 
                                                onClick={handleEditClientPopoverClick}
                                                className={classes.moreHorizIconStyle}/>
                                        }
                                        {handleEditClientPopOver()}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                        <div>{clientAddress}</div>
                                        <div>{clientState && `${clientState},`} {clientCountry}</div>
                                        {/* {
                                            clientMobile &&
                                            <div>Tel: {clientMobile}</div>
                                        }
                                        {
                                            clientEmail &&
                                            <div>Email: {clientEmail}</div>
                                        } */}
                                        {/* {client_taxId &&
                                            <div>Tax Id: {client_taxId}</div>
                                        } */}
                                        {
                                            client_contact_array && client_contact_array.length > 0 &&
                                            client_contact_array.slice(0, 1).map((contact) => {
                                                return <div key={contact.id}>
                                                        {
                                                            contact.email && <div> Email - {contact.email} </div>
                                                        }
                                                        {
                                                            contact.phone_number && <div> Phone - {contact.phone_number} </div>
                                                        }
                                                        </div>
                                            })
                                        }
                                        {
                                            clientUserDefinedFields && clientUserDefinedFields.length > 0 && 
                                            clientUserDefinedFields.map(udf => {
                                                return (udf.value !== '' && udf.show_invoice) ?
                                                <div key={udf.value}>{udf.name}: {udf.value}</div>
                                                :
                                                <div></div>
                                            })
                                        }
                                    </Typography>
                            </Grid>

                            <Grid item sm={5} style={{textAlign:'right'}}>
                                    <Typography className='bill_label'>
                                        Bill from:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {entityObj?.name}
                                        {Object.keys(props.editInvoiceObj).length > 0 && props.selectedAccount.entities.length > 1 &&
                                        <MoreHorizIcon fontSize='small' 
                                            onClick={handleEditEntityPopoverOpen}
                                            className={classes.moreHorizIconStyle1}/>
                                        }
                                        {showEditEntityPopover()}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                        <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                        </div>
                                        <div>{entityObj?.country}</div>
                                        <div>Tel: {entityObj?.mobile}</div>
                                        <div>Email: {entityObj?.email}</div> 
                                        {entityObj?.tax_id &&
                                            <div>Tax Id: {entityObj?.tax_id}</div>
                                        }
                                    </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} style={{marginTop:'18px', paddingLeft:'32px'}}>
                            <Typography className={classes.invoiceHeading}>
                                Invoice
                            </Typography>

                            <Grid item container alignItems='center' spacing={1}>
                                <Grid item md={4} className={classes.titleColumns}>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        placeholder='Job description'
                                        value={invoiceDescription}
                                        onChange={(e) => {
                                            setInvoiceDescription(e.target.value)
                                            setErrorType(0);
                                            setErrorMessage('');
                                        }}
                                        error={errorType === 1}
                                        helperText={errorType === 1 ? errorMessage : ''}
                                        classes={{
                                            root: classes.MuiTextFieldroot
                                        }}
                                    />
                                </Grid>

                                <Grid item md={4} className={classes.titleColumns}>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        placeholder='Reference Number'
                                        value={referenceNumber}
                                        onChange={(e) => setReferenceNumber(e.target.value)}
                                        classes={{
                                            root: classes.MuiTextFieldroot
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sm={12} style={{paddingBottom:'16px'}}>
                                {
                                    itemDetails && itemDetails.length > 0 &&
                                    <Grid item sm={11} className={classes.invoiceItemsHeadings}>
                                        <Grid item container spacing={1}>
                                            <Grid item sm={3} className={classes.customColumn1}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    Item / Service
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={1} className={classes.customColumn2}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    Cost
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    QTY
                                                </Typography>
                                            </Grid>
                                            {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                            <Grid item sm={1} className={classes.customColumn3}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    HSN/SAC code
                                                </Typography>
                                            </Grid>}
                                            <Grid item sm={3}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 3} 
                                                className={!isInvoiceAccountCountryIndia(invoiceAccountCountry) && classes.customColumn4}
                                                style={{textAlign:'right'}}>
                                                <Typography variant="subtitle1" className={classes.itemHeading}>
                                                    Amount
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    itemDetails && itemDetails.length > 0 &&
                                    itemDetails.map((invoiceItem, index) => {
                                        return <Grid item container
                                                    className={classes.invoiceItemsContainer}>
                                                    <Grid item sm={11} className={classes.invoiceItems}>
                                                        <Grid item container
                                                            direction="column">

                                                            <Grid item container alignItems='center' spacing={1}>

                                                                <Grid item sm={3} className={classes.customColumn1}>  
                                                                    <Autocomplete
                                                                        id="combo-box-demo"
                                                                        options={itemList}
                                                                        classes={{
                                                                            root: classes.MuiAutocompleteinputRoot
                                                                        }}
                                                                        value={getSelectedItem(index)}
                                                                        getOptionLabel={(option) => option.title}
                                                                        //inputValue={invoiceItem.item_name}
                                                                        //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                                                                        renderOption={(option) => renderOptions(option, index)}
                                                                        onChange={(e, newValue) => {
                                                                            onInvoiceItemNameChanged(e, newValue, index);
                                                                            onItemClick(newValue, index)
                                                                        }}
                                                                        style={{padding:0}}
                                                                        renderInput={params => (
                                                                            <TextField {...params} variant="outlined" 
                                                                            placeholder='Search items' 
                                                                            fullWidth
                                                                            classes={{
                                                                                root: classes.customTextField
                                                                            }}
                                                                                />
                                                                        )}
                                                                        closeIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)}/>}
                                                                        PaperComponent={CreateItemButton}
                                                                    />
                                                                    {/* (errorType === 2 && index === itemIndex) &&
                                                                        <span className={classes.errorText}>
                                                                            {errorMessage}
                                                                        </span>
                                                                    */} 
                                                                </Grid>

                                                                <Grid item sm={1} className={classes.customColumn2}>
                                                                    <TextField fullWidth
                                                                        variant="outlined"
                                                                        value={invoiceItem.cost}
                                                                        type="number"
                                                                        onChange={(e) => onInvoiceItemCostChanged(e, invoiceItem, index)}
                                                                        classes={{
                                                                            root: classes.MuiTextFieldroot
                                                                        }}
                                                                        //error={errorType === 3 && index === itemIndex}
                                                                        //helperText={(errorType === 3 && index === itemIndex) ? errorMessage : ''}
                                                                    />
                                                                        {/*
                                                                            (errorType === 3 && index === itemIndex) &&
                                                                            <span className={classes.errorText}>
                                                                                {errorMessage}
                                                                            </span>
                                                                    */}
                                                                </Grid>

                                                                <Grid item sm={1}>
                                                                    <TextField fullWidth
                                                                        variant="outlined"
                                                                        value={invoiceItem.quantity}
                                                                        type="number"
                                                                        onChange={(e) => onInvoiceItemQuantityChanged(e, invoiceItem, index)}
                                                                        classes={{
                                                                            root: classes.MuiTextFieldroot
                                                                        }}
                                                                        inputRef={focusTextInput}
                                                                        //style={{border: (errorType === 4 && index === itemIndex) && '1px solid red'}}
                                                                        //error={errorType === 4 && index === itemIndex}
                                                                        //helperText={(errorType === 4 && index === itemIndex) ? errorMessage : ''}
                                                                    />
                                                                    
                                                                    {/*
                                                                        (errorType === 4 && index === itemIndex) &&
                                                                        <span className={classes.errorText}>
                                                                            {errorMessage}
                                                                        </span>
                                                                    */}
                                                                </Grid>

                                                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                                                <Grid item sm={1} className={classes.customColumn3}>
                                                                    <TextField fullWidth
                                                                        variant="outlined"
                                                                        value={invoiceItem.hsn_code}
                                                                        type="text"
                                                                        placeholder="HSN/SAC code"
                                                                        onChange={(e) => onInvoiceItemHSNCodeChanged(e, invoiceItem, index)}
                                                                        classes={{
                                                                            root: classes.MuiTextFieldroot
                                                                        }}
                                                                    />
                                                                </Grid>}

                                                                <Grid item sm={3}>
                                                                    <TextField style={{width:'98%'}}
                                                                        variant="outlined"
                                                                        value={invoiceItem.item_description}
                                                                        type="text"
                                                                        placeholder="Item Description"
                                                                        onChange={(e) => onInvoiceItemDescriptionChanged(e, invoiceItem, index)}
                                                                        classes={{
                                                                            root: classes.MuiTextFieldroot
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 3} 
                                                                    className={!isInvoiceAccountCountryIndia(invoiceAccountCountry) && classes.customColumn4}
                                                                    style={{textAlign:'right'}}>
                                                                    <Typography variant="body1">
                                                                        {`${isNaN(invoiceItem.price) ? `₹` + 0.00.toFixed(2) : formatAmount(invoiceItem.price, selectedCurrency)}`}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            { <Grid item container alignItems='start'>
                                                                { (errorType === 2 && index === itemIndex) ?
                                                                    <Grid item sm={4}>
                                                                        <span className={classes.errorText}>
                                                                            {errorMessage}
                                                                        </span>
                                                                    </Grid> 
                                                                    :
                                                                    <Grid item sm={4}>
                                                                        <span className={classes.errorText}>
                                                                            {''}
                                                                        </span>
                                                                    </Grid> 
                                                                } 
                                                                { (errorType === 3 && index === itemIndex) ?
                                                                    <Grid item sm={2}>
                                                                        <span className={classes.errorText}>
                                                                            {errorMessage}
                                                                        </span>
                                                                    </Grid> 
                                                                    :
                                                                    <Grid item sm={2}>
                                                                        <span className={classes.errorText}>
                                                                            {''}
                                                                        </span>
                                                                    </Grid>

                                                                } 
                                                                { (errorType === 4&& index === itemIndex) ?
                                                                    <Grid item sm={2}>
                                                                        <span className={classes.errorText}>
                                                                            {errorMessage}
                                                                        </span>
                                                                    </Grid> 
                                                                    :
                                                                    <Grid item sm={2}>
                                                                        <span className={classes.errorText}>
                                                                            {''}
                                                                        </span>
                                                                    </Grid>

                                                                }   
                                                            </Grid> }

                                                            {/* <Grid item sm={4}>
                                                                <TextField style={{width:'98%'}}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    value={invoiceItem.item_description}
                                                                    type="text"
                                                                    placeholder="Item Description"
                                                                    onChange={(e) => onInvoiceItemDescriptionChanged(e, invoiceItem, index)}
                                                                    classes={{
                                                                        root: classes.MuiTextFieldroot
                                                                    }}
                                                                />
                                                            </Grid> */}
                                                            
                                                            <Grid item container alignItems='center' 
                                                                style={{marginTop:'4px'}} 
                                                                spacing={2}>
                                                                <Grid item className="dropdown">
                                                                    {/* {invoiceItem?.tax && invoiceItem?.tax.length > 0 &&
                                                                    <Typography className={classes.taxesLabel}>
                                                                        Taxes:
                                                                    </Typography>} */}
                                                                    <Button className="dd-button"
                                                                            onClick={(event) => onAddTaxClick(event, index)}
                                                                            style={{marginRight:'-6px'}}
                                                                    >
                                                                        Apply Tax
                                                                        <ExpandMoreIcon className="arrow_down_icon"/>
                                                                    </Button>
                                                                        {index === dropdownIndex &&
                                                                        <ClickAwayListener onClickAway={() => {
                                                                            setShowAddTaxMenu(false);
                                                                            setAnchorEl(null);
                                                                            setDropDownIndex(undefined)
                                                                        }}>
                                                                        <Popper id={id} open={open} anchorEl={anchorEl}
                                                                            className={showAddTaxMenu ? `dd-menu active` : 'dd-menu'}
                                                                            placement="bottom-start"
                                                                            style={{height:'auto', maxHeight:'330px'}}
                                                                            >
                                                                            <>
                                                                                {/* {
                                                                                    invoiceItem?.tax && invoiceItem?.tax.length > 0 &&
                                                                                    <li>
                                                                                        <Typography className={classes.taxPresetHeading}>
                                                                                            APPLIED TAX
                                                                                        </Typography>
                                                                                    </li>
                                                                                } */}
                                                                                
                                                                                {/* {
                                                                                    invoiceItem?.tax && invoiceItem?.tax.length > 0 &&
                                                                                    <li className="divider"></li>
                                                                                } */}
                                                                                {
                                                                                    <li>
                                                                                        <Typography className={classes.taxPresetHeading}>
                                                                                            TAX PRESET
                                                                                        </Typography>
                                                                                    </li>
                                                                                }
                                                                                {

                                                                                taxPresetsArr.filter((taxItem) => invoiceItem?.tax.find(filter =>
                                                                                    filter.tax_preset_id === taxItem.id) === undefined).map((tax, i) => {
                                                                                return <li className="d_flex"
                                                                                            key={tax.id}
                                                                                            onClick={(e) => onTaxItemClick(tax, index)}>
                                                                                            <Typography variant="subtitle1">
                                                                                                {tax.name}
                                                                                            </Typography>

                                                                                            <Typography variant="subtitle1">
                                                                                                {`${(tax.rate).toFixed(2)}%`}
                                                                                                {/* {currencyFormatter.format(tax.rate)} */}
                                                                                            </Typography>
                                                                                        </li>
                                                                                })
                                                                                }
                                                                                {
                                                                                    <li className='last_item'>
                                                                                        <Button style={{width:'100%'}}
                                                                                            onClick={handleDrawerOpen}
                                                                                            variant="outlined" color="secodary">
                                                                                            <AddCircleOutlineIcon fontSize='small'
                                                                                                style={{marginRight:'8px',
                                                                                                        fontSize: '16px'
                                                                                                        }}
                                                                                            />
                                                                                            Create Preset
                                                                                        </Button>
                                                                                    </li>
                                                                                }


                                                                            </>
                                                                        </Popper>
                                                                        </ClickAwayListener>}
                                                                </Grid>
                                                                                
                                                                                
                                                                {
                                                                    invoiceItem?.tax.map((tax) => {
                                                                    return <Grid item key={tax.tax_preset_id} 
                                                                                style={{paddingTop:0, paddingBottom:0}}
                                                                                className={classes.taxItems}>

                                                                                <Typography variant='subtitle1'
                                                                                    className='d_flex center'
                                                                                >
                                                                                        {`${tax.name} ${tax.rate}% (${formatAmount(parseFloat(displayTaxAmount(tax.rate, invoiceItem, index)), selectedCurrency)})`}
                                                                                    <ClearIcon
                                                                                        onClick={(e) => {
                                                                                            onDeleteAppliedTaxItem(tax.tax_preset_id, index, invoiceItem)
                                                                                            getTaxDetails(tax);
                                                                                        }}
                                                                                        className="clear_icon"
                                                                                    />
                                                                                </Typography>
                                                                            </Grid>
                                                                    })
                                                                }

                                                                <Grid item  className="dropdown">
                                                                    <Button className={invoiceItem?.discount_value && invoiceItem?.discount_value > 0 ? "dd-button2" : "dd-button1"}
                                                                            onClick={(event) => onAddDiscountClick(event, index)}
                                                                    >
                                                                    {invoiceItem?.discount_value && invoiceItem?.discount_value > 0 ?
                                                                    <Grid item container alignItems='center'>
                                                                        <ClearIcon fontSize='small' 
                                                                            onClick={(e)=> onClearDiscount(e, invoiceItem, index)}
                                                                            style={{fontSize:'18px', marginRight:'8px', color:'#b5bbc2'}}/>
                                                                        <Typography variant='subtitle1' className={classes.discountTextStyle}>
                                                                            Discount: {displayDiscountValue(invoiceItem, index)}
                                                                        </Typography>
                                                                    </Grid>
                                                                    :
                                                                    <>
                                                                    Add Discount
                                                                    <ExpandMoreIcon className="arrow_down_icon"/>
                                                                    </>
                                                                    }
                                                                    </Button>
                                                                    {index === dropdownIndex1 &&
                                                                        <Popover id={id4} open={open4} anchorEl={anchorEl4}
                                                                            onClose={closeInvoiceDiscount}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'center',
                                                                            }}
                                                                                transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'center',
                                                                            }}
                                                                            style={{width:'300px'}}
                                                                            >
                                                                                <Grid item md={12} xs={12} style={{padding:"8px"}}>
                                                                                <Tabs
                                                                                    value={itemDetails[index].discount_type}
                                                                                    onChange={(event, newValue) => handleChange(event, newValue, invoiceItem, index)}
                                                                                    variant="fullWidth"
                                                                                    textColor="secondary"
                                                                                    aria-label="icon label tabs example"
                                                                                    className={classes.tabStyle}
                                                                                    classes={{
                                                                                        root: classes.MuiTabsroot,
                                                                                        indicator: classes.MuiTabsindicator
                                                                                    }}
                                                                                >
                                                                                    <Tab
                                                                                        style={{marginLeft:'5px'}} 
                                                                                        value={DiscountTypes.PERCENT}
                                                                                        classes={{
                                                                                        root: classes.MuiTabroot,
                                                                                        selected: classes.Muiselected
                                                                                    }} label="Percentage (%)" />
                                                                                    <Tab style={{marginRight:'5px'}} 
                                                                                        value={DiscountTypes.FLAT}
                                                                                        classes={{
                                                                                        root: classes.MuiTabroot,
                                                                                        selected: classes.Muiselected
                                                                                    }} label="Flat (₹)" />
                                                                                </Tabs>

                                                                                <Grid item container alignItems='center'
                                                                                    justifyContent='space-between' style={{marginTop:'2px'}}>
                                                                                    <Grid item md={2}>
                                                                                        <Typography variant='subtitle1'>
                                                                                            Discount:
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item md={8}>
                                                                                        <TextField variant='outlined'
                                                                                            fullWidth
                                                                                            margin='dense'
                                                                                            classes={{
                                                                                                root: classes.MuiTextFieldroot1
                                                                                            }}
                                                                                            type='number'
                                                                                            value={invoiceItem.discount_value}
                                                                                            InputProps={{
                                                                                                
                                                                                                startAdornment: invoiceItem.discount_type === DiscountTypes.FLAT && (
                                                                                                <InputAdornment position="start">
                                                                                                    <Typography className={classes.rupeeSymbolStyle}>
                                                                                                        ₹
                                                                                                    </Typography>
                                                                                                </InputAdornment>
                                                                                                ),
                                                                
                                                                                                endAdornment: invoiceItem.discount_type === DiscountTypes.PERCENT &&(
                                                                                                    <InputAdornment position='end'>
                                                                                                        <Typography className={classes.percentStyle}>
                                                                                                            %
                                                                                                        </Typography>
                                                                                                    </InputAdornment>
                                                                                                )
                                                                
                                                                                            }}
                                                                                            onChange={(e) => onInvoiceItemDiscountChange(e, invoiceItem, index)}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item md={12} style={{marginTop:'4px'}}>
                                                                                        <Button fullWidth onClick={(e) => onApplyClick(e, invoiceItem, index)}
                                                                                            variant='contained' className='button_purple'>
                                                                                            Apply Discount
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    
                                                                                </Grid>
                                                                        </Popover>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        {itemDetails && itemDetails.length - 1 !== index && <Divider style={{margin:'24px 0px 16px 0px'}}/>}
                                                    </Grid>

                                                    <Grid item sm={1}
                                                        style={{
                                                            flexBasis:'5%',
                                                            maxWidth:'5%',
                                                            textAlign:'center'
                                                        }}>
                                                            <DeleteOutlineIcon fontSize='small'
                                                                onClick={() => onDeleteLine(index)}
                                                                className={classes.binIcon}/>
                                                    </Grid>

                                                </Grid>

                                    })
                                }

                                <Grid item style={{marginTop:'16px'}}>
                                    <Button variant='outlined' color="secondary"
                                        onClick={() => itemDetailsValidated()}
                                        startIcon={<AddIcon className={classes.addIconStyle}/>}
                                        className={classes.addLineButton}
                                    >
                                        
                                        Add Line
                                    </Button>
                                </Grid>

                            </Grid>
                            <div className="border_bottom"></div>
                        </Grid>

                        <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                                <Grid item container justifyContent='space-between'>
                                    <Grid item sm={4}>
                                        <Grid item container alignItems='flex-start'
                                            spacing={1}>
                                            <Grid item>
                                                <Typography className="labels_styles"
                                                    onClick={onshowPaymentTermsClick}
                                                >
                                                    {showPaymentTerms ? <VisibilityOffOutlinedIcon className="visibility_icon"/>
                                                    : <VisibilityOutlinedIcon className="visibility_icon"/>}
                                                    <span className='show_hideLabels'>
                                                        {showPaymentTerms ? 'Hide' :'Show'}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item>
                                                <Typography 
                                                    className={classes.paymentClientLabelStyles}>
                                                    Payment Terms
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {showPaymentTerms &&
                                        <Grid item>
                                            <TextField fullWidth
                                                multiline
                                                variant="outlined"
                                                maxRows={4}
                                                value={parseString(paymentTerms)}
                                                onChange={(e) => setPaymentTerms(e.target.value)}
                                            />
                                        </Grid>}

                                        <Grid item container alignItems='flex-start'
                                            spacing={1} style={{marginTop:'8px'}}>

                                            <Grid item>
                                                <Typography className="labels_styles"
                                                    onClick={onshowClientNotesClick}>
                                                    {showClientNotes ? <VisibilityOffOutlinedIcon className="visibility_icon"/>
                                                    : <VisibilityOutlinedIcon className="visibility_icon"/>}
                                                    <span className='show_hideLabels'>
                                                        {showClientNotes ? 'Hide' :'Show'}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item>
                                                <Typography  
                                                    className={classes.paymentClientLabelStyles}>
                                                    Client Notes
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {showClientNotes &&
                                        <Grid item>
                                            <TextField fullWidth
                                                multiline={true}
                                                variant="outlined"
                                                maxRows={4}
                                                value={parseString(clientNotes)}
                                                onChange={(e) => setClientNotes(e.target.value)}
                                            /> 
                                        </Grid>}

                                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                        <>
                                        <Grid item container alignItems='flex-start'
                                            spacing={1} style={{marginTop:'8px'}}>

                                            <Grid item>
                                                <Typography className="labels_styles"
                                                    onClick={onShowQrCodeClick}>
                                                    {showUpiQrCode ? <VisibilityOffOutlinedIcon className="visibility_icon"/>
                                                    : <VisibilityOutlinedIcon className="visibility_icon"/>}
                                                    <span className='show_hideLabels'>
                                                        {showUpiQrCode ? 'Hide' :'Show'}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item>
                                                <Typography  
                                                    className={classes.paymentClientLabelStyles}>
                                                    UPI QR Code
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {showUpiQrCode && 
                                            <div style={{ height: "auto", 
                                                maxWidth: 120,
                                                width: "100%",
                                                marginTop:'8px' }}>

                                                {entityObj?.upi_id ?
                                                <QRCode 
                                                    value={`upi://pay?pa=${entityObj?.upi_id}&am=${0}`}
                                                    size="120"
                                                    logoImage="https://i.postimg.cc/5tdHfkxF/118852864-1241633666213183-4722008391193475906-n.png"
                                                    logoWidth="80"
                                                    logoHeight="100"
                                                    logoOpacity="0.6"
                                                />
                                                :
                                                <div style={{margin:'4px 0px 0px -8px', position:'relative'}}>
                                                    {accessLevelOwner && 
                                                    <Button variant='contained' color='primary'
                                                        className={classes.setUpiButton}
                                                        onClick={handleShowUpiDrawerOpen}>
                                                        Set UPI
                                                    </Button>}
                                                    <Tooltip title='Set UPI ID' arrow>
                                                        <img src='/images/placeholder_qr.png' width={120}/>
                                                    </Tooltip>
                                                </div>
                                                }
                                            </div>
                                        }
                                        </>
                                        }

                                    </Grid>

                                    <Grid item sm={8}>
                                        <Grid item sm={5} className={classes.customMarginLeft}>
                                            <Grid item container justifyContent='space-between'>
                                                <Typography className='list_item_styles'>
                                                    Subtotal
                                                </Typography>

                                                <Typography className='list_item_styles'>
                                                    {`${isNaN(getTotalPrice().toFixed(2)) ? 0.00.toFixed(2)  : 
                                                        formatAmount(getTotalPrice(), selectedCurrency)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item sm={5} className={classes.customMarginLeft}
                                            style={{position:'relative', marginTop:'12px'}} onClick={handleOpenFullDiscountMenu}>
                                            <Grid item container justifyContent='space-between'
                                                onClick={(e) => {
                                                    if(fullInvoiceDiscount[0]?.amount === 0 ||  !fullInvoiceDiscount[0]?.amount) {
                                                        e.stopPropagation();
                                                        return;
                                                    }
                                                }}>
                                                <Typography className={(fullInvoiceDiscount[0]?.amount === 0 ||  !fullInvoiceDiscount[0]?.amount) 
                                                                        ? 'list_item_styles' : 'list_item_styles1'}>
                                                    Discount
                                                </Typography>

                                                <Typography className='list_item_styles'>
                                                    {`${isNaN(fullInvoiceDiscount[0]?.amount.toFixed(2))? `₹` + 0.00.toFixed(2) : 
                                                        formatAmount(fullInvoiceDiscount[0]?.amount, selectedCurrency)}`}
                                                </Typography>
                                            </Grid>

                                            {(fullInvoiceDiscount[0]?.amount === 0 ||  !fullInvoiceDiscount[0]?.amount) &&
                                            <Grid item container 
                                                className={classes.discountButton}>
                                                <Typography variant='body1'>
                                                    <AddIcon fontSize='small' style={{marginTop:'1px'}}/>
                                                </Typography>

                                                <Typography variant='body1' style={{marginLeft:'6px'}}>
                                                    Discount entire invoice
                                                </Typography>
                                            </Grid>}
                                        </Grid>
                                        <Grid item style={{position:'relative'}}>
                                        {showFullInvoiceDiscount()}
                                        </Grid>
                                        
                                    
                                        <Grid item sm={5} className={classes.customMarginLeft}>
                                            {showTotalTaxUi()}
                                        </Grid>
                                        
                                        <Grid item sm={5} className={classes.customMarginLeft}>
                                            <div className="bill_invoice"></div>
                                            <Grid item container justifyContent='space-between'
                                                className="item_marginTop">
                                                <Typography className='list_item_styles'>
                                                    Invoice Total
                                                </Typography>

                                                <Typography className='list_item_styles'>
                                                {`${isNaN(getInvoiceTotal().toFixed(2)) ?  `₹` + 0.00.toFixed(2) : 
                                                    formatAmount(getInvoiceTotal(), selectedCurrency)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                        <Grid item sm={12} className={classes.customMarginLeft}>
                                            <Grid item container alignItems='center'>
                                                <Grid item sm={7}>
                                                    <Grid item container alignItems='center'
                                                        justifyContent='center'
                                                        className="item_marginTop">
                                                            
                                                        <Typography
                                                            style={{marginLeft:'16px'}}
                                                            className='list_item_styles'>
                                                            TDS
                                                        </Typography>
                                                        <Autocomplete
                                                            style={{width:'65%', marginLeft:'8px'}}
                                                            id="combo-box-demo"
                                                            options={props.tdsTypeList}
                                                            classes={{
                                                                root: classes.MuiAutocompleteinputRoot
                                                            }}
                                                            getOptionLabel={(option) => option.tds_display}
                                                            //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                                                            renderOption={(option) => renderOptions1(option)}
                                                            onChange={(e, newValue) => {
                                                                onTDSItemChanged(e, newValue);
                                                            }}
                                                            value={tdsTypeObj}
                                                            renderInput={params => (
                                                                <TextField {...params} variant="outlined"
                                                                placeholder='Search items'
                                                                fullWidth
                                                                classes={{
                                                                    root: classes.customTextField
                                                                }}
                                                                    />
                                                            )}
                                                            closeIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item sm={5}>
                                                    <Grid item container alignItems='center'
                                                        justifyContent='space-between'
                                                        className="item_marginTop">

                                                        <Typography className='list_item_styles'>
                                                            TDS Amount
                                                        </Typography>
                                                        <TextField id="tds-amount"
                                                            variant='outlined'
                                                            type='number'
                                                            margin='dense'
                                                            style={{width:'50%', color:'#ccc'}}
                                                            placeholder='TDS Amount'
                                                            value={tdsAmount}
                                                            onChange={(e) => setTDSAmount(e.target.value)}
                                                            InputLabelProps={{
                                                               // classes: { root: classes.labelRoot, shrink: classes.shrink },
                                                                style: {fontSize: 14, color: "#ccc"}
                                                            }}
                                                            className={classes.input}
                                                            classes={{
                                                                root: classes.MuiTextFieldroot
                                                            }}
                                                            closeIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>}

                                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) && tcsList && tcsList.length > 0 &&
                                        <Grid item sm={12} className={classes.customMarginLeft}>
                                            <Grid item container alignItems='center'>
                                                <Grid item sm={7}>
                                                    <Grid item container alignItems='center'
                                                        justifyContent='center'
                                                        className="item_marginTop">
                                                            
                                                        <Typography
                                                            style={{marginLeft:'16px'}}
                                                            className='list_item_styles'>
                                                            TCS
                                                        </Typography>
                                                        <Autocomplete
                                                            style={{width:'65%', marginLeft:'8px'}}
                                                            id="combo-box-demo"
                                                            options={props.selectedAccount?.tcs}
                                                            classes={{
                                                                root: classes.MuiAutocompleteinputRoot
                                                            }}
                                                            getOptionLabel={(option) => option.name}
                                                            //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                                                            renderOption={(option) => renderOptions2(option)}
                                                            onChange={(e, newValue) => {
                                                                onTCSItemChanged(e, newValue);
                                                            }}
                                                            value={tcsTypeObj}
                                                            renderInput={params => (
                                                                <TextField {...params} variant="outlined"
                                                                placeholder='Search items'
                                                                fullWidth
                                                                classes={{
                                                                    root: classes.customTextField
                                                                }}
                                                                    />
                                                            )}
                                                            closeIcon={<CloseIcon fontSize='small' onClick={onItemClearClick2}/>}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item sm={5}>
                                                    <Grid item container alignItems='center'
                                                        justifyContent='space-between'
                                                        className="item_marginTop">

                                                        <Typography className='list_item_styles'>
                                                            TCS Amount
                                                        </Typography>
                                                        <TextField id="tcs-amount"
                                                            variant='outlined'
                                                            type='number'
                                                            margin='dense'
                                                            style={{width:'50%', color:'#ccc'}}
                                                            placeholder='TCS Amount'
                                                            value={tcsAmount}
                                                            onChange={(e) => setTCSAmount(Number(e.target.value))}
                                                            InputLabelProps={{
                                                               // classes: { root: classes.labelRoot, shrink: classes.shrink },
                                                                style: {fontSize: 14, color: "#ccc"}
                                                            }}
                                                            className={classes.input}
                                                            classes={{
                                                                root: classes.MuiTextFieldroot
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>}

                                        <Grid item sm={5} className={classes.customMarginLeft}>
                                            <div className="bill_invoice"></div>
                                            <Grid item container justifyContent='space-between'
                                                className="item_marginTop">
                                                <Typography className='list_item_styles'>
                                                    Net Total
                                                </Typography>

                                                <Typography className='list_item_styles'>
                                                {`${formatAmount(getInvoiceTotal() - tdsAmount + tcsAmount, selectedCurrency)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item sm={5} className={classes.customMarginLeft}>
                                            {
                                                Object.keys(invoiceObj).length !== 0 &&
                                                <>
                                                <Grid item container justifyContent='space-between'
                                                    className="item_marginTop">
                                                    <Typography className='list_item_styles'>
                                                        Paid to date
                                                    </Typography>

                                                    <Typography className='list_item_styles'>
                                                        {`${isNaN(amountPaid.toFixed(2)) ?  `₹` + 0.00.toFixed(2)  :  
                                                            formatAmount(amountPaid, selectedCurrency)}`}
                                                    </Typography>
                                                </Grid>

                                                <Grid item container justifyContent='space-between'
                                                    className="item_marginTop">
                                                    <Typography className='list_item_styles'>
                                                        Amount Due
                                                    </Typography>

                                                    <Typography className='list_item_styles'>
                                                        {`${isNaN(getDueAmount().toFixed(2)) ?  `₹` + 0.00.toFixed(2)  : 
                                                            formatAmount(getDueAmount() - Number(tdsAmount) + Number(tcsAmount), selectedCurrency)}`}
                                                    </Typography>
                                                </Grid>
                                                </>
                                            }
                                        </Grid>

                                    </Grid>

                                </Grid>
                                {selectedEntityObj?.enable_esign ? <div className="border_bottom_one"></div> : <div className="dashed_border"></div>}
                        </Grid>

                        {selectedEntityObj?.enable_esign &&
                        <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                            <Grid item container direction='column' alignItems='flex-end'>
                                
                                {eSignUrl &&
                                    <Grid item style={{position:'relative', top:'3px'}}>
                                        <div className={'eSign_style'}>
                                            <img src={eSignUrl} width='40%' />
                                        </div>
                                        <Typography style={{fontWeight:400, marginTop:'4px'}}>
                                            Authorized Signatory
                                        </Typography>
                                    </Grid>
                                }

                            </Grid>
                            <div className="dashed_border"></div>
                        </Grid>}

                        <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                            <Grid item container justifyContent='space-between'
                                alignItems='center'>
                                <Typography  style={{color:'#4a5568 !important', fontSize:'13px', fontWeight:400}}>
                                    Payment Stub
                                </Typography>

                                <Typography className="labels_styles"
                                    onClick={onShowPaymentStubClick}
                                >
                                    {showPaymentStub ? <VisibilityOffOutlinedIcon className="visibility_icon"/>
                                    : <VisibilityOutlinedIcon className="visibility_icon"/>}
                                    <span className='show_hideLabels'>
                                        {showPaymentStub ? 'Hide Payment Stub' :'Show Payment Stub'}
                                    </span>
                                </Typography>
                            </Grid>

                            {showPaymentStub &&
                            <Grid item container justifyContent='space-between' style={{marginTop:'16px'}}>
                                <Grid item sm={5} className="payment_stubContainer"
                                    style={{flexBasis:'56%', maxWidth:'56%'}}
                                >
                                    <Typography className='clientOwner_address'>
                                        Bill from:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {entityObj?.name}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                        <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                        </div>
                                        <div>{entityObj?.country}</div>
                                        <div>Tel: {entityObj?.mobile}</div>
                                        <div>Email: {entityObj?.email}</div> 
                                        {entityObj?.tax_id &&
                                            <div>Tax Id: {entityObj?.tax_id}</div>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item sm={5}>
                                    <Grid item container justifyContent='space-between'>
                                        <Typography className='list_item_styles'>
                                            Client
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                            {clientName}
                                        </Typography>
                                    </Grid>

                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop1">
                                        <Typography className='list_item_styles'>
                                            Invoice
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                            #{invoiceNumber}
                                        </Typography>
                                    </Grid>

                                    {showTotalTaxUi()}

                                    <div className="bill_invoice"></div>
                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop1">
                                        <Typography className='list_item_styles'>
                                            Amount Due
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                            {`${isNaN(getDueAmount().toFixed(2)) ?  `₹` + 0.00.toFixed(2) : 
                                                formatAmount(getDueAmount() - Number(tdsAmount) + Number(tcsAmount), selectedCurrency)}`}
                                        </Typography>
                                    </Grid>

                                    {/* <Grid item container justifyContent='space-between'
                                        className="item_marginTop1">
                                        <Typography>
                                            Amount Enclosed
                                        </Typography>

                                        <Typography>
                                            0.00₹
                                        </Typography>
                                    </Grid> */}

                                </Grid>
                            </Grid>}

                        </Grid>
                </Grid>
                <Grid item sm={2} className={classes.secondColumnContainer}>
                        <Grid item container direction='column' 
                            alignItems='center' 
                            className="save_invoice"
                        
                        >

                            <Button fullWidth className={classes.buttonStyles}
                                onClick={() => onCreateUpdateInvoiceClick(true, false, false)}
                                variant="contained" style={{background:'#4a5568', color:'#fff'}} 
                                disabled={accessLevelViewer}>
                                <PictureAsPdfIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />
                                    Download
                                    <Link
                                        style={{ display: "none" }}
                                        id="invoice_download"
                                        target="_blank"
                                        onClick={(e) => e.stopPropagation()}
                                        download
                                    ></Link>
                            </Button>

                            <Button fullWidth
                                onClick={() => onCreateUpdateInvoiceClick(false, false, true)}
                                disabled={invoiceLoading || previewAndSend || accessLevelViewer}
                                variant="outlined" color="secondary">
                                {invoiceLoading && !previewAndSend ? <CircularProgress size={15} style={{marginRight:'8px'}}/> :
                                <SaveOutlinedIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                {Object.keys(invoiceObj).length !== 0 ? 'Update Invoice' : 'Save Invoice'}
                            </Button>
                        </Grid>

                        {!accessLevelViewer &&
                        <Grid item sm={12} className="esign_style">

                            <Typography variant="body1" style={{fontWeight:500, color:'#4a5568'}}>
                                E-Signature
                            </Typography>

                            <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedEntityObj?.enable_esign ? selectedEntityObj?.enable_esign : false}
                                    onChange={() => handleESignChange(false)}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Enable E-Sign"
                            />
                        </Grid>} 

                        {!accessLevelViewer &&
                        <RecurringInvoiceSettings issueDate={issuedDate}
                            setIntervalDays={setIntervalDays}
                            intervalDays={intervalDays}
                            setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                            handleInvoiceDialogClose={props.handleInvoiceDialogClose}/>
                        }

                        {
                            !accessLevelViewer && !props.showCreateInvoice && invoiceObj?.status !== InoviceStatus.DRAFT && invoiceObj?.status !== InoviceStatus.PAID &&
                            <EnableDisableReminders reminderObj={reminderObj}
                                invoiceObj={invoiceObj}
                                updateInvoiceFuncObj={props.setEditInvoiceObj}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                invoiceAccountId={props.selectedAccount?.id}
                                invoiceId={invoiceObj.id}
                            />
                        }

                        {/* <Grid item sm={12} className="reminders">

                            <Typography variant="body1">
                                Reminders
                            </Typography>
                        </Grid> */}

                </Grid>
            </Grid>
            <CreateTaxPresetDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
            />

            <AddEditInvoiceItemsDrawer openDrawer={openAddEditItemsDrawer}
                handleDrawerClose={handleAddEditItemDrawerClose}
                fromCreateEditInvoice={true}
                itemIndex={itemIndex}
                onItemClick={onItemClick}
                onAddItemsClick={onAddItemsClick}
                itemObj={{}}
            />

            <AddEditClientDrawer openClientDrawer={openClientDrawer} 
                    handleClientDrawerClose={handleEditClientDrawerClose}
                    editClientObj={editClientObj}
                    setClientName={setClientName}
                    setClientAddress={setClientAddress}
                    setClientState={setClientState}
                    setClientCountry={setClientCountry}
                    setClientMobile={setClientMobile}
                    setClientEmail={setClientEmail}
                    setClientUserDefinedField={setClientUserDefinedField}
                    clientUserDefinedFields={clientUserDefinedFields}
                    setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                    fromCreateEditOrPreviewInvoice={true}
                    //addClient={addClient}
            />

            {
                openChangeCustomerModal && 
                <InvoiceModalComponent openDialog={openChangeCustomerModal}
                    handleClickDialogClose={handleChangeCustomerModalClose}
                    openChangeCustomerModal={openChangeCustomerModal}
                    fromCreateUpdateInvoiceComponent={true}
                    />
            }

            {
                openChangeEntityModal && 
                <InvoiceModalComponent openDialog={openChangeEntityModal}
                    handleClickDialogClose={handleChangeEntityModalClose}
                    openChangeEntityModal={openChangeEntityModal}
                    fromCreateUpdateInvoiceComponent={true}
                    setSelectedEntityObj={setSelectedEntityObj}
                    />
            }
            {openESignDialog && <EnableInvoiceESignModal openESignDialog={openESignDialog}
                handleEnableInvoiceESignModalClose={handleEnableInvoiceESignModalClose}
                entityObj={entityObj}
                fromCreateUpdateInvoiceComponent={true}
                selectedEntityObj={selectedEntityObj}
                setSelectedEntityObj={setSelectedEntityObj}/>
            }
            {
                openUpdateNumberDialog && <UpdateNumberModal openUpdateNumberDialog={openUpdateNumberDialog}
                                            handleUpdateNumberDialogClose={handleUpdateNumberDialogClose}
                                            invoiceNumberString={invoiceNumberString}
                                            invoiceNumber={invoiceNumber}
                                            entityObj={entityObj}
                                            setInvoiceNumber={setInvoiceNumber}
                                            invoiceObj={invoiceObj}
                                            setDontCallApi={setDontCallApi}
                                            setEntityNextInvoiceNumber={setEntityNextInvoiceNumber}/>
            }

            {showUpiDrawer && 
            <SetUPIDrawer showUpiDrawer={showUpiDrawer}
                handleShowUpiDrawerClose={handleShowUpiDrawerClose}
                entityObj={entityObj}
                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                setDontCallApi={setDontCallApi}
                setCallGetInvoiceListApi={props.setCallGetInvoiceListApi}
                />}
        </Container>
    );
}

const mapStateToProps = (state) => ({
    customerObj: state.invoiceAccounts.customerObj,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showSendInvoiceScreen: state.invoiceAccounts.showSendInvoiceScreen,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
    user: state.invoiceAccounts.user,
    entityObj: state.invoiceAccounts.entityObj,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    drawerState: state.drawerState.setDrawerState,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,

});

const mapDispatchToProps = (dispatch) => ({
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    logout: () => dispatch(logout()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
    setShowSendInvoiceScreenType: (sendInvoiceScreenType) => dispatch(setShowSendInvoiceScreenType(sendInvoiceScreenType)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
    setShowPreviewInvoiceScreen: (previewScreen) => dispatch(setShowPreviewInvoiceScreen(previewScreen)),
    setEntityObj: (entity) => dispatch(setEntityObj(entity)),

    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateInvoiceComponent);