import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  IconButton,
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import RightToLeftSideDrawer from "./RightToLeftSideDrawer";
import { getClientListApi } from "../services/authService";
import {
  consoleToLog,
  boldString,
  showDate,
  capitalizeFirstLetterOfEachWord,
  isInvoiceAccountCountryIndia,
} from "../util/AppUtil";
import { connect } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useIsMount } from "./useIsMount";
import { getPendingInvoiceListApi } from "../services/authService";
import { formatAmount } from "../util/currencyUtil";
import { AccessLevel } from "../util/AppUtil";

const useStyles = makeStyles((theme) => ({
  customMarginTop: {
    marginTop: "16px",
  },
  customMarginBottom: {
    marginBottom: "-8px",
  },
  listContainer: {
    width: "100% !important",
    "&:last-child .dropdownDivider": {
      display: "none",
    },
  },
  avatarStyle: {
    width: "28px",
    height: "28px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#2B2B2B",
    marginRight: "-8px",
  },
  list: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    padding: "0px 8px 0px 16px",
    "&:hover": {
      background: "#F9F9F9",
    },
  },
  dividerStyle: {
    background: "#000",
    opacity: "0.1",
  },
  dueInvoiceListContainer: {
    boxSizing: "border-box",
    border: "1px solid #ccc",
    padding: "24px 24px 0px 24px",
    borderRadius: "4px",
    marginBottom: "16px",
  },
  invoiceNumber: {
    background: "#d2f2d6",
    padding: "4px 8px",
    borderRadius: "4px",
    color: "#4a8c50",
  },
  dueDate: {
    color: "#8d96a2",
    fontWeight: "500",
    marginLeft: "16px",
  },
  divider: {
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    margin: "18px 0px",
  },
  headingText: {
    color: "#8d96a2",
  },
  plusIcon: {
    marginRight: "14px",
  },
  invoiceType: {
    color: "#747775",
    background: "#ededed",
    padding: "4px 8px",
    borderRadius: "4px",
  },
}));

const AddNewPaymentDrawer = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();

  const [seacrhClient, setSearchClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [pendingInvoiceList, setPendingInvoiceList] = useState([]);

  const proforma_enabled =
    props.selectedAccount.general_settings?.proforma_enabled;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER ? true : false;

  const invoiceAccountCountry = props.selectedAccount?.country;

  const getClientsList = (text) => {
    const invoice_account_id = props.selectedAccount.id;

    getClientListApi(invoice_account_id, text)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response getClientListApi: ", res);
        setClientList(res);
      })
      .catch((e) => {
        consoleToLog("Error getClientListApi ", e);
        if (e.response.data && e.response.data.message) {
          return false;
        }
      });
  };

  useEffect(() => {
    if (!isMount) {
      if (selectedClient) {
        getPendingInvoicesList();
      }
    }
  }, [selectedClient]);

  useEffect(() => {
    if (!isMount) {
      if (seacrhClient === "") {
        setSelectedClient(undefined);
      }
    }
  }, [seacrhClient]);

  const onItemClick = (client) => {
    setSearchClient("");
    setSearchClient(client.name);
    setSelectedClient(client);
    setClientList([]);
  };

  const onClearIconClick = () => {
    setSearchClient("");
    setSearchClient("");
    setSelectedClient(undefined);
    setClientList([]);
  };

  const getPendingInvoicesList = () => {
    const invoice_account_id = props.selectedAccount?.id;
    const client_id = selectedClient?.id;

    getPendingInvoiceListApi(invoice_account_id, client_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response getPendingInvoiceListApi:", res);
        setPendingInvoiceList(res);
      })
      .catch((e) => {
        consoleToLog("Error getClientListApi ", e);
        if (e.response.data && e.response.data.message) {
          return false;
        }
      });
  };

  const clearAllValues = () => {
    setSearchClient("");
    setClientList([]);
    setPendingInvoiceList([]);
    props.handleCloseAddNewPaymentDrawer();
  };

  return (
    <RightToLeftSideDrawer
      title={"Add Payment"}
      openDrawer={props.openAddNewPaymentDrawer}
      closeDrawer={clearAllValues}
      addNewPayment={true}
    >
      <Grid
        item
        container
        direction="column"
        style={{ padding: "16px 24px", marginBottom: "18px" }}
      >
        <Grid item>
          <Typography variant="body1" style={{ fontWeight: "500" }}>
            Select Customer you are receiving from
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="column"
          style={{ position: "relative", marginBottom: "16px" }}
        >
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            classes={{
              root: classes.MuiTextFieldroot,
            }}
            value={seacrhClient}
            InputLabelProps={{ style: { fontSize: 14, color: "#ccc" } }}
            placeholder="Find Customer"
            onChange={(e) => {
              setSearchClient(e.target.value);
              getClientsList(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <SearchIcon className={classes.searchicon}/> */}
                  <i className="flaticon-search"></i>
                </InputAdornment>
              ),

              endAdornment: selectedClient && (
                <InputAdornment position="end" onClick={onClearIconClick}>
                  <ClearIcon
                    style={{
                      color: "#ccc",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {clientList && clientList.length > 0 && (
            <Grid
              item
              sm={12}
              className={`company__menu ${
                seacrhClient !== "" ? "active" : "inactive"
              }`}
            >
              {clientList.length > 0 ? (
                clientList.map((client) => {
                  const client_name = client.name;
                  const compArr = client.name.split(" ");
                  let compStr1 = compArr[0];
                  let compStr2 = compArr[1] ? compArr[1] : "";
                  const finalStr =
                    compStr1.charAt(0).toUpperCase() +
                    compStr2.charAt(0).toUpperCase();

                  return (
                    <ul key={client.id} className={classes.listContainer}>
                      <li
                        className={classes.list}
                        onClick={() => onItemClick(client)}
                      >
                        <Avatar
                          style={{
                            background: "white",
                            color: "#2B2B2B",
                            border: "1px solid #2B2B2B",
                          }}
                          className={classes.avatarStyle}
                        >
                          {finalStr}
                        </Avatar>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: boldString(client_name, seacrhClient),
                          }}
                        ></span>
                      </li>
                      <Divider
                        className={`dropdownDivider ${classes.dividerStyle}`}
                      />
                    </ul>
                  );
                })
              ) : (
                <ul
                  className={classes.listContainer}
                  style={{ textAlign: "right" }}
                >
                  <li className={classes.list}>
                    <span style={{ fontSize: "14px", color: "#555" }}>
                      No Customers Found
                    </span>
                  </li>
                </ul>
              )}
            </Grid>
          )}
        </Grid>

        {selectedClient &&
        pendingInvoiceList &&
        pendingInvoiceList.length > 0 ? (
          pendingInvoiceList.map((invoice) => {
            let currency = invoice?.currency;
            let locale = invoice?.locale;
            return (
              <Grid
                item
                className={classes.dueInvoiceListContainer}
                style={{ paddingBottom: "24px" }}
                key={invoice.id}
              >
                <Grid item container alignItems="center">
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.invoiceNumber}
                    >
                      Invoice {invoice.invoice_number}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body2" className={classes.dueDate}>
                      {showDate(invoice.due_date)}
                    </Typography>
                  </Grid>

                  {proforma_enabled && (
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Typography
                        variant="body2"
                        className={classes.invoiceType}
                      >
                        {capitalizeFirstLetterOfEachWord(invoice.invoice_type)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <div className={classes.divider}></div>

                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ padding: "0px 8px" }}
                >
                  <Grid item md={4}>
                    <Typography variant="body2" className={classes.headingText}>
                      Invoice Total
                    </Typography>

                    <Typography variant="body2">
                      {formatAmount(invoice.total, invoice.currencies)}
                    </Typography>
                  </Grid>

                  {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                  <Grid item md={4} style={{ textAlign: "center" }}>
                    <Typography variant="body2" className={classes.headingText}>
                      TDS Amount
                    </Typography>

                    <Typography variant="body2">
                      {formatAmount(invoice.tds_amount, invoice.currencies)}
                    </Typography>
                  </Grid>}

                  <Grid item md={4} style={{ textAlign: "right" }}>
                    <Typography variant="body2" className={classes.headingText}>
                      Amount due
                    </Typography>

                    <Typography variant="body2" style={{ color: "red" }}>
                      {formatAmount(invoice.due, invoice.currencies)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  style={{ marginTop: "16px", marginBottom: "-8px" }}
                >
                  <Button
                    variant="outlined"
                    onClick={(e) => props.handleOpenAddPaymentDrawer(e, invoice)}
                    fullWidth
                    style={{ color: "green" }}
                    disabled={accessLevelViewer}
                  >
                    <AddCircleOutlineIcon
                      fontSize="small"
                      className={classes.plusIcon}
                    />
                    Add Payment
                  </Button>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <>
            {selectedClient && (
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  marginTop: "8px",
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "4px",
                  padding: "8px 0",
                }}
              >
                <Typography variant="body2">
                  No Pending Invoices Found
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </RightToLeftSideDrawer>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.invoiceAccounts.selectedAccount,
});

export default connect(mapStateToProps)(AddNewPaymentDrawer);
