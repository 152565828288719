import React from 'react';
import {Grid, Typography, Dialog, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Slide from "@material-ui/core/Slide";
import { connect } from 'react-redux';
import CreateUpdateInvoiceComponent from '../CreateUpdateInvoiceComponent';
import PreviewAndSendInvoiceComponent from '../PreviewAndSendInvoiceComponent';
import {SendEmailType} from '../../actions/invoiceAccount';
import {setCreateInvoiceAccount, setInvoiceItemObj, setCustomerObject, 
    setShowPreviewInvoiceScreen, setShowSendInvoiceScreenType, setEditInvoiceObj} from '../../actions/invoiceAccount';
import { history } from '../../routers/AppRouter';
import { getUrlAccordingToSelectedComponent } from '../../util/AppUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEditPreviewInvoiceModal = (props) => {

    const closeDialog = () => {
        history.push(getUrlAccordingToSelectedComponent(props.drawerState));
        props.showCreateInvoice && props.setCreateInvoiceAccount(false);
        Object.keys(props.invoiceObj).length > 0 && props.setInvoiceItemObj({});
        Object.keys(props.editInvoiceObj).length > 0 && props.setEditInvoiceObj({});
        props.setCustomerObject({});
        if(props.showPreviewInvoiceScreen) props.setShowPreviewInvoiceScreen(false);
        if(props.showSendInvoiceScreenType !== SendEmailType.NONE) props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        props.handleInvoiceDialogClose();
    }

    return (
        <Dialog
            open={props.openInvoiceDialog}
            TransitionComponent={Transition}
            scroll="paper"
            fullScreen
            style={{scrollbarWidth:'none !important', zIndex:'100'}}
            onClose={closeDialog}>

                <Grid item md={12} style={{background:'#f7f7f7'}}>

                    {Object.keys(props.invoiceObj).length !== 0 || (props.invoiceObj && props.showPreviewInvoiceScreen) || 
                    (props.invoiceObj && props.showSendInvoiceScreenType !== SendEmailType.NONE) 
                    ? 
                    <PreviewAndSendInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                        setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                        setAddTransaction={props.setAddTransaction}
                        updateTranscItemInTranscList={props.updateTranscItemInTranscList}/> 
                    :
                    <CreateUpdateInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                        setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                        updateInvoiceItemListInClients={props.updateInvoiceItemListInClients}
                        setCallGetInvoiceListApi={props.setCallGetInvoiceListApi}/>}

                </Grid>
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    setShowPreviewInvoiceScreen: (previewInvoiceScreen) => dispatch(setShowPreviewInvoiceScreen(previewInvoiceScreen)),
    setShowSendInvoiceScreenType: (sendInvoiceScreenType) => dispatch(setShowSendInvoiceScreenType(sendInvoiceScreenType)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPreviewInvoiceModal);