import React, {useState, createRef, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, Button, Typography, CircularProgress, Tooltip, Popover, MenuItem, IconButton, Select,
    Drawer, AppBar, Toolbar, Link, Paper} from '@material-ui/core'; 
import {connect} from 'react-redux';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { showAllCountries, showAllStates } from '../util/AppUtil';
import ClearIcon from '@material-ui/icons/Clear';
import {udfTypes, consoleToLog, isValidUserDefinedFields, capitalizeFirstLetterOfEachWord, 
    removeSpace, show401ErrorAndClearRedux} from '../util/AppUtil';
import {Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import {companyNameValidator, mobileNoValidator, emailValidator} from '../util/validator';
import S3FileUpload from "react-s3";
import {addClientApi, editClientApi} from '../services/clientService';
import {useIsMount} from './useIsMount';
import {setEditInvoiceObj, setInvoiceItemObj, setCustomerObject} from '../actions/invoiceAccount';
import CustomFieldDrawer from './CustomFieldDrawer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {phoneNumberValidate} from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import {getClientUploadURLApi, uploadCustomFieldSuccessApi, deleteCustomFieldAttachmentApi, downloadAttachmentApi} from '../services/authService';
import axios from 'axios';
import { trackGAEvent } from '../util/AppUtil';
import countryState from '../data/countryState.json';
import country from "../data/country.json";
import { getCountryObj, getStateObj } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    drawer: {
            flexShrink: 0,
        },
    drawerPaper: {
        width: '550px',
        height:'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px',
    },
    drawerPaper1: {
        width: '550px',
        height:'100vh',
        flexGrow:1,
        overflow:'auto'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },
    listContainer : {
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
    udfButton : {
        position: "relative",
        width: "27px",
        height: "27px",
        minWidth: "0",
        margin: "-5px 0px 0px 10px",
        fontSize: "14px",
        color: "#555555",
        borderRadius: "50%",
        border: "1px solid #555555",
        paddingTop: "5px",
        //display: props.isFromWaitingForMyApproval ? "none" : "flex",
    },
    customColumn: {
        maxWidth: '31.5%',
        flexBasis: '31.5%',
    },
    customColumn2: {
        maxWidth: '5.5%',
        flexBasis: '5.5%',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
      enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
      disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
    downloadLink: {
        color: "#20a8e0",
        fontSize: "15px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
    },
    customColumn: {
        flexBasis:'31.2%',
        maxWidth:'31.2%'
    }, 
    customColumn1: {
        flexBasis:'6%',
        maxWidth:'6%'
    },
    addCustomFieldStyle: {
        margin:'8px auto 4px 4px', 
        width:'95%', 
        color:'#2196f3', 
        borderColor:'#d4d8de'
    },
    addItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
    MuiButtonlabel: {
        fontSize:'13px'
    },
    customMarginBottom1: {
        marginBottom:'-4px'
    }
}));   

const AddEditClientDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    
    const [clientName, setClientName] = useState('');
    const [emails, setEmails] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [compAddress, setCompAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [selectedState, setSelectedState] = useState(undefined);
    const [taxId, setTaxId] = useState('');
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentIndex, setCurrentIndexIndex] = useState(undefined);
    const [switchFocus, setSwitchFocus] = useState(false);
    const [stateList, setStateList] = useState([]);

    const [userDefinedFields, setUserDefinedFields] = useState([]);
    
    const customFieldList = props.selectedAccount?.custom_fields;
    const scrollToDiv = createRef();
    const focusTextInput = createRef();
    const {countries} = country;
    const {states} = countryState;

    useEffect(() => {
        if(!isMount) {
            if(scrollToDiv.current) {
                scrollToDiv.current.scrollIntoView({
                    behavior: "smooth"
                });
            }
        }

    }, [scrollToDiv]);

    useEffect(() => {
        if(props.editClientObj) {
            setClientName(props.editClientObj?.name);
            setEmails(props.editClientObj.email ? [props.editClientObj.email] : props.editClientObj.email_array);
            setPhoneNumber(props.editClientObj?.phone_number);
            setCompAddress(props.editClientObj?.address);

            let countryObj = getCountryObj(countries, props.editClientObj?.country);
            setSelectedCountry(countryObj);
            let stateObj = getStateObj(states, props.editClientObj?.state,  props.editClientObj?.country);
            setSelectedState(stateObj);

            setTaxId(props.editClientObj?.tax_id);
            setUserDefinedFields(props.editClientObj?.user_defined_field ? props.editClientObj?.user_defined_field : []);

            }
    }, [props.editClientObj]);

    useEffect(() => {
        if(props.editClient) {
            console.log('edit client from client page', props.editClient);
            setClientName(props.editClient?.name);
            setEmails(props.editClient.email !== '' ? [props.editClient.email] : []);
            setPhoneNumber(props.editClient?.phone_number);
            setCompAddress(props.editClient?.address);

            let countryObj = getCountryObj(countries, props.editClient?.country);
            setSelectedCountry(countryObj);
            let stateObj = getStateObj(states, props.editClient?.state, props.editClient?.country);
            setSelectedState(stateObj);

            setTaxId(props.editClient?.tax_id);
            setUserDefinedFields(props.editClient?.user_defined_field ? props.editClient?.user_defined_field : []);

        }
    }, [props.editClient]);

    useEffect(() => {
        if(!isMount) {
            if(switchFocus) {
                focusTextInput.current?.focus();
            }
        }

    }, [focusTextInput]);

    useEffect(() => {
        if(!isMount) {
            if(selectedCountry) {
                const {states} = countryState;
                const state_list = states.filter((state) => state.country_code === selectedCountry?.code);
                setStateList(state_list);
            }
        }
    }, [selectedCountry]);

    const onCustomFieldAdded = () => {
        //allow repeatation give warning about error
        var newUDFObj = {
            name: undefined,
            type: undefined,
            value: undefined,
            show_invoice: false,
        };
        setUserDefinedFields([...userDefinedFields, newUDFObj]);
        props.editClientObj && !props.editClient &&
        props.setClientUserDefinedField([...userDefinedFields, newUDFObj]);
    };

    const onUDFNameChanged = (e, udf, index) => {
        const updatedArray = userDefinedFields;
        updatedArray[index].name = udf.name;
        setUserDefinedFields([...updatedArray]);
    }

    const showDefaultClientValues = (option) => {
        const itemObj = option.default_value;
        //console.log('*******************',option, itemObj)
        switch (itemObj) {
            case '{{client.name}}':
                return clientName;
    
            case '{{client.address}}':
                return compAddress;
    
            case '{{client.country}}':
                return selectedCountry;
            
            case '{{client.state}}':
                return selectedState;
                
            case '{{client.tax_id}}':
                return taxId
        
            default:
                break;
        }
    }

    const onItemClick = (option, index) => {
        const updatedArray = userDefinedFields;
        
        updatedArray[index].name = option.name;
        updatedArray[index].type = option.type;
        updatedArray[index].value = showDefaultClientValues(option);
        updatedArray[index].show_invoice = option.show_invoice;
        focusTextInput.current?.focus();
        setUserDefinedFields([...updatedArray]);
        if(switchFocus) setSwitchFocus(false);
    }

    const renderOptions = (option, index, fromState) => {
        return (
            <div className={classes.itemOptionStyle}>
                <Grid item container 
                    onClick={() => onItemClick(option, index)}
                    justifyContent="space-between">
                    <Typography variant='body2'>
                        {option.name}
                    </Typography> 
                </Grid>    

            </div>
            );
    }

    const onItemClearClick = (index) => {
        const updatedArray = userDefinedFields;
        
        updatedArray[index].name = undefined;
        updatedArray[index].type = undefined;
        updatedArray[index].value = undefined;

        setUserDefinedFields([...updatedArray]);
        
    }

    const closeDrawer = () => {
        setClientName('');
        setEmails([]);
        setCompAddress('');
        setClientName('');
        setPhoneNumber('');
        setTaxId('');
        setSelectedCountry(undefined);
        setSelectedState(undefined);
        setUserDefinedFields([]);
        props.handleClientDrawerClose();
        props.editClient && props.setEditClient(undefined);
        setStateList([]);
    }

    const onUpdateUDFValue = (e, index, type) => {
        var newValue = undefined;
        if (type === udfTypes.ImageOrDocument.value) {
          //file
            newValue = e.target.files[0];
            console.log('newFile', newValue);
          //uploadFileIfExists(newValue, index)
        } else {
          //text password
            newValue = e.target.value;
        }
        const allObjs = userDefinedFields;
        allObjs[index].value = newValue;
        setUserDefinedFields([...allObjs]);
        props.editClientObj && !props.editClient &&
        props.setClientUserDefinedField([...allObjs]);
    };

    const onRemoveUDFObj = (removeUDFObj, index) => {
        consoleToLog("removeUDFObj", removeUDFObj);
        if (removeUDFObj.type === udfTypes.ImageOrDocument.value && removeUDFObj.value && typeof(removeUDFObj.value) !== 'object') {
            //setDeletedFiles([...deletedFiles, removeUDFObj]);
            callRemoveAttachmentApi(removeUDFObj);
        }

        const updatedArray = userDefinedFields.filter((udf, i) => udf.name !== removeUDFObj.name);
        setUserDefinedFields(updatedArray);
        props.editClientObj && !props.editClient &&
        props.setClientUserDefinedField(updatedArray);
    };

    const callRemoveAttachmentApi = (removeUDFObj) => {
        const client_id = props.editClientObj ? props.editClientObj?.id : props.editClient.id;
        
        deleteCustomFieldAttachmentApi(client_id, removeUDFObj.value) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteCustomFieldAttachmentApi: ', res);
            })
            .catch((e) => {
                consoleToLog('Error deleteCustomFieldAttachmentApi', e.response);
            })
    }

    const onRemoveCustomFieldClick = (udf, index) => {
        const editUDFArray = props.editClientObj ? props.editClientObj?.user_defined_field : props.editClient?.user_defined_field
        const isFromParentCustomField = editUDFArray?.includes(udf);

        if(isFromParentCustomField) {
            if(window.confirm('Are you sure you want to delete this Custom Field?')) {
                onRemoveUDFObj(udf, index);
            }
        } else {
            onRemoveUDFObj(udf, index);
        }

    }

    const filterCustomFeilds = () => {
        let customFieldArr;
        if(props.editClientObj || props.editClient) {
            return customFieldArr = customFieldList.filter((cf) => userDefinedFields.find(filter => filter.name === cf.name) === undefined);
        } else {
            return customFieldArr = customFieldList.filter((cf) => userDefinedFields.find(filter => filter.name === cf.name) === undefined).filter((option) => option.type !== udfTypes.ImageOrDocument.value);
        }
    }

    const customNameField = (udf) => {
        const udfName = userDefinedFields.find((udfField) => udfField.name === udf.name);
        //console.log('udf name*****************************', udfName);
        return udfName?.name ? true : false;
    }

    const handleOpenCustomFieldDrawer = () => {
        setOpenDrawer(true);
    }

    const handleCloseCustomFieldDrawer = () => {
        setOpenDrawer(false);
    }


    

    const AddCustomFieldButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
            <Button fullWidth
                className={classes.addCustomFieldStyle}
                variant="outlined"
                color="primary"
                style={{padding:'6px 2px'}}
                onMouseDown={handleOpenCustomFieldDrawer}
                classes={{
                    label: classes.MuiButtonlabel
                }}
            >
            <AddCircleOutlineIcon fontSize='small' className={classes.addItemIcon}/>  
                Add Custom Field
            </Button>
            {children}
        </Paper>
        );
    
    const  getSelectedItem = (index) => {
        const itemObj = customFieldList.find((cf) => {
            if(cf.name === userDefinedFields[index].name) {
                return cf
            } 
        });
        //console.log('**********item', itemObj);

        return itemObj || {};
    }

    const downloadAttachmentUrl = (value) => {
        const invoice_account_id = props.selectedAccount?.id;
    
        //get downloadable url from backend using url
        downloadAttachmentApi(invoice_account_id, value)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response downloadAttachmentApi: ", res);

                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById("udfFileDownloader").href = downloadableURL;
                document.getElementById("udfFileDownloader").click();

                trackGAEvent(props.selectedAccount.name, 'Client attachment downloaded', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog("downloadAttachmentApi error", e.response);
                show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            });
    }

    const showCustomField = () => {
        return (
            userDefinedFields && userDefinedFields.map((udf, index) => {
            const typeOfValueInputField =
            udf.type === udfTypes.Text.value
                ? "text"
                : udf.type === udfTypes.Password.value
                ? "password"
                : "";
            return (
            <Grid item container spacing={1}
                ref={scrollToDiv}
                key={index}
                onClick={() => setCurrentIndexIndex(index)}
                alignItems="center">

                <Grid item sm={3} className={classes.customColumn}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={filterCustomFeilds()}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        //value={getSelectedItem(index)}
                        value={userDefinedFields[index]}
                        disabled={customNameField(udf)}
                        getOptionLabel={(option) => option.name}
                        //inputValue={invoiceItem.item_name}
                        //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                        renderOption={(option) => renderOptions(option, index)}
                        onChange={(e, newValue) => {
                            onUDFNameChanged(e, newValue, index);
                            onItemClick(newValue, index)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Custom Field' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                        closeIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)}/>}
                        PaperComponent={AddCustomFieldButton}
                    />
                </Grid>
    
                <Grid item sm={3} className={classes.customColumn}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        disabled
                        value={udf.type ? udf.type : -1}
                        select
                        classes={{root: classes.MuiFormControlroot,}}>
                            {Object.values(udfTypes).map((typeObj) => {
                            return (
                                <MenuItem key={typeObj.value} value={typeObj.value}>
                                {typeObj.displayName}
                                </MenuItem>
                            );
                            })}
                    </TextField>
                </Grid>

                <Grid item sm={3} className={classes.customColumn}>
                    {udf.type === udfTypes.ImageOrDocument.value ? (
                    <Grid item container sm={12}>
                        {!udf.value  && (
                        <label>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                            />
    
                            <Typography variant="body2"
                                style={{
                                    marginTop: "2px",
                                    marginBottom: "none",
                                    padding: "8px 38px 8px 32px",
                                    border: "1px dashed #555",
                                    cursor: "pointer",
                                    color: "#555",
                            }}>
                            <i className="flaticon-attachment"  style={{ marginRight: "6px" }}></i>
                                Attach File
                            </Typography>
                        </label>
                        )}
                        {(udf.value && (udf.value.name && udf.value.name.indexOf("/") === -1)) ? (
                        <Tooltip
                            title={udf.value.name
                                ? udf.value.name
                                : udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            arrow
                        >
                            <Typography
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                            }}
                            >
                            {udf.value.name
                            ? udf.value.name
                            :udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            </Typography>
                        </Tooltip>
                        )
                                :
                        (udf.value && (udf.value !== '' && udf.value?.indexOf("/") > -1)) && (
                        <Tooltip title={udf.value?.replace(/^.*[\\\/]/, "")} arrow>
                        <div  onClick={() => downloadAttachmentUrl(udf.value)}>
                            <Typography className={classes.downloadLink}>
                                {udf.value?.replace(/^.*[\\\/]/, "")}
                            </Typography>
                            <Link
                                style={{ display: "none" }}
                                id="udfFileDownloader"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                        </div>
                        </Tooltip>
                        )}  
                    </Grid>
                ) : (
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type={typeOfValueInputField}
                        value={udf.value ? udf.value : ""}
                        // disabled={showCompanyDetails}
                        placeholder="Value"
                        classes={{
                            root: classes.MuiFormControlroot,
                        
                        }}
                        disabled={udf.type === 'password'}
                        onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                        inputRef={focusTextInput}
                    />
                )}
                </Grid>
    
                <Grid item md={1} className={classes.customColumn1}>
                    <IconButton
                        onClick={() => onRemoveCustomFieldClick(udf, index)}
                        style={{
                        color: "#555",
                        padding: "0px",
                        // marginRight: "-16px",
                        marginTop: "3px",
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
            );
        })
        );
    };

    const onCreateClientModal = () => {
        const client_name = clientName;
        
        const clientEmail = emails;
        
        let mobile = phoneNumber ? phoneNumber : '';
        
        const address = compAddress;

        //company name validation
        if (!client_name) {
            enqueueSnackbar('Client name can not be empty!', {
                variant: 'error'
            });
            return;
        }

        const isClientNameValid = companyNameValidator(client_name);
        if (!isClientNameValid) {
            enqueueSnackbar('Enter valid client name', {
                variant: 'error'
            });
            return;
        }

        mobile = removeSpace(mobile);
        //user defined fields validations

        // if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
        //     enqueueSnackbar('Please Select the Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
        if(!selectedCountry) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        }
        const clientCountry =  selectedCountry?.code;

        // if(selectedCountry !== 'IN') {
        //     enqueueSnackbar('Please Select India as Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
    
        if(!selectedState) {
            enqueueSnackbar('Please Select the State', {
                variant: 'error'
            });
            return;
        }

        const clientState =  selectedState?.state_code;

        const tax_id = taxId;
        
        if (userDefinedFields.length > 0) {
            const errors = isValidUserDefinedFields(userDefinedFields);
            if (errors) {
                enqueueSnackbar(errors, {
                    variant:'error'
                });
                return;
            }
        } 

        //setLoading(true);
        const clientObj = { client_name, address, clientEmail, mobile, clientCountry, clientState, tax_id }

        callNextFileUploadOrCompanyApi(0, clientObj);
    }

    const callNextFileUploadOrCompanyApi = (index, clientObj) => {
        if (userDefinedFields && userDefinedFields.length > 0) {
            for (var i = index; i < userDefinedFields.length; i++) {
                if (userDefinedFields[i].type === udfTypes.ImageOrDocument.value && typeof(userDefinedFields[i].value) === 'object') {
                    if(props.editClientObj || props.editClient) {
                        uploadFileIfExists(i, clientObj);
                    }
                } else if (i === userDefinedFields.length - 1) {//call company api directly as this is last iteration
                    if(props.editClientObj || props.editClient) {
                        callEditCompanyApi(clientObj)
                    } else {
                        callAddCompanyApi(clientObj);
                    }
                }
            }
        } else {
            if(props.editClientObj || props.editClient) {
                callEditCompanyApi(clientObj)
            } else {
                callAddCompanyApi(clientObj);
            }
        }
    }

    const uploadFileIfExists = (i, clientObj) => {
        const udfObj = userDefinedFields[i];
        console.log('client obj*****', clientObj);
    
        //console.log('udf obj', udfObj.value, config);
        const client_id = props.editClientObj ? props.editClientObj?.id : props.editClient.id;
        const file_name = udfObj.value?.name;
        const file_type = udfObj.value?.type; 
        const type = 'custom';

        getClientUploadURLApi(client_id, file_name, type)
            .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientUploadURLApi', res);
            
            var signedRequest = res.upload_signed_url;
            var url = res.url;

            // Put the fileType in the headers for the upload
                const options = {
                headers: {
                    "Content-Type": file_type,
                }
                };
                axios.defaults.headers.common = {};
                axios.put(signedRequest, udfObj.value, options)
                .then(result => {
                    consoleToLog('signed Request s3', result);
                    console.log("Response from s3");

                    callUploadSuccessApi(i, userDefinedFields, udfObj, clientObj, url)
                })
                .catch(error => {
                    consoleToLog('error: getClientUploadURLApi', error);
                })
            })
            .catch((e) => {
                consoleToLog('Error getUploadURLApi', e);
            })
    }

    const callUploadSuccessApi = (i, userDefinedFields, udfObj, clientObj, url) => {
        const client_id = props.editClientObj ? props.editClientObj?.id : props.editClient.id;;
        
        uploadCustomFieldSuccessApi(client_id, url)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response uploadCustomFieldSuccessApi: ', res);
        
                //update state obj with new path
                const newUDFObj = {
                    ...udfObj,
                    value: url
                }

                let tempArr = userDefinedFields.filter((obj, index) => index !== i);
                tempArr.splice(i,0, newUDFObj)
                setUserDefinedFields(tempArr);
                callapi (i, clientObj, tempArr);
                consoleToLog('123456 arr', userDefinedFields, tempArr);
            })
            .catch((e) => {
                consoleToLog("Error uploadCustomFieldSuccessApi: ", e);
            })
    }

    const callapi = (i, companyObj, tempArr) => {
        let userDefinedArr = userDefinedFields.map(a => {
            const exists = tempArr.find(b => a.name == b.name);
            return exists ? (a.value = exists.value, a) : a;
        });
        setUserDefinedFields(userDefinedArr); 

        if (i === userDefinedFields.length - 1) {//call company api
            if(props.editClientObj || props.editClient) {
                callEditCompanyApi(companyObj)
            } else {
                callAddCompanyApi(companyObj);
            }
        } else {//recursive call if file exists
            callNextFileUploadOrCompanyApi(i + 1, companyObj);
        }
    }

    const callAddCompanyApi = ({ client_name, address, clientEmail, mobile, clientCountry, clientState, tax_id }) => {
            //no templates found so create company only
            const invoice_account_id = props.selectedAccount?.id;
            const user_defined_field = userDefinedFields;
            const client_email = clientEmail && clientEmail.length > 0 ? clientEmail.join() : '';
            //console.log('json string', JSON.stringify(userDefinedFields));

            //create client api call
            setLoading(true);
            addClientApi(invoice_account_id, capitalizeFirstLetterOfEachWord(client_name), address, clientState,
            clientCountry, mobile, client_email, tax_id, user_defined_field)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response addClientApi: ", res);
                    setLoading(false);
                    enqueueSnackbar('Client created!', {
                        variant:'success'
                    });

                    //update local state companies
                    props.addClient(res);
                    closeDrawer();
                    trackGAEvent(props.selectedAccount.name, 'Client added successfully', `${props.user.firstname} ${props.user.lastname}`);

                })
                .catch((e) => {
                    consoleToLog("addClientApi error", e);
                    setLoading(false);
                    show401ErrorAndClearRedux(props, e, enqueueSnackbar);
                });
    }

    const storeInClientReduxField = (clientObj) => {
        let currentClientObj = props.customerObj;
        currentClientObj = clientObj;
        props.setCustomerObject(currentClientObj);
    } 

    const storeInPreviewInvoiceReduxField = (clientObj) => {
        let currentInvoiceObj = props.invoiceObj;
        currentInvoiceObj.client = clientObj;
        props.setInvoiceItemObj(currentInvoiceObj);
        props.drawerState === SelectedDrawerState.SHOW_INVOICES && props.setUpdatedInvoiceObj(currentInvoiceObj);
    } 

    const storeInEditInvoiceReduxField = (clientObj) => {
        let currentInvoiceObj = props.editInvoiceObj;
        currentInvoiceObj.client = clientObj;
        props.setEditInvoiceObj(currentInvoiceObj);
        props.drawerState === SelectedDrawerState.SHOW_INVOICES && props.setUpdatedInvoiceObj(currentInvoiceObj);
    }

    const callEditCompanyApi = ({ client_name, address, clientEmail, mobile, clientCountry, clientState, tax_id }) => {
        //no templates found so create company only
        const invoice_account_id = props.selectedAccount?.id;
        const user_defined_field = userDefinedFields;
        const client_email = clientEmail && clientEmail.length > 0 ? clientEmail.join() : '';
        const client_id = props.editClientObj ? props.editClientObj?.id : props.editClient.id;

        editClientApi(client_id, client_name, address, clientState, 
            clientCountry, mobile, client_email, tax_id, user_defined_field)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response editClientApi: ', res);
                setLoading(false);
                enqueueSnackbar(res.message, {variant:'success'});
                closeDrawer();
                props.editClient && props.clientEdited(res.client);

                Object.keys(props.customerObj).length !== 0 && storeInClientReduxField(res.client)
                Object.keys(props.editInvoiceObj).length !== 0 && storeInEditInvoiceReduxField(res.client);
                Object.keys(props.invoiceObj).length !== 0 && storeInPreviewInvoiceReduxField(res.client);

                trackGAEvent(props.selectedAccount.name, 'Client updated successfully', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error EditClientApi', e);
                show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })
    }

    const onClientNameChange = (e) => {
        setClientName(e.target.value);
        props.editClientObj && props.setClientName(e.target.value);
    }

    const onClientMobileNoChange = (e) => {
        setPhoneNumber(e.target.value);
        props.editClientObj && props.setClientMobile(e.target.value);
    }

    const onClientAddressChange = (e) => {
        setCompAddress(e.target.value);
        props.editClientObj && props.setClientAddress(e.target.value);
    }
    
    const onClientCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        if(e.target.value !== 'IN') {
            setSelectedState('none');
        }
        props.editClientObj && props.setClientCountry(e.target.value);
    }

    const onClientStateChange = (e) => {
        setSelectedState(e.target.value);
        props.editClientObj && props.setClientState(e.target.value);
    }

    const onCountryNameChange = (e, newValue) => {
        setSelectedCountry(newValue);
        setSelectedState(undefined);
        props.editClientObj && props.setClientCountry(newValue);
    }

    const onStateNameChange = (e, newValue) => {
        setSelectedState(newValue);
        props.editClientObj && props.setClientState(newValue);
    }

    const validateEmail = (email) => {
        if(!isEmail(email)) {
            enqueueSnackbar('Please enter a valid email.', {variant:'error'});
            return false;
        } else {
            return true;
        }
    }

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openClientDrawer}
            onClose={closeDrawer}
            style={{position:'relative'}}
            classes={{
                //paper: props.fromCreateEditOrPreviewInvoice || props.fromOnboardingComponent ? classes.drawerPaper1  : classes.drawerPaper
                paper: classes.drawerPaper1    
            }}
        >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        {props.editClientObj || props.editClient ? 'Update Client' : 'Add Client'}
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={closeDrawer}>
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>


            <div className='modal__main'>
                <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1" 
                        className={classes.customMarginBottom}>
                        Client Name
                    </Typography>

                    <TextField fullWidth 
                        id="client_name" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={clientName}
                        autoComplete="off"
                        placeholder="Enter Client Name"
                        InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={onClientNameChange}
                    />
                </Grid>    

                {/* <Grid item md={12} lg={12}
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1" >
                        Email Addresses (Optional)
                    </Typography>

                    <ReactMultiEmail
                        className={emails && emails.length > 0 ? "org__email" : ""}
                        style={{
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#DBDBDB",
                            fontSize:"14px"
                        }}
                        placeholder="Type Email Address And Press Enter"
                        emails={emails}
                        onChange={(emails) => setEmails(emails)}
                        validateEmail={email => validateEmail(email)}
                        getLabel={(
                            email,
                            index,
                            removeEmail = (index) => {
                                const arr = emails.splice(index, 1);
                                setEmails(arr);
                            }
                        ) => {
                            return (
                                <div data-tag key={index} className="return__email">
                                    {email}
                                    {<span data-tag-handle onClick={() => removeEmail(index)}>
                                        x
                                    </span>}
                                </div>
                            );
                        }}
                    />
                </Grid>   */}

                {/* <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1" 
                        className={classes.customMarginBottom}>
                        Mobile Number (Optional)
                    </Typography>   

                    <TextField fullWidth 
                        id="mobile_number" 
                        variant="outlined"
                        type="tel"
                        margin="dense"
                        autoComplete="off"
                        value={phoneNumber}
                        placeholder="Mobile Number"
                        InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={onClientMobileNoChange}
                        onKeyPress={(e) => phoneNumberValidate(e)}
                    />
                </Grid>  */}

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1" 
                        className={classes.customMarginBottom}>
                        Tax Id (Optional)
                    </Typography>   

                    <TextField fullWidth 
                        id="tax_id" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        autoComplete="off"
                        placeholder="Tax Id"
                        value={taxId}
                        InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => setTaxId(e.target.value)}
                    />
                </Grid>

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1" 
                        className={classes.customMarginBottom}>
                        Address (Optional)
                    </Typography>   

                    <TextField fullWidth 
                        id="address" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        autoComplete="off"
                        placeholder="Address"
                        value={compAddress}
                        InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={onClientAddressChange}
                    />
                </Grid>

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1"  className={classes.customMarginBottom1}>
                        Country
                    </Typography>   

                    {/* <Select 
                        className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedCountry}
                        onChange={onClientCountryChange}
                        variant="outlined"
                        placeholder='Select Country'
                        autoWidth
                        //disabled={disabled}
                        >
                        {
                            selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                        } 
                        {showAllCountries()}
                    </Select> */}
                    <Autocomplete
                        id="combo-box-demo"
                        options={countries}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={selectedCountry}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            onCountryNameChange(e, newValue)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Country' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                    />
                </Grid>

                {stateList && stateList.length > 0 &&
                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1"  className={classes.customMarginBottom1}>
                        State
                    </Typography>   

                    <Autocomplete
                        id="combo-box-demo"
                        options={stateList}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={selectedState}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            onStateNameChange(e, newValue)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search State' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                    />
                </Grid>}

                {/* {selectedCountry === 'none' || selectedCountry === 'IN' &&
                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1">
                        State
                    </Typography>   

                    <Select 
                        className={selectedState === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedState}
                        onChange={onClientStateChange}
                        variant="outlined"
                        placeholder='Select State'
                        autoWidth
                        disabled={selectedCountry === 'none' || selectedCountry !== 'IN'}
                    >
                    {
                        selectedState === 'none' && <MenuItem value='none'>Select State</MenuItem>
                    } 
                    {showAllStates()}
                    </Select>
                </Grid>} */}

                <Grid item  md={12} lg={12} 
                    style={{marginBottom:"8px"}}
                    className={classes.customMarginTop}>
                        <Grid item container>
                            <Grid item>
                                <Typography variant="subtitle1" 
                                    className={classes.customMarginBottom}>
                                    Custom Fields
                                </Typography>   
                                </Grid>

                                <Grid item>
                                <Tooltip title="Add New Entry" arrow>
                                    <Button
                                        className={classes.udfButton}
                                        onClick={(e) => onCustomFieldAdded(e)}
                                    >
                                    <i className="flaticon-add-2" style={{ paddingTop: "6px" }}></i>
                                    </Button>
                                </Tooltip>
                                </Grid>       
                        </Grid>
                </Grid> 

                {showCustomField()}
            </div>
            <div className="modal__footer">
                <Button variant="contained" color="primary" 
                    onClick={onCreateClientModal}
                    disabled={props.accessLevelViewer}
                    className="modal__button">
                    {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                    {props.editClientObj || props.editClient ? 'Update' : 'Next'}   
                </Button>
                <Button variant="outlined" style={{
                    minWidth: '130px',
                }} onClick={closeDrawer}>
                    Cancel
                </Button>
            </div>
            
            <CustomFieldDrawer openFilterDrawer={openDrawer}
                handleCloseFilterDrawer={handleCloseCustomFieldDrawer}
                fromCustomFieldComponent={true}
                onItemClick={onItemClick}
                onUDFNameChanged={onUDFNameChanged}
                currentIndex={currentIndex}
                setSwitchFocus={setSwitchFocus}
            />   
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    customerObj: state.invoiceAccounts.customerObj,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditClientDrawer);