import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedStatusToInvoiceFilter, removeSelectedStatusFromInvoiceFilter} from '../../actions/invoiceFilters';
import {trackGAEvent} from '../../util/AppUtil';

const InvoiceStatusFilterComponent = (props) => {
    const statusNameList = ["Draft", "Sent", "Viewed", "Downloaded", "Partial", "Paid", "Cancelled"];
    const statusValueList = ["draft", "sent", "viewed", "downloaded", "partial", "paid", "cancelled"];
    const statusClassNames = ["draft__status", "sent__status", "viewed__status", "downloaded__status", "partial__status", "paid__status", "cancelled_status"];
    const statusClassNames1 = ["draft__background", "sent__background", "viewed__background", "downloaded__background", "partial__background", "paid__background", "cancelled__background"]
    
    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        return props.selectedStatusInvoiceFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
    }

    const onStatusClick = (selectedValue) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice filter - status filter changed');
        const selectedStatus = props.selectedStatusInvoiceFilter.filter((status) => status === selectedValue);
        if(selectedStatus && selectedStatus.length > 0) {
            props.removeSelectedStatusFromInvoiceFilter(selectedValue);
        } else {
            props.addSelectedStatusToInvoiceFilter(selectedValue);
        }
    }

    return (
        <div style={{marginTop:'18px'}}>
           <span style={{fontSize:'14px'}}>
                Status            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => {
                                        onStatusClick(statusValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedStatusInvoiceFilter: state.invoiceFilters.selectedStatusInvoiceFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedStatusToInvoiceFilter: (item) => dispatch(addSelectedStatusToInvoiceFilter(item)),
    removeSelectedStatusFromInvoiceFilter: (item) => dispatch(removeSelectedStatusFromInvoiceFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatusFilterComponent);