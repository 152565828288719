import moment from 'moment';



const defaultState = {
    issueStartDateFilter: undefined,
    issueEndDateFilter: undefined,

    selectedStatusInvoiceFilter: ["draft", "sent", "downloaded", "viewed", "partial", "paid", "cancelled"],
    invoiceClientsFilter: [],
    selectedTypeInvoiceFilter: ['proforma', 'tax', 'none'],
    selectedEntitiesFilter: []

}

function getDefaultStartDate() {
    return (moment().month() < 3) ? 
        moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
        : 
        moment().month('April').startOf('month').format('YYYY-MM-DD'); 
}

function getDefaultEndDate() {
   return (moment().month() < 3) ? 
        moment().month('March').endOf('month').format('YYYY-MM-DD')
        : 
        moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
}


export default (state = defaultState, action) => {
    switch(action.type) {
        
        case 'LOGOUT_INVOICE_FILTERS':
            return defaultState;

        case 'SET_ISSUE_START_DATE_FILTER' :
            return {
                ...state,
                issueStartDateFilter : action.start_date
            }
        
        case 'SET_ISSUE_END_DATE_FILTER' :
            return {
                ...state,
                issueEndDateFilter : action.end_date
            }  
            
        //status filters
        case 'ADD_SELECTED_STATUS_TO_INVOICE_FILTER':
            return {
                ...state,
                selectedStatusInvoiceFilter: [
                    ...state.selectedStatusInvoiceFilter,
                    action.status
                ]
            }
        
        case 'REMOVE_SELECTED_STATUS_FROM_INVOICE_FILTER':
            return {
                ...state,
                selectedStatusInvoiceFilter: state.selectedStatusInvoiceFilter.filter((s) => s !== action.status)
            }   

        //company filter
        case 'ADD_SELECTED_CLIENT_INVOICE_FILTER':
            return {
                ...state,
                invoiceClientsFilter: [
                    ...state.invoiceClientsFilter,
                    action.client
                ]
            }

        case 'DELETE_SELECTED_CLIENT_INVOICE_FILTER':
            return {
                ...state,
                invoiceClientsFilter: state.invoiceClientsFilter.filter((client) => client.id !== action.client.id)
            }  
            
        //type filters
        case 'ADD_SELECTED_TYPE_TO_INVOICE_FILTER':
            return {
                ...state,
                selectedTypeInvoiceFilter: [
                    ...state.selectedTypeInvoiceFilter,
                    action.invoiceType
                ]
            }
        
        case 'REMOVE_SELECTED_TYPE_FROM_INVOICE_FILTER':
            return {
                ...state,
                selectedTypeInvoiceFilter: state.selectedTypeInvoiceFilter.filter((s) => s !== action.invoiceType)
            }

        case 'ADD_SELECTED_ENTITIES_TO_INVOICE_FILTER' :
            return {
                ...state,
                selectedEntitiesFilter: [
                    ...state.selectedEntitiesFilter,
                    action.entity
                ]
            }

        case 'REMOVE_SELECTED_ENTITIES_TO_INVOICE_FILTER' :
            return {
                ...state,
                selectedEntitiesFilter: state.selectedEntitiesFilter.filter((entity) => entity.id !== action.entity.id)
            }

        //clear filters
        case 'CLEAR_SELECTED_INVOICE_FILTERS' :
            return defaultState     
        
        default:
            return state;
    }
}