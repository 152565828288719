import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography, Button, InputAdornment, Tooltip, IconButton, CircularProgress} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';
import {setSelectedInvoiceAccount, setInvoiceAccountsList} from '../actions/invoiceAccount';
import { useEffect } from 'react';
import AddEditEntityDrawer from './AddEditEntityDrawer';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import {verifyInvoiceAccountEntityEmailApi} from '../services/authService';
import { consoleToLog, AccessLevel, shouldDisable, InvoiceEntityApi } from '../util/AppUtil';
import EnableInvoiceESignModal from './modals/EnableInvoiceESignModal';
import {getInvoiceAccountsEntityListApi} from '../services/authService';
import { parseString } from '../util/AppUtil';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import {Tabs, Tab} from '@material-ui/core';
import ShowConfirmDialog from './ShowConfirmDialog';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { archiveRestoreDeleteEntityApi } from '../services/authService';
import { trackGAEvent } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'hidden',
        overflowX:'hidden', 
    },
    container: {
        marginTop: "8px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginBottom:'32px'
    },
    imgContainer :{
        padding:'3px',
        width:'50px',
        height:'50px',
        borderRadius:'100%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box'
    },
    uploadImg: {
        position:'relative',
        left:'-30px',
        top:'90px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    customColumn : {
        flexBasis:'27%',
        maxWidth:'27%'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },  
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    customMarginButton: {
        marginBottom: '-4px'
    },
    entityListStyle: {
        padding:'8px 16px',
        background:'#f7f7f7',
        borderRadius:'5px',
        marginBottom:'8px',
        '& .entity_name' : {
            fontSize:'15px',
            fontWeight: 500
        },
        '& .edit_icon' : {
            visibility:'hidden',
            minWidth:'20px'
        },
        '&:hover .edit_icon' : {
            visibility:'visible',
            minWidth:'20px'
        },
        '& .entity_email' : {
            fontSize:'13px',
            color:'#888'
        },

    },
    tabContainerStyles: {
        fontSize:'14px',
        position:'relative',
        backgroundColor:'#f9f9f9',
        padding:'4px',
        borderRadius:'5px',
        marginBottom:'16px',
        minHeight:'25px !important',
            '& .Mui-selected': {
                color:"#fff !important",
                backgroundColor:"#1A73E8 !important",
                borderRadius:'5px',
            },
            '& .MuiTabs-indicator' : {
                backgroundColor:"inherit"
            },
            '& .MuiTab-root' : { 
                fontSize:'14px',
                minWidth:"110px",
                [theme.breakpoints.down(1400)] : {
                    minWidth: '100px'
                }
            }
                                
    },
    muiTab: {
        fontSize:'14px',
        minWidth:"130px",
        [theme.breakpoints.down(1400)] : {
            minWidth: '100px'
        }
    }
}))


const InvoiceAccountInfo = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const isMount = useIsMount();

    const [openEntityDrawer, setOpenEntityDrawer] = useState(false);
    const [entityObj, setEntityObj] = useState(undefined);
    const [openESignDialog, setOpenESignDialog] = useState(false);
    const [logoImageUrl, setLogoImageUrl] = useState(undefined);
    const [entityList, setEntityList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState('active');
    const [openComfirmDialog, setOpenConfirmDialog] = useState(false);
    const [entityDelete, setEntityDelete] = useState(false);
    const [entityId, setEntityId] = useState(undefined);

    const { access_level } = props.selectedAccount || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const invoice_account_id = props.selectedAccount?.id;
    const canCreateEntity = props.selectedAccount?.can_create_entity;
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;

    useEffect(() => {
        getEntityList(true);
    }, []);

    useEffect(() => {
        if(!isMount) {
            getEntityList(true);
            setTabValue('active');
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(props.openEntityDrawerFromParent) {
                handleEntityDrawerOpen();
            }
        }
    }, [props.openEntityDrawerFromParent]);

    useEffect(() => {
        if(!isMount) {
            if(entityObj) {
                handleEntityDrawerOpen();
            }
        }
    }, [entityObj]);

    useEffect(() => {
        if(!isMount) {
            getEntityList(true);
        }
    }, [tabValue]);

    const onVerifyInvoiceAccountEmail = (entity) => {

        verifyInvoiceAccountEntityEmailApi(invoice_account_id, entity.id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response verifyInvoiceAccountEntityEmailApi: ", res)
            enqueueSnackbar(res.message, {
                variant:'success'
            });
          //trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email verification link from settings`);
        })
        .catch((e) => {
            consoleToLog("verifyInvoiceAccountEntityEmailApi error", e);
    
            if (e && e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'success'
                });
                return false;
            }
        });
    }

    const endAdornmentIcon = (entity) => {
        return (
            <InputAdornment position="end" onClick={() => {
                if (!disabled && !entity.is_email_verified) onVerifyInvoiceAccountEmail(entity)
            }}>
                <Tooltip title={entity.is_email_verified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: entity.is_email_verified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {entity.is_email_verified ? 
                            <CheckSharpIcon fontSize="small" style={{fontSize:'18px'}}/> 
                            : 
                            <>
                                <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold', fontSize:'15px'}}/>
                                <Typography style={{color:"#0071FF", fontSize:'13px'}}>Verify </Typography>
                            </>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    }

    const handleEntityDrawerOpen = () => {
        setOpenEntityDrawer(true);
    }

    const handleEntityDrawerClose = () => {
        setOpenEntityDrawer(false);
        props.openEntityDrawerFromParent && props.handleEntityDrawerCloseFromParent();
        entityObj && setEntityObj(undefined);
    }

    const onEditEntityClick = (entityObj) => {
        setEntityObj(entityObj);
    }

    const handleEnableInvoiceESignModalOpen = () => {
        setOpenESignDialog(true);
    }

    const handleEnableInvoiceESignModalClose = () => {
        setOpenESignDialog(false);
    }

    const getEntityList = (refresh, resObj) => {
        refresh && setLoading(true);
        const apiPath = tabValue === 'active' ? InvoiceEntityApi.ACTIVE : InvoiceEntityApi.ARCHIVED;
        getInvoiceAccountsEntityListApi(invoice_account_id, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsEntityListApi: ', res);
                setLoading(false);

                setEntityList(res);
                if(tabValue === 'active') {
                    let account = {
                        ...props.selectedAccount,
                        can_create_entity : resObj ? resObj?.can_create_entity : props.selectedAccount?.can_create_entity,
                        entities: res
                    };
    
                    let accountList = props.invoiceAccountsList.map((ia)=>{
                        if(ia.id === account.id) return account;
                        else return ia;
                    });
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);
                }
            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsEntityListApi error", e.response);
                setLoading(false);
                setEntityList([]);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return false;
                }
            });
    }

    const onEntityUpdate = (resObj) => {
        getEntityList(false, resObj);
    }

    const onPurchaseNowClick = () => {
        props.setValue(6);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleConfirmDialogOpen = (e, entity_id, hard_delete) => {
        e.stopPropagation();
        setEntityId(entity_id);
        hard_delete && setEntityDelete(true);
        setOpenConfirmDialog(true);
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        entityDelete && setEntityDelete(false);
    }

    const onArchiveEntityClick = (entityObj) => {
        handleConfirmDialogOpen();
    }

    const showProperApiPath = () => {
        if(tabValue === 'active') {
            return 'delete_entity'
        }

        if(tabValue === 'archived' && !entityDelete) {
            return 'restore_entity'
        }

        if(tabValue === 'archived' && entityDelete) {
            return 'hard_delete_entity'
        }
    }

    const callArchiveRestoreDeleteCompanyApi = () => {

        const apiPath = showProperApiPath()

        archiveRestoreDeleteEntityApi(invoice_account_id, entityId, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response archiveRestoreDeleteEntityApi: ', res);

                enqueueSnackbar('Entity List Updated Successfully', {
                    variant:'success'
                });

                // if(tabValue === 'active' || (tabValue === 'archived' && !entityDelete)) {
                //     let account = {
                //         ...props.selectedAccount,
                //         entities: tabValue === 'active' ? props.selectedAccount.entities.filter((entityItem) => entityItem?.id === entityId) :
                //                     [...props.selectedAccount.entities, res]
                //     };  
    
                    
                // }
                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === res.id) return res;
                    else return ia;
                });
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(res);
                const updatedEntitiesArr = entityList.filter((entityObj) => entityObj?.id !== entityId);
                setEntityList(updatedEntitiesArr);
                setEntityId(undefined);
            })
            .catch((e) => {
                consoleToLog('Error archiveRestoreDeleteEntityApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return;
                }
            })
    }


    return (
        <Grid item md={12} className={classes.settingsContainer}>
                
                <Grid item md={8}>
                    {/* {!canCreateEntity &&
                    <Grid item md={12} className={'upgradeMessageStyle'}>
                        <Typography variant='body2'>
                            You are on free plan, Please upgrade plan to create more entity <span className='link_style1' onClick={onPurchaseNowClick}>Purchase now!</span>
                        </Typography>
                    </Grid>
                    } */}

                    {accessLevelOwner && 
                    <Grid item>
                        <Grid item container justifyContent='flex-end'>
                            <Grid item>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    classes={{
                                        root: classes.tabContainerStyles
                                    }}>
                                    <Tab label='Active'  value="active" style={{minHeight:0}}/>
                                    <Tab label='Archived'  value="archived" style={{minHeight:0}}/>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {loading
                    ?
                    <Grid item container justifyContent='center'>
                        <Grid item>
                            <CircularProgress size={25} style={{color:"#212121"}} />
                        </Grid>
                    </Grid>
                    :
                    <>
                    {
                        entityList && entityList.length > 0 ?
                        entityList.map((entityItem) => {
                            return <Grid item container key={entityItem.id}
                                        alignItems='center'
                                        className={classes.entityListStyle}>
                                        <Grid item md={2}>
                                            {entityItem?.image ?
                                            <img className={classes.imgContainer}
                                                src={entityItem?.image ? entityItem?.image : "/images/eprocessify-icon.png"}
                                                alt="invoiceAccountEntityPic"
                                            />
                                            :
                                            <img className={classes.imgContainer}
                                                src={"/images/eprocessify-icon.png"}
                                                alt="invoiceAccountEntityAltPic"
                                            />
                                            }
                                        </Grid>

                                        <Grid item md={8} style={{flexBasis:'70%', maxWidth:'70%'}}>
                                            <Typography className='entity_name'>
                                                {entityItem.name}
                                            </Typography>
                                            <div style={{display:'flex', alignItems:"center"}}>
                                                <Typography className='entity_email'>
                                                    {entityItem.email}
                                                </Typography>
                                                {endAdornmentIcon(entityItem)}
                                            </div>
                                            <Typography variant="subtitle1" style={{color:'#888'}}>
                                                Address : {parseString(entityItem?.address !== null ? entityItem.address : "")}
                                            </Typography>
                                            {entityItem.tax_id &&
                                            <Typography variant="subtitle1" style={{color:'#888'}}>
                                                Tax Id : {entityItem.tax_id}
                                            </Typography>}
                                        </Grid>

                                        {tabValue === 'active'
                                        ?
                                        accessLevelOwner && 
                                        <Grid item md={2} style={{flexBasis:'13%', maxWidth:'13%'}}>
                                            <Tooltip title="Update Entity" arrow>
                                                <Button className='edit_icon'
                                                    onClick={() => onEditEntityClick(entityItem)}>
                                                    <EditOutlinedIcon fontSize='small' style={{fontSize:'21px', color:"grey"}}/>
                                                </Button>
                                            </Tooltip>

                                            <Tooltip title="Archive Entity" arrow>
                                                <Button className='edit_icon'
                                                    onClick={(e) => {
                                                        trackGAEvent(props.selectedAccount?.name, `Archive Entity clicked`, `${props.user.firstname} ${props.user.lastname}`);
                                                        handleConfirmDialogOpen(e, entityItem?.id, false)
                                                    }}>
                                                    <ArchiveOutlinedIcon fontSize='small' style={{fontSize:'21px', color:"grey"}}/>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        <Grid item md={2} style={{flexBasis:'13%', maxWidth:'13%'}}>
                                            {canCreateEntity && accessLevelOwner && 
                                            <Tooltip title="Restore Entity" arrow>
                                                <Button className='edit_icon'
                                                    onClick={(e) => {
                                                        trackGAEvent(props.selectedAccount?.name, `Restore Entity clicked`, `${props.user.firstname} ${props.user.lastname}`);
                                                        handleConfirmDialogOpen(e, entityItem?.id, false)
                                                    }}>
                                                    <RestoreOutlinedIcon fontSize='small' style={{fontSize:'21px', color:"grey"}}/>
                                                </Button>
                                            </Tooltip>}

                                            {accessLevelOwner && 
                                            <Tooltip title="Delete Entity" arrow>
                                                <Button className='edit_icon'
                                                    onClick={(e) => {
                                                        trackGAEvent(props.selectedAccount?.name, `Delete Entity clicked`, `${props.user.firstname} ${props.user.lastname}`);
                                                        handleConfirmDialogOpen(e, entityItem?.id, true)
                                                    }}>
                                                    <DeleteOutlineOutlinedIcon fontSize='small' style={{fontSize:'21px', color:"grey"}}/>
                                                </Button>
                                            </Tooltip>}
                                        </Grid>
                                        }
                                    </Grid>
                        })
                        :
                        <Grid item container justifyContent='center'>
                            <Grid item md={12} style={{background:'#f7f7f7', padding:'16px', borderRadius:'5px', textAlign:'center'}}>
                                <Typography variant='body1'>
                                    No Entities Found
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    </>
                    }

                </Grid>

            {openEntityDrawer &&
            <AddEditEntityDrawer openEntityDrawer={openEntityDrawer}
                handleEntityDrawerClose={handleEntityDrawerClose}
                entityObj={entityObj}
                handleEnableInvoiceESignModalOpen={handleEnableInvoiceESignModalOpen}
                onEntityUpdate={onEntityUpdate}
                setEntityObj={setEntityObj}
            />}

            {openESignDialog && <EnableInvoiceESignModal openESignDialog={openESignDialog}
                handleEnableInvoiceESignModalClose={handleEnableInvoiceESignModalClose}
                entityObj={entityObj}
                handleEntityDrawerClose={handleEntityDrawerClose}
                invoiceAccountInfoComponent={true}
                onEntityUpdate={onEntityUpdate}
                />}

            {
                openComfirmDialog && <ShowConfirmDialog openComfirmDialog={openComfirmDialog}
                                        handleConfirmDialogClose={handleConfirmDialogClose}
                                        tabValue={tabValue}
                                        fromEntitiesComponent={true}
                                        entityDelete={entityDelete}
                                        selectedAccount={props.selectedAccount}
                                        callArchiveRestoreDeleteCompanyApi={callArchiveRestoreDeleteCompanyApi}
                                        />
            }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAccountInfo);
