import {getInvoiceTransactionApi} from '../services/authService';
import {consoleToLog} from './AppUtil';

export const getInvoiceTransactions = async(invoice_account_id, invoice_id, props) => {
    try {
        const response = await getInvoiceTransactionApi(invoice_account_id, invoice_id)
        const res = response.data;
        consoleToLog("Response getInvoiceTransactionApi", res);
        return res;
    } catch(e) {
        consoleToLog('Error getInvoiceTransactionApi', e.response)
    }
}