import React, {useState, useEffect} from 'react';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import DashboardPage from '../components/DashboardPage';
import {connect} from 'react-redux';
import PreviewAndDownloadInvoice from '../components/PreviewAndDownloadInvoice';
import InvoiceAccountVerification from '../components/InvoiceAccountVerification';
import RootPage from '../components/RootPage';
import NotFoundPage from '../components/NotFoundPage';
import VerifyEmailPage from '../components/VerifyEmailPage';
import HomePage from '../components/HomePage';
import OnboardingComponent from '../components/onboarding/OnboardingComponent';
import PaymentSuccessPages from '../components/PaymentSuccessPages';
import BaseComponent from '../components/Base/BaseComponent';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import DashboardComponent from '../components/DashboardComponent';
import InvoiceListComponent from '../components/InvoiceListComponent';
import { AccessLevel, AppRoutes, consoleToLog, InvoicePlans } from '../util/AppUtil';
import LeftMenuComponent from '../components/LeftMenuComponent';
import SnackbarComponent from '../components/SnackbarComponent';
import TransactionsComponent from '../components/TransactionsComponent';
import ClientsComponent from '../components/ClientsComponent';
import AccountMembers from '../components/AccountMembers';
import SettingsComponent from '../components/SettingsComponent';
import MyProfile from '../components/MyProfile';
import { PropsProvider } from '../components/context';
import { trackGAEvent, setHeightForComponent } from '../util/AppUtil';
import AddEditClientDrawer from '../components/AddEditClientDrawer';
import ClientContactModal from '../components/modals/ClientContactModal';
import AddEditBankDrawer from '../components/AddEditBankDrawer';
import ShowInvoicesDrawer from '../components/ShowInvoicesDrawer';
import ShowInfoDialog from '../components/ShowInfoDialog';
import AddEditPaymentDrawer from '../components/AddEditPaymentDrawer';
import AddNewPaymentDrawer from '../components/AddNewPaymentDrawer';
import CreateEditPreviewInvoiceModal from '../components/modals/CreateEditPreviewInvoiceModal';
import InvoiceModalComponent from '../components/modals/InvoiceModalComponent';
import OutsideOnboardingComponent from '../components/OutsideOnboardingComponent';
import { useIsMount } from '../components/useIsMount';
import { getInvoiceAccountsList, setUserObj } from '../actions/invoiceAccount';
import { getUserDetailsObjApi } from '../services/authService';
import { setLoading } from '../actions/loading';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import RevenueByClientReport from '../components/RevenueByClientReport';
import ClientSummaryDialog from '../components/modals/ClientSummaryDialog';
import RevenueByItemsReports from '../components/RevenueByItemsReports';
import { extractBasePath } from '../util/AppUtil';

export const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
    displayflex : {
        display: "flex", 
        height: "100%"
    },
    leftMenu: {
        transition: 'width 0.6s ease',
    },
    mainContent: {
        transition: 'width 0.6s ease',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    }
}))

const AppRouter = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const[showDrawer, setShowDrawer] = useState(true);

    const [openAddPaymentDrawer, setOpenAddPamentDrawer] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [paymentInvoiceObj, setPaymentInvoiceObj] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);

    const [openAddNewPaymentDrawer, setOpenAddNewPaymentDrawer] = useState(false);
    const [updateTranscItemFromParent, setUpdateTranscItemFromParent] = useState(undefined);
    const [transactionObj, setTransactionObj] = useState(undefined);
    const [addTransaction, setAddTransaction] = useState(undefined);

    const [openClientDrawer, setOpenClientDrawerDrawer] = useState(false);
    const [openClientContactDrawer, setOpenClientContactDrawer] = useState(false);
    const [newClientObj, setNewClientObj] = useState(undefined);
    const [clientObjFromParent, setClientObjFromParent] = useState(undefined);
    const [editClient, setEditClient] = useState(undefined);
    const [isAddClient, setIsAddClient] = useState(false);

    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [bankAccObj, setBankAccObj] = useState(undefined);
    const [entityObjForBankAcc, setEntityObjForBankAcc] = useState(false);
    const [updatedInvoiceObj, setUpdatedInvoiceObj] = useState(undefined);

    const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
    const [openInvoicesDrawer, setOpenInvoicesDrawer] = useState(false);
    const [dueInvoiceObj, setDueInvoiceObj] = useState(undefined);
    const [clientInvoiceObj, setClientInvoiceObj] = useState(undefined);

    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [newInvoiceClicked, setNewInvoiceClicked] = useState(false);

    const [transactionsComponentState, setTransactionsComponentState] = useState(undefined);
    const [invoicesComponentState, setInvoicesComponentState] = useState(undefined);
    const [scrollContainer, setScrollContainer] = useState(null);
    const [callgetInvoiceListApi, setCallGetInvoiceListApi] = useState(false);

    const [openClientSummaryModal, setOpenClientSummaryModal] = useState(false);
    const [clientStatementObj, setClientStatmentObj] = useState(undefined);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const entityList = props.selectedAccount.entities;
    const unVerifiedEntityEmails = entityList && entityList.length > 0 ? entityList.filter((entityItem) => !entityItem.is_email_verified) : [];
    const is_entity_email_unverified = unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0;
    const isInvoiceAccountPlanFree = props.selectedAccount?.plan?.toLowerCase() === InvoicePlans.FREE;
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;
    const canCreateInvoice = props.selectedAccount?.can_create_invoice;
    const pathname = history.location.pathname;
    const basePath = extractBasePath(false);

    useEffect(() => {
        if(Object.keys(props.selectedAccount).length === 0 && !props.isLoading && pathname !== '/' && basePath !== '/client') {
            props.setLoading(true);
            props.getInvoiceAccountsList(props.selectedAccount);
        }
        if(Object.keys(props.user).length === 0 && !props.isLoading && pathname !== '/' && basePath !== '/client') {
            props.setLoading(true);
            const fetchData = async() => {
                try {
                    let response = await getUserDetailsObjApi();
                    let userObj = {
                        ...response.data,
                        first_login: false,
                    }
                    props.setUserObj(userObj);
                    props.setLoading(false);
                } catch(e) {
                    props.setLoading(false);
                    consoleToLog('Error getUserDetailsObjApi', e)
                }
            }
            fetchData();
        }
    }, [pathname, props.selectedAccount, props.user]);

    const drawerAction = () => {
        //consoleToLog("*****drawerAction"); 
        setShowDrawer(!showDrawer);
    }

    const handleOpenAddPaymentDrawer = (e, invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice list item - Add Payment button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setInvoiceObj(invoice);
        setOpenAddPamentDrawer(true);
    };

    const handleCloseAddPaymentDrawer = () => {
        setOpenAddPamentDrawer(false);
        setInvoiceObj(undefined);
    };

    const updateInvoiceItemInInvoiceList = (obj) => {
        setPaymentInvoiceObj(obj);
    }

    const handleClickDialogOpen = () => {
        if(canCreateInvoice) {
            if(!entityLimitExceeded) {
                trackGAEvent(props.selectedAccount.name, 'Invoice list item - New Invoice button clicked', `${props.user.firstname} ${props.user.lastname}`);
                setOpenDialog(true);
            } else {
                handleInfoDialogOpen();
            }
        } else {
            handleInfoDialogOpen();
            setNewInvoiceClicked(true);
        }
    };
    
    const handleClickDialogClose = () => {
        setOpenDialog(false);
    };

    const handleOpenAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(true);
    };

    const handleCloseAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(false);
    };

    const updateTranscItemInTranscList = (obj) => {
        setUpdateTranscItemFromParent(obj);
    }

    const handleClientDrawerOpen = () => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenClientDrawerDrawer(true);
    }

    const handleClientDrawerClose=()=>{
        setOpenClientDrawerDrawer(false);
    }

    const handleOpenClientContactDrawer = () => {
        setOpenClientContactDrawer(true);
    }

    const handleCloseClientContactDrawer = () => { 
        setOpenClientContactDrawer(false);
    }

    const addClient = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
                setIsAddClient(true);
                setClientObjFromParent(clientObj);
        }
        setNewClientObj(clientObj);
        props.drawerState !== SelectedDrawerState.SHOW_CLIENTS && props.drawerState !== SelectedDrawerState.SHOW_ONBOARDING && handleOpenClientContactDrawer();
    }

    const clientEdited = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
            setClientObjFromParent(clientObj);
        }
    }

    const handleBankDrawerOpen = () => {
        setOpenBankDrawer(true);
    };

    const handleBankDrawerClose = () => {
        setOpenBankDrawer(false);
    };

    const handleInvoiceDialogOpen = () => {
        setOpenInvoiceDialog(true);
    }

    const handleInvoiceDialogClose = () => {
        setOpenInvoiceDialog(false);
    }

    const handleInvoicesDrawerOpen = (invoice, selectedEntity) => {
        const obj = {
            ...invoice,
            entity_id: selectedEntity === 'all' ? undefined : selectedEntity
        }
        setDueInvoiceObj(obj);
        setOpenInvoicesDrawer(true);
    }

    const handleInvoicesDrawerClose = () => {
        setOpenInvoicesDrawer(false);
        setDueInvoiceObj(undefined);
    }

    const updateInvoiceItemListInClients = (invoiceObj) => {
        setClientInvoiceObj(invoiceObj)
    }

    const handleInfoDialogOpen = () => {
        setOpenInfoDialog(true);
    }
    
    const handleInfoDialogClose = () => {
        setOpenInfoDialog(false);
    }

    const setInvoiceComponentState = (invoicesStateObj) => {
        setInvoicesComponentState(invoicesStateObj)
    }

    const setTransactionComponentState = (transactionStateObj) => {
        setTransactionsComponentState(transactionStateObj)
    }

    const handleClientSummaryDialogOpen = (clientInvoicesObj) => {
        history.push(`/report/client/${clientInvoicesObj?.client.id}`);
        setClientStatmentObj(clientInvoicesObj);
        setOpenClientSummaryModal(true);
    }

    const handleClientSummaryDialogClose = () => {
        history.push(AppRoutes.CLIENT_REPORTS);
        setOpenClientSummaryModal(false);
        setClientStatmentObj(undefined);
    }

    const contextValue = {
        showDrawer,
        fullName: `${props.user.firstname} ${props.user.lastname}`,
        is_entity_email_unverified,
        handleOpenAddPaymentDrawer,
        paymentInvoiceObj,
        setPaymentInvoiceObj,
        handleInvoiceDialogOpen,
        openInvoiceDialog,
        updatedInvoiceObj,
        setUpdatedInvoiceObj,
        accessLevelViewer,
        clientObjFromParent,
        setClientObjFromParent,
        handleOpenClientContactDrawer,
        setEditClient,
        handleClientDrawerOpen,
        isAddClient,
        setIsAddClient,
        handleInfoDialogOpen,
        handleInvoicesDrawerOpen,
        updateTranscItemFromParent,
        setUpdateTranscItemFromParent,
        setTransactionObj,
        setOpenAddPamentDrawer,
        setAddTransaction,
        addTransaction,
        setInvoiceComponentState,
        invoicesComponentState,
        openDialog,
        setTransactionComponentState,
        transactionsComponentState,
        callgetInvoiceListApi,
        setCallGetInvoiceListApi,
        handleClickDialogOpen,
        handleClientSummaryDialogOpen,
        setOpenClientSummaryModal

    };

    return (
        <Router history={history}>
                <Switch>
                <Route path='/' component={RootPage} exact={true}/>
                <Route path='/setup' component={OnboardingComponent} exact={true}/>
                <Route path='/client/invoice/:invoice_uuid/preview' component={PreviewAndDownloadInvoice} exact={true}/>
                <Route path='/account-email-verification-success' component={InvoiceAccountVerification} exact={true}/>
                <Route path='/account-email-verification-failure' component={InvoiceAccountVerification} exact={true}/>
                <Route path="/verify-email/:slug?" component={VerifyEmailPage} exact={true}/>
                <Route path="/payment/success" component={PaymentSuccessPages} exact={true}/>

            
                <div ref={(node) => setScrollContainer(node)}>
                    {
                        Object.keys(props.selectedAccount).length === 0 || props.isLoading 
                        ?
                        <Grid item container justifyContent='center'>
                            <CircularProgress size={25} style={{marginTop:'32px'}}/>
                        </Grid>
                        :
                        <BaseComponent drawerAction={drawerAction}
                            handleClickDialogOpen={handleClickDialogOpen}
                            handleOpenAddNewPaymentDrawer={handleOpenAddNewPaymentDrawer}
                            handleClientDrawerOpen={handleClientDrawerOpen}
                            setInvoiceComponentState={setInvoiceComponentState}
                            setTransactionComponentState={setTransactionComponentState}
                            invoicesComponentState={invoicesComponentState}
                            transactionsComponentState={transactionsComponentState}
                            handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                            setOpenClientSummaryModal={setOpenClientSummaryModal}
                            accessLevelViewer={accessLevelViewer}
                            >

                                
                                    <div className={classes.displayflex}>

                                        <Route path='/invoice/create' component={CreateEditPreviewInvoiceModal} />
                                        <Route path='/invoice/:slug/edit' component={CreateEditPreviewInvoiceModal} />
                                        <Route path='/invoice/:slug/preview' component={CreateEditPreviewInvoiceModal} />
                                        <Route path='/reports/client/:client_id' component={ClientSummaryDialog} />

                                        <Box width={showDrawer ? '19%' : '0%'} 
                                            className={classes.leftMenu}>
                                            <LeftMenuComponent 
                                                invoicesComponentState={invoicesComponentState}
                                                setInvoiceComponentState={setInvoiceComponentState}
                                                transactionsComponentState={transactionsComponentState}
                                                setTransactionComponentState={setTransactionComponentState}
                                                />
                                        </Box>
                                            
                                            <Box width={showDrawer ? '83%' : '100%'}
                                                height={setHeightForComponent(isInvoiceAccountPlanFree, entityLimitExceeded)}
                                                className={classes.mainContent}
                                            >
                                            
                                            {unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0 && !accessLevelViewer &&
                                                    <div>
                                                        <SnackbarComponent showDrawer={showDrawer}
                                                        unVerifiedEntityEmails={unVerifiedEntityEmails}/>
                                                    </div>
                                            }

                                                <PropsProvider value={contextValue}>
                                                    <Route path={AppRoutes.ONBOARDING} component={OutsideOnboardingComponent} />
                                                    <Route path={AppRoutes.DASHBOARD} component={DashboardComponent} />
                                                    <Route path={AppRoutes.INVOICES} component={InvoiceListComponent} />
                                                    <Route path={AppRoutes.TRANSACTIONS} component={TransactionsComponent} />
                                                    <Route path={AppRoutes.CLIENTS} component={ClientsComponent} />
                                                    <Route path={AppRoutes.MEMBERS} component={AccountMembers} />
                                                    {pathname !== AppRoutes.BILLING && <Route path={AppRoutes.SETTINGS} component={SettingsComponent} />}
                                                    <Route path={AppRoutes.PROFILE} component={MyProfile} />
                                                    <Route path={AppRoutes.CLIENT_REPORTS} component={RevenueByClientReport} />
                                                    <Route path={AppRoutes.ITEM_REPORTS} component={RevenueByItemsReports} />
                                                    {pathname === AppRoutes.BILLING && <Route path={AppRoutes.BILLING} component={SettingsComponent} />}
                                                    {/* <PrivateRoute path='/invoices/client/preview' component={DownloadInvoice}/> */}
                                                </PropsProvider>

                                        </Box>
                                    </div>
                                {
                                    openClientDrawer && 
                                    <AddEditClientDrawer openClientDrawer={openClientDrawer} 
                                            handleClientDrawerClose={handleClientDrawerClose}
                                            accessLevelViewer={accessLevelViewer}
                                            addClient={addClient}
                                            editClient={editClient}
                                            setEditClient={setEditClient}
                                            clientEdited={clientEdited}
                                        />
                                }

                                {   
                                    openClientContactDrawer &&
                                    <ClientContactModal openDrawer={openClientContactDrawer}
                                        setOpenClientContactDrawer={setOpenClientContactDrawer}
                                        newClientObj={newClientObj}
                                        setNewClientObj={setNewClientObj}
                                        handleCloseClientContactDrawer={handleCloseClientContactDrawer}
                                        clientEdited={clientEdited}
                                        //clientsList={clientsList}
                                        //setClientsList={setClientsList}
                                    
                                />}

                                { 
                                    openDialog && 
                                    <InvoiceModalComponent openDialog={openDialog}
                                        handleClickDialogClose={handleClickDialogClose}
                                        handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                                        />
                                }

                                {
                                    openInvoiceDialog && 
                                    <CreateEditPreviewInvoiceModal openInvoiceDialog={openInvoiceDialog}
                                        handleInvoiceDialogClose={handleInvoiceDialogClose}
                                        setUpdatedInvoiceObj={setUpdatedInvoiceObj}
                                        setAddTransaction={setAddTransaction}
                                        updateTranscItemInTranscList={updateTranscItemInTranscList}
                                        updateInvoiceItemListInClients={updateInvoiceItemListInClients}
                                        setCallGetInvoiceListApi={setCallGetInvoiceListApi}
                                    />
                                }

                                {
                                    openAddPaymentDrawer && 
                                    <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                                        handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                                        invoiceObj={invoiceObj}
                                        updateInvoiceItemInInvoiceList={updateInvoiceItemInInvoiceList}
                                        updateTranscItemInTranscList={updateTranscItemInTranscList}
                                        transactionObj={transactionObj}
                                        setTransactionObj={setTransactionObj}
                                        accessLevelViewer={accessLevelViewer}
                                        openAddNewPaymentDrawer={openAddNewPaymentDrawer}
                                        handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                                        setAddTransaction={setAddTransaction}
                                        handleBankDrawerOpen={handleBankDrawerOpen}
                                        handleBankDrawerClose={handleBankDrawerClose}
                                        bankAccObj={bankAccObj}
                                        setBankAccObj={setBankAccObj}
                                        setEntityObjForBankAcc={setEntityObjForBankAcc}
                                    />
                                } 

                                {
                                    openAddNewPaymentDrawer &&
                                    <AddNewPaymentDrawer openAddNewPaymentDrawer={openAddNewPaymentDrawer} 
                                        handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                                        handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                                    />
                                }

                                {
                                    openBankDrawer &&
                                    <AddEditBankDrawer openBankDrawer={openBankDrawer}
                                        handleBankDrawerClose={handleBankDrawerClose}
                                        bankAccountObj={{}}
                                        fromDashboardOrAddPayment={true}
                                        setBankAccObj={setBankAccObj}
                                        entityObjForBankAcc={entityObjForBankAcc}
                                        setEntityObjForBankAcc={setEntityObjForBankAcc}
                                    />
                                }

                                {
                                    openInvoicesDrawer && 
                                    <ShowInvoicesDrawer openInvoicesDrawer={openInvoicesDrawer}
                                        handleInvoicesDrawerClose={handleInvoicesDrawerClose}
                                        dueInvoiceObj={dueInvoiceObj}
                                        handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                                        handleInvoiceDialogOpen={handleInvoiceDialogOpen}/>            
                                }

                                {
                                    openInfoDialog && 
                                    <ShowInfoDialog openInfoDialog={openInfoDialog}
                                        handleInfoDialogClose={handleInfoDialogClose}
                                        newInvoiceClicked={newInvoiceClicked}
                                        setNewInvoiceClicked={setNewInvoiceClicked}/>
                                }

                                {
                                    openClientSummaryModal &&
                                    <ClientSummaryDialog openClientSummaryModal={openClientSummaryModal}
                                        handleClientSummaryDialogClose={handleClientSummaryDialogClose}
                                        clientStatementObj={clientStatementObj}
                                    />
                                }

                        </BaseComponent>
                    }
                </div>
                <Route path='*' component={NotFoundPage} exact={true}/>
            </Switch>
        </Router>
    )
};

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj, 
    isLoading: state.loading.isLoading
}); 

const mapDispatchToProps = (dispatch) => ({
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj)),
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),

    setLoading: (isLoading) => dispatch(setLoading(isLoading))
    
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);