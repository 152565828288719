import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Avatar, Tooltip, TextField, InputAdornment, ClickAwayListener} from '@material-ui/core';
import {connect} from 'react-redux';
import {getClientsListApi} from '../services/clientService';
import { useIsMount } from './useIsMount';
import { consoleToLog, show401ErrorAndClearRedux } from '../util/AppUtil';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import AddEditClientDrawer from './AddEditClientDrawer';
import {deleteClientApi} from '../services/clientService';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { trackGAEvent } from '../util/AppUtil';
import ClientContactModal from './modals/ClientContactModal';
import ClientDetails from './clients_info/ClientDetails';
import ContactComponent from './clients_info/ContactComponent';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { usePropsContext } from './context';
import useRestoreComponentState from './useRestoreComponentState';
import {AccessLevel} from '../util/AppUtil';


const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    headings: {
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        position:'relative',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            backgroundColor:'#f7fafc',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'90px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    clientName: {
        whiteSpace: "nowrap", 
        maxWidth: "320px", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
    },
    emailEllipsis : {
        whiteSpace: "nowrap", 
        maxWidth: "83% !important", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    addressHeading: {
        display:'flex', 
        justifyContent: 'center', 
        marginLeft:'-72px'
    },
    addressText : {
        display:'flex', 
        justifyContent: 'center'
    },
    customColumn1 : {
        flexWidth: '41%',
        maxWidth: '41%'
    },
    customColumn2 : {
        flexWidth: '18%',
        maxWidth: '18%'
    },
    customColumn3 : {
        flexWidth: '8%',
        maxWidth: '8%',
        [theme.breakpoints.down(1400)] :{ 
            flexWidth: '21%',
            maxWidth: '21%',
        }
    },
    customColumn4 : {
        flexWidth: '22%',
        maxWidth: '22%',
        [theme.breakpoints.down(1400)] :{ 
            flexWidth: '18%',
            maxWidth: '18%',
        }
    },
    customColumn5 : {
        flexWidth: '7%',
        maxWidth: '7%',
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row',
        flexGrow:1,
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    customColumn6: {
        maxWidth:'39%',
        flexBasis:'39%'
    },
    customColumn7: {
        maxWidth:'19%',
        flexBasis:'19%'
    },
    emailEllipsis: {
        maxWidth:'170px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1300)] : {
            maxWidth:'140px',
        }
    }
}))

const ClientListComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [clientsList, setClientsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [total, setTotal] = useState(0);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [searchClientText, setSearchClientText] = useState('');

    const { clientObjFromParent, handleOpenClientContactDrawer, setEditClient,  handleClientDrawerOpen, 
        isAddClient, setIsAddClient, invoicesComponentState, transactionsComponentState } = usePropsContext();

    const { restoreState } = useRestoreComponentState(
        invoicesComponentState || transactionsComponentState, 
    );
    
    const restoredState = restoreState();

    useEffect(() => {
        if(Object.keys(restoredState).length > 0) {
            setClientsList(restoredState?.clientListState?.clientListState);
            setPage(restoredState?.clientListState?.page);
            setNextPage(restoredState?.clientListState?.nextPage);
            setTotal(restoredState?.clientListState?.total);
        }
    }, [restoredState])

    useEffect(() => {
        getClientList(searchClientText);
    }, [id]);

    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setPage(0);
            }
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                    getClientList(searchClientText);
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
            getClientList(searchClientText);
        }
    }, [searchClientText]);

    useEffect(() => {
        if(!isMount) {
            if(clientObjFromParent && isAddClient) {
                addClient();
            }

            if(clientObjFromParent && !isAddClient) {
                clientEdited();
            }
        }
    }, [clientObjFromParent]);

    useEffect(() => {
        if(!isMount) {
            if(clientsList && clientsList.length > 0 && !props.fromOnboardingComponent) {
                let obj = {
                    clientsList,
                    page,
                    total,
                    nextPage
                }
                props.setClientComponentObj(obj);
            }
        }
    }, [clientsList]);
    
    const {access_level} = props.selectedAccount;
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const pageReset = (query) => {
        if(page === 0) {
            getClientList(query);
        }
    }

    const onSearch = (e) => {
        const query = e.target.value;
        if (query === '') {
            setPage(0);
            setSearchClientText('');
        } else {
            //dont allow spaces before string, allow in between
            const regex = /^[^-\s][a-zA-Z0-9_\s-]+$/;
            if (query && query.length > 0) {
                setPage(0);
                setSearchClientText(query);
            }
        }
    }


    const getClientList = (text) => {
        const invoice_account_id = props.selectedAccount?.id

        
        if(!isMoreLoading) setLoading(true);
        getClientsListApi(invoice_account_id, page, text)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = clientsList.concat(res.companies);
                    setClientsList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if(res.next_page && id === props.selectedAccount.id) setPage(page + 1);
                    setClientsList(res.companies);
                    setTotal(res.total);
                }
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog('Error getClientsListApi: ', e.response);
                show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })

    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const addClient = () => {
        console.log('client', clientObjFromParent);
        setClientsList(oldClients => [clientObjFromParent, ...oldClients]);
        props.fromOnboardingComponent && props.setOnboardingClientList(oldClients => [clientObjFromParent, ...oldClients]);
        handleOpenClientContactDrawer();
        setIsAddClient(false);
    }

    const onEditClientClick = (e, clientObj) => {
        e.stopPropagation();
        setEditClient(clientObj);
        handleClientDrawerOpen();
    }

    const clientEdited = () => {
        const clientListArr = clientsList.map((client) => {
            if(client.id === clientObjFromParent.id) {
                client = clientObjFromParent;
            }
            return client
        })
        //console.log("client", props.clientObjFromParent)
        setClientsList(clientListArr);
        setIsAddClient(false);
    }

    const onDeleteClientClick = (e, client) => {
        e.stopPropagation();
        if(window.confirm('Are you sure you want to archive this client?')) {
            const client_id = client.id;

            deleteClientApi(client_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteClientApi', res);
                    enqueueSnackbar(res.message, {variant: 'success'});

                    trackGAEvent(props.selectedAccount.name, 'Client Deleted', `${props.user.firstname} ${props.user.lastname}`);


                    let updatedClientsArr = clientsList.filter((client) => client.id !== client_id);
                    setClientsList(updatedClientsArr);
                    props.fromOnboardingComponent && props.setOnboardingClientList(updatedClientsArr);

                })
                .catch((e) => {
                    consoleToLog('Error deleteClientApi: ', e.response);
                    show401ErrorAndClearRedux(props, e, enqueueSnackbar);

                })
        }
    }

    const onNewClientClick = () => {
        handleClientDrawerOpen();
    }

    return (
        <div style={{width:'100%'}}>
            {props.fromOnboardingComponent &&
                <Grid item container justifyContent='space-between' 
                    style={{padding:"16px"}}
                    alignItems='center'>
                    <Grid item>
                        <Typography className='outboardingLabel'>
                            Clients
                        </Typography>
                    </Grid>

                <Grid item>
                    <Button
                        onClick={onNewClientClick}
                        className={'addButton'}
                    >
                    Add Client
                    <AddCircleOutlineIcon fontSize='small' 
                        style={{fontSize:'15px', marginLeft:'4px'}}
                        className={'buttonPlusIcon'}/>
                    </Button>
                </Grid>
            </Grid>}
            {!props.fromOnboardingComponent &&
            <Grid item sm={4} style={{margin:'16px 0px 0px auto', paddingRight:"16px"}}>
                <TextField 
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Search Clients'
                    InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            {/* <SearchIcon className={classes.searchicon}/> */}
                            <i className="flaticon-search"></i>
                        </InputAdornment>
                        )
                    }}
                    onChange={onSearch}
                />
            </Grid>}
            <Grid item xs={12} style={{marginTop:!props.fromOnboardingComponent && '24px'}}>
                    
                    <Paper variant='standard' elevation={0} style={{marginTop:!props.fromOnboardingComponent && '24px', width:'100%'}}>
                    <TableContainer style={{overflow:'visible', height:'100%'}}>
                        <Table>
                        <TableHead style={{position:'sticky', top:'0px', zIndex:99, background:'#f7f7f7'}}>
                            <TableRow>
                                <TableCell className='tableheading_style'>Name</TableCell>
                                <TableCell className='tableheading_style'>Contacts</TableCell>
                                <TableCell className='tableheading_style'>Address</TableCell>
                                <TableCell className='tableheading_style'></TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ?
                        <TableBody>
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={clientsList && clientsList.length > 1 ? 4 : 3} align='center'>
                                    <CircularProgress size={25} style={{margin:'24px 0px'}}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {
                            clientsList && clientsList.length > 0 ? clientsList.map((client) => {
                                const compArr = client.name.split(" ");
                                let compStr1 = compArr[0];
                                let compStr2 = compArr[1] ? compArr[1] : '';
                                const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();
                                const clientEmails = client.email && client.email.length > 0 ? client.email.split(',') : [];
                                const contactList = client?.contacts;
                                return (
                                    <TableRow hover  key={client.id} className={classes.itemListContainer}
                                        onClick={() => props.setShowClientInfo(client)}>
                                        <TableCell component="th" scope="row">
                                            <Grid item container alignItems='center'>
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                        {finalStr}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item style={{marginLeft: "12px"}}>
                                                    <Tooltip title={client.name} arrow>
                                                        <Typography variant='body2' className={classes.clientName}>
                                                            {client.name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={'tablebody_style1'}>
                                            {
                                                contactList && contactList.length > 0 &&
                                                contactList.map((contact) => {
                                                    const clientFullName = contact.first_name + ' ' + contact.last_name;
                                                    return  <Grid item container alignItems='center' key={contact.id}>
                                                                {contact.first_name &&
                                                                <Grid item>
                                                                    <Typography variant='subtitle1'>
                                                                        {clientFullName}
                                                                    </Typography>
                                                                </Grid>}

                                                                {contact.first_name && 
                                                                <Grid item>
                                                                    <Typography variant='body2' style={{margin:'0px 6px'}}>
                                                                        -
                                                                    </Typography>
                                                                </Grid>}

                                                                <Grid item>
                                                                    <Tooltip title={contact.email} arrow>
                                                                        <Typography variant='subtitle1' 
                                                                            className={classes.emailEllipsis}>
                                                                            {contact.email}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </Grid>

                                                                {contact.phone_number && 
                                                                <Grid item>
                                                                    <Typography variant='body2' style={{margin:'0px 6px'}}>
                                                                        -
                                                                    </Typography>
                                                                </Grid>}

                                                                <Grid item>
                                                                    <Typography variant='subtitle1'>
                                                                        {contact.phone_number}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                })
                                            }
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            <Typography variant='subtitle1' style={{wordWrap: 'break-word', width:"180px", color:'#6b7280'}}>
                                                {client.address}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className='tablebody_style'>
                                            {!accessLevelViewer && 
                                            <div className={classes.actionIconContainer}>
                                                <Button 
                                                    onClick={(e) => onEditClientClick(e, client)}
                                                    className='more_actionButton1 action__icon'>
                                                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                    Edit
                                                </Button>
                                                <Button 
                                                    onClick={(e) => onDeleteClientClick(e, client)}
                                                    className='more_actionButton1 action__icon'>
                                                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                    Delete
                                                </Button>
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            :
                            <TableRow hover className={classes.invoiceListHover}>
                                <TableCell colSpan={4} align='center'>
                                    No Clients Found
                                </TableCell>
                            </TableRow>
                            
                            }
                        </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {!loading && loadMoreLogic()}
                </Paper>
                </Grid> 

                {/* <AddEditClientDrawer openDrawer={openDrawer} 
                    closeDrawer={closeDrawer}
                    setClientName={setClientName}
                    addClient={addClient}
                    editClient={editClient}
                    setEditClient={setEditClient}
                    clientEdited={clientEdited}
                    fromClientComponent={true}
                    accessLevelViewer={props.accessLevelViewer}

                />

                <ClientContactModal openDrawer={openClientContactDrawer}
                    setOpenClientContactDrawer={setOpenClientContactDrawer}
                    newClientObj={newClientObj}
                    setNewClientObj={setNewClientObj}
                    showClientInfo={props.showClientInfo}
                    setShowClientInfo={props.setShowClientInfo}
                    handleCloseClientContactDrawer={handleCloseClientContactDrawer}
                    clientsList={clientsList}
                    setClientsList={setClientsList}
                    
                />     */}
            </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    issueStartDateFilter: state.invoiceFilters.issueStartDateFilter,
    issueEndDateFilter: state.invoiceFilters.issueEndDateFilter,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientListComponent);