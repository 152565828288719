import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Grid, Container, Typography, Button, Avatar, Tooltip, CircularProgress, Link, ClickAwayListener} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import md5 from 'md5';
import { clientPreviewApi, downloadInvoiceApi } from '../services/authService';
import { consoleToLog, showDate, displayInitials, showStatusImages, displayInvoiceType, DiscountTypes, isInvoiceAccountCountryIndia} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import OtherInvoicesModal from './modals/OtherInvoicesModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import {history} from '../routers/AppRouter';
import {useParams} from 'react-router-dom';
import { useWindowSize } from './useWindowSize';
import {getImageDownloadUrlApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import { formatAmount } from '../util/currencyUtil';
import { QRCode } from 'react-qr-code';
import PaymentReceipt from './PaymentReceipt';
import {getInvoiceTransactions} from '../util/invoiceTransactionUtil';
import {displayInvoiceTypeLabel} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        flexGrow: 1,
        width:'100vw',
        height:'100vh',
        overflow:'auto !important',
        boxSizing:'border-box',
        background: theme.palette.primary.light,
        
    }, 
    container: {
        margin:'0px auto 32px auto',
        //padding:'8px 8px 8px 16px'
    },
    createFormContainer : {
        background:'white',
        padding:'0px 0px 32px 0px',
        borderRadius:'5px',
        '& .customColumn1' : {
        flexBasis:'58%',
        maxWidth:'58%',
        [theme.breakpoints.down(992)]: {
            flexBasis:'100%', 
            maxWidth:'100%'
        } 
        }

    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 16px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    firstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'18px 14px 16px 14px'
    },
    backIconContainer : {
        [theme.breakpoints.down(1450)] : {
            flexBasis:'50%',
            maxWidth:'50%',
        }
    },
    backIcon: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
    },
    secondRow: {
        padding:'24px'
    },
    avatarStyle: {
        width:"80px",
        height:"80px",
        fontSize:'35px',
        fontWeight:'600',
        [theme.breakpoints.down(500)] : {
            width:"60px",
            height:"60px",
            fontSize:'32px',
        },
        [theme.breakpoints.down(365)] : {
            margin:'8px 0px'
        }
    },
    thirdRow: {
        padding:'16px 24px 16px 24px' 
    },
    customColumn: {
        marginLeft:'16px',
        flexBasis:'40%',
        maxWidth:'40%',
    },
    secondColumnContainer: {
        marginLeft:'16px',
        flexBasis:'20%', 
        maxWidth:'20%',
        [theme.breakpoints.down(992)]: {
            marginLeft:'0px',
            marginBottom:'24px',
            flexBasis:'100%', 
            maxWidth:'100%'
        } 
    },
    plusIcon: {
        fontSize: '18px',
        marginRight:'8px'
    },
    MuiTextFieldroot : {
        background:'#fff',
        fontSize:'12px !important'
    },
    highlightOffIcon: {
        fontSize:'16px',
        marginRight:'4px'
    },
    customMarginBottom : {
        marginBottom: '24px'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    customColumn2: {
        marginLeft:'16px',
        flexBasis:'23%',
        maxWidth:'23%'
    },
    invoiceListContainer : {
        background:'white', 
        borderRadius:'4px', 
        marginBottom:'6px',
        color:'#2B2B2B',
        display:'flex', 
        alignItems:'center', 
        justifyContent:'space-between',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'0px 2px 0px 2px',
            border:'1px solid white',
            borderRadius:'4px'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
    },
    itemStyle: {
        borderBottom:'1px solid rgba(0, 0, 0, 0.1)', 
        paddingTop:'16px',
        paddingBottom:'8px'
    },
    paymentClientStyles: {
        marginLeft:'8px',
        width:'100% !important',
        [theme.breakpoints.down('md')]: {
            marginLeft:'0px'
        }
    },
    marginDropdown: {
        top:'25px !important',
        right: '4px',
    },
    modalMarginDropdown: {
        top:'25px !important',
        right: '35px',
        cursor:'pointer'
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px'
    },
    clientOwnerName: {
        color: '#4a5568',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '26px',
        [theme.breakpoints.down(600)] : {
            fontSize: '15px',
            maxWidth:'190px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(500)] : {
            fontSize: '15px',
            maxWidth:'170px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(420)] : {
            fontSize: '15px',
            maxWidth:'150px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(390)] : {
            fontSize: '15px',
            maxWidth:'140px',
            wordWrap:'break-word !important'
        }
    },
    clientOwnerAddress: {
        fontSize: "13px !important",
        color: '#4a5568 !important',
        lineHeight: "26px !important",
        fontWeight: '400 !important',
        [theme.breakpoints.down(600)] : {
            maxWidth:'190px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(600)] : {
            maxWidth:'170px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(420)] : {
            maxWidth:'150px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(390)] : {
            maxWidth:'140px',
            wordWrap:'break-word !important'
        }
    },
    mobileMargin: {
        marginBottom:'8px'
    },
    paymentStubStyle: {
        flexBasis:'55%', 
        maxWidth:'55%',
        [theme.breakpoints.down(700)] : {
            flexBasis:'100%', 
            maxWidth:'100%',
        }
        
    },
    mobileListContainer: {
        marginBottom:'16px',
        '&:last-child': {
            marginBottom:'0px'
        }
    },
    previewInvoiceDescriptionStyle: {
        fontSize: '13px !important', 
        color: '#4a5568 !important',
        [theme.breakpoints.down(480)] : {
            width:'180px !important',
            wordWrap:'break-word !important',
        }
    },
    statusImage: {
        width:"180px", 
        height:"67.5px",
        [theme.breakpoints.down] : {
            width:"120px", 
            height:"58px",
        },
        [theme.breakpoints.down(365)] : {
            margin:'8px 0px'
        }
    },
    customColumn3: {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn4: {
        flexBasis:'16%',
        maxWidth:'16%'
    },
    customColumn5: {
        flexBasis:'22%',
        maxWidth:'22%'
    },
    customColumn6: {
        flexBasis:'38%',
        maxWidth:'38%'
    },
    customMarginLeft: {
        marginLeft:'auto'
    },
    typeStyle : {
        fontSize:'13px', 
        color:'#4a5568', 
        marginTop:'1px',
        marginLeft:'6px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
    },
    tdsDisplay: {
        width:'auto',
        maxWidth:'250px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            width:'180px', 
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    }
    
}));

const PreviewAndDownloadInvoice = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [otherInvoices, setOtherInvoices] = useState([]);
    const [showAllInvoicesModal, setShowAllInvoicesModal] = useState(false);
    const [menuId, setMenuId] = useState('');
    const [isActive, setIsActive] = useState(false); 
    const [fromInvoiceModal, setFromInvoiceModal] = useState(false);
    const [signUrl, setSignUrl] = useState(undefined);

    const [eSignUrl, setESignUrl] = useState(undefined);

    const {enqueueSnackbar} = useSnackbar();
    const {invoice_uuid} = useParams();
    const {width} = useWindowSize();
    const isMount = useIsMount();

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');
    const email = decodeURIComponent(emailParam).replace(/[\[\]']/g, '').replace(/\s/g, '');

    const client_name = invoiceObj?.client.name;
    let over_due = (moment(invoiceObj?.due_date).toDate().getTime() < Date.now())

    const invoiceType = invoiceObj?.invoice_type;
    const entityObj = invoiceObj?.entity;
    let locale = invoiceObj?.locale;
    let currency = invoiceObj?.currency;
    let currenciesObj = invoiceObj?.currencies;
    const invoiceAccountCountry = invoiceObj?.invoice_account.country;
    const invoiceTransactions = invoiceObj?.invoice_transactions;

    useEffect(() => {
        setLoading(true);
        setShowAllInvoicesModal(false);

        const uuid_string = invoice_uuid;
        consoleToLog('uuid: ', uuid_string);

        var preview_url = `${process.env.REACT_APP_BASE_URL}/client/invoice/${uuid_string}/preview?email=${email}`;
        if(preview_url.indexOf("https://") !== -1){
            preview_url = preview_url.replace('https://','http://');
        }
        const md5_hash = md5(preview_url);
        consoleToLog('preview md5 hash: ', md5_hash, email);
        //console.log('request urlv: ', preview_url);
        
        clientPreviewApi(md5_hash, uuid_string, email)
            .then(async(response) => {
                const res = response.data;
                consoleToLog('Response clientPreviewApi: ', res);
                let image = res.invoice?.entity?.image;
                //getDownloadSignedUrl(res.invoice);

                console.log('response', res);
                setInvoiceObj(res.invoice);
                setOtherInvoices(res.results);
                setESignUrl(res.signature);
                setSignUrl(image);
                setLoading(false);
                // const data = await getInvoiceTransactions(invoice_account_id, invoice_id, props);
                // setInvoiceTransactions(data);
            })
            .catch((e) => {
                consoleToLog('Error clientPreviewApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
            })
    }, [invoice_uuid]);

    const getDownloadSignedUrl = async(invoice) => {
        let invoice_account_id = invoice?.invoice_account?.id;
        let image = invoice?.entity?.image;

        try {
            const response  = await getImageDownloadUrlApi(invoice_account_id, image, 'image');
            const res = response.data;
            consoleToLog("getDownloadSignedUrl", res);

            setSignUrl(res.download_signed_url);
        } catch(e) {
            consoleToLog("getDownloadSignedUrl error", e.response);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        }
    }

    const onDownloadInvoiceClick = (invoice) => {
        const invoice_id = invoice ? invoice.id : invoiceObj?.id;
        var request_url = `${process.env.REACT_APP_BASE_URL}/client/invoice/${invoice_id}/download`;

        if(request_url.indexOf("https://") !== -1){
            request_url = request_url.replace('https://','http://');
        }

        const md5_hash = md5(request_url);
        consoleToLog('download md5_hash: ', md5_hash);
        if(isActive) closeOptionsMenu();

        downloadInvoiceApi(md5_hash, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Reponse downloadInvoiceApi: ', res);

                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById('invoice_download').href = downloadableURL;
                document.getElementById('invoice_download').click();

            })
            .catch((e) => {
                consoleToLog('Error downloadInvoiceApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
            })
    }

    const onViewAllInvoicesClick = () => {
        setShowAllInvoicesModal(true);
    }

    const closeOtherInvoicesModal = () => {
        setShowAllInvoicesModal(false);
    }

    const onPreviewInvoiceClick = (invoice) => {
        //console.log('invoice uuid', invoice.uuid);
        history.push(`/client/invoice/${invoice.uuid}/preview`);
        closeOptionsMenu();
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
        if(fromInvoiceModal) setFromInvoiceModal(false);
    }

    const showDropDown = (invoice) => {
        let marginStyle = fromInvoiceModal ? classes.modalMarginDropdown : classes.marginDropdown;
        return (
            <>
            {invoice.id === menuId &&
                <ClickAwayListener onClickAway={closeOptionsMenu}>
                <Grid item className={`menu1 ${isActive ? `active ${marginStyle}` : "inactive"}`}>
                    <ul>

                        <li onClick={(e) => {
                            e.stopPropagation();
                            onPreviewInvoiceClick(invoice)
                        }}>
                            <a>
                                <VisibilityOutlinedIcon fontSize='small'  
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Preview
                            </a>
                        </li>

                        <li onClick={(e) => {
                            e.stopPropagation();
                            onDownloadInvoiceClick(invoice)
                        }}>
                            <a>
                                <CloudDownloadOutlinedIcon fontSize='small'  
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Download
                                <Link
                                    style={{ display: "none" }}
                                    id="invoice_download"
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}
                                    download
                                ></Link>
                            </a>
                        </li>

                        {/* <li>
                            <a>
                                <FilterNoneOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Get shareable link
                            </a>
                        </li> */}
                    </ul>
                </Grid>
                </ClickAwayListener>
                }
            </>
        )
    }

    const ShowDesktopView = () => {
        return(
        <Grid item container alignItems='center'
            className={classes.thirdRow}>
            {invoiceObj?.invoice_items && invoiceObj?.invoice_items.length > 0 &&
            <Grid item sm={12} >
                <Grid item container
                    alignItems='center' 
                    className="table_container">
                    <Grid item md={4} sm={4}>
                        <Typography variant="subtitle1" 
                            className='bill_label'>
                            Item
                        </Typography>
                    </Grid>

                    <Grid item md={2} sm={2}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            Cost
                        </Typography>
                    </Grid>

                    <Grid item md={2} sm={2} className={classes.customColumn3}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            QTY
                        </Typography>
                    </Grid>

                    {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                    <Grid item sm={2} className={classes.customColumn4}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            HSN/SAC code
                        </Typography>
                    </Grid>
                    }

                    <Grid item md={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 4} 
                        className={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? classes.customColumn5 : classes.customColumn6}
                        style={{textAlign:'right'}}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            Amount
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>}

            {invoiceObj?.invoice_items && invoiceObj?.invoice_items.length > 0 &&
                invoiceObj?.invoice_items.map((invoice) => {
                return <Grid item sm={12} key={invoice.id}>
                            <Grid item container style={{padding:'8px'}}>
                                <Grid item md={4} sm={4}>
                                    <Typography className='preview_list_name_style'>
                                        {invoice.item_name}
                                    </Typography>

                                    <Typography className="preview_list_style">
                                        {invoice.item_description} 
                                    </Typography>
                                </Grid>

                                <Grid item md={2} sm={2}>
                                    <Typography className="preview_list_style">
                                        {/* {invoice.cost.toFixed(2)}₹ */}
                                        {formatAmount(invoice.cost, currenciesObj)}
                                    </Typography>
                                </Grid>

                                <Grid item md={2} sm={2} className={classes.customColumn3}>
                                    <Typography className="preview_list_style">
                                        {invoice.quantity.toFixed(2)}
                                    </Typography>
                                </Grid>

                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                <Grid item md={2} sm={2} className={classes.customColumn4}>
                                    <Typography className="preview_list_style">
                                        {invoice.hsn_code}
                                    </Typography>
                                </Grid>
                                }

                                <Grid item md={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 4} 
                                    className={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? classes.customColumn5 : classes.customColumn6}
                                    style={{textAlign:'right'}}>
                                    <Typography className="preview_list_style">
                                        {/* {invoice.price.toFixed(2)}₹ */}
                                        {formatAmount(invoice.price, currenciesObj)}
                                    </Typography>
                                    {
                                        invoice.tax.map((taxObj) => {
                                            return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500',  color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                        {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + `(${taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0})`}
                                                    </Typography>
                                        })
                                    }

                                    {
                                        invoice.discount.map((discount) => {
                                            return  <Grid item xs={12}>
                                                        {
                                                            discount.type === DiscountTypes.PERCENT 
                                                            ?
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {`${discount.value}% (${formatAmount(discount.amount, currenciesObj)})`}
                                                            </Typography>
                                                            :
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {` ${formatAmount(discount.amount, currenciesObj)}`}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        <div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>
                    </Grid>
                })
            }    
        </Grid>
        )
    };

    const ShowMobileView = () => (
        <Grid item container 
            direction="column"
            justifyContent='space-between'
            className={classes.thirdRow}>
            {invoiceObj?.invoice_items && invoiceObj?.invoice_items.length > 0 &&
                invoiceObj?.invoice_items.map((invoice) => {
                return <Grid item sm={12} key={invoice.id} className={classes.mobileListContainer}>
                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Item
                                        </Typography>
                                    </Grid>
                                    <Typography className='mobile_list_name_style'>
                                        {invoice.item_name}
                                    </Typography>

                                    <Typography  className="mobile_list_style">
                                        {invoice.item_description} 
                                    </Typography>
                                </Grid>

                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item sm={12} className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Cost
                                        </Typography>
                                    </Grid>
                                    <Typography className="mobile_list_style">
                                        {/* {invoice.cost.toFixed(2)}₹ */}
                                        {formatAmount(invoice.cost, currenciesObj)}
                                    </Typography>
                                </Grid>

                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item sm={12} className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            HSN/SAC code
                                        </Typography>
                                    </Grid>
                                    <Typography className="mobile_list_style">
                                        {invoice.hsn_code}
                                    </Typography>
                                </Grid>}

                                <Grid item container className="table_container"
                                    justifyContent='space-between'>
                                    <Grid item sm={6}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            QTY
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={6} style={{textAlign:'right'}}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Amount
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container justifyContent='space-between'>
                                    <Grid item sm={6} className={classes.mobileMargin}>
                                        <Typography className="mobile_list_style">
                                            {invoice.quantity.toFixed(2)}
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={6} style={{textAlign:'right'}}>
                                        <div style={{marginRight:'8px'}}>
                                        <Typography className="mobile_list_style">
                                            {/* {invoice.price.toFixed(2)}₹ */}
                                            {formatAmount(invoice.price, currenciesObj)}
                                        </Typography>
                                        {
                                            invoice.tax.map((taxObj) => {
                                                return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500',  color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                            {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + `(${taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0})`}
                                                        </Typography>
                                            })
                                        }

                                    {
                                        invoice.discount.map((discount) => {
                                            return  <Grid item xs={12}>
                                                        {
                                                            discount.type === DiscountTypes.PERCENT 
                                                            ?
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {`${discount.value}% (${formatAmount(discount.amount, currenciesObj)})`}
                                                            </Typography>
                                                            :
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {` ${formatAmount(discount.amount, currenciesObj)}`}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                        })
                                    }
                                        </div>
                                        
                                    </Grid>

                                </Grid>        
                        <div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>
                    </Grid>
                })
            }    
        </Grid>
    );
    

    return (
        <div className={classes.mainContent}>
            {loading ?
            <CircularProgress size={30} style={{position:'absolute', top:'50px', left:'50%', right:'50%'}}/>
            :
            <Container className={classes.container} maxWidth="xl">
                    {signUrl &&
                    <Grid item container justifyContent="center" style={{margin:'24px 0px 0px 0px'}}>
                        <img src={signUrl} width="80" />
                    </Grid>}
                <Grid item container justifyContent='center' style={{flexWrap: 'wrap-reverse', marginTop:'24px'}}>
                    <Grid item md={7} sm={12} xs={12}
                        className={classes.createFormContainer}>
                        <Grid item container alignItems='center'
                            justifyContent='space-between' 
                            className={classes.firstRow}>

                            <Grid item sm={5} xs={12} className={width > 700 && classes.backIconContainer}
                                style={{marginLeft:width < 700 ? '0px' : '16px'}}>
                                <Typography className='preview_invoice_text_style'>
                                    <b>Invoice Number:</b> {invoiceObj?.invoice_sequence_str}
                                </Typography>
                                {invoiceObj?.reference_number &&
                                <Typography className='preview_invoice_text_style'>
                                    <b>Reference Number:</b> {invoiceObj?.reference_number}
                                </Typography>}

                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <Grid item container direction={invoiceObj?.reference_number ? 'column' : 'row'} 
                                justifyContent={ width > 700 ? "flex-end" : 'flex-start'} 
                                alignItems={invoiceObj?.reference_number ? width > 700 ? 'flex-end' : 'flex-start' : 'center'}
                                style={{marginTop: width > 700 ? '0px' : '16px'}}>
                                    <Grid item style={{marginRight:'21px'}}>
                                        <Typography className='preview_invoice_text_style'>
                                            <b>Issued: </b>  
                                            {showDate(invoiceObj?.issue_date)}
                                        </Typography>
                                    </Grid>

                                    <Grid item style={{marginRight:'21px'}}>
                                        <Typography className='preview_invoice_text_style'>
                                            <b>Due: </b> 
                                            {showDate(invoiceObj?.due_date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> 

                        <Grid item container alignItems='center'
                            justifyContent='space-between' 
                            className={classes.secondRow}>

                                {client_name &&
                                <Grid item>
                                    <Avatar className={classes.avatarStyle} 
                                        style={{background:'white', color:'#202020', border:'1px solid #202020'}}>
                                        {displayInitials(client_name)}
                                    </Avatar>
                                </Grid>}

                                {over_due && invoiceObj?.status !== 'paid' ?
                                <Grid item>
                                    <img src="/images/eprocessify_pastdue.png"
                                        className={classes.statusImage} alt='status image'/>
                                </Grid>
                                :
                                (invoiceObj?.status === 'partial' || invoiceObj?.status === 'paid') &&
                                <Grid item>
                                    <img src={showStatusImages(invoiceObj?.status)} 
                                        className={classes.statusImage} alt='status image'/>
                                </Grid>}

                                <Grid item style={{textAlign:'right'}}>
                                    <Typography className='preview_invoice_style'>
                                        {displayInvoiceType(invoiceType)} Invoice
                                    </Typography>
                                    <Typography className={classes.previewInvoiceDescriptionStyle}>
                                        {invoiceObj?.invoice_description}
                                    </Typography>
                                </Grid>

                        </Grid> 

                        <div className='modalBorderBottom1'></div>

                        <Grid item container 
                            justifyContent='space-between' 
                            className={classes.thirdRow}>
                                <Grid item md={5} sm={6}> 
                                    <Typography className='bill_label'>
                                        Bill To:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {invoiceObj?.client.name}
                                    </Typography>
                                    { <Typography className={classes.clientOwnerAddress}>
                                        {<div>{invoiceObj?.client.address}</div>}
                                        {<div>{invoiceObj?.client.state && `${invoiceObj?.client.state},`} {invoiceObj?.client.country}</div>}
                                        {/* {invoiceObj?.client.tax_id &&
                                            <div>Tax Id: {invoiceObj?.client.tax_id}</div>
                                        } */}
                                        {
                                            invoiceObj?.client.contacts && invoiceObj?.client.contacts.length > 0 &&
                                            invoiceObj?.client.contacts.slice(0, 1).map((contact) => {
                                                return <div key={contact.id}>
                                                        {
                                                            contact.email && <div> Email - {contact.email} </div>
                                                        }
                                                        {
                                                            contact.phone_number && <div> Phone - {contact.phone_number} </div>
                                                        }
                                                        </div>
                                            })
                                        }
                                        {/* {
                                            invoiceObj?.client.phone_number &&
                                            <div>Tel: {invoiceObj?.client.phone_number}</div>
                                        }
                                        {
                                            invoiceObj?.client.email &&
                                            <div>Email: {invoiceObj?.client.email}</div>
                                        } */}
                                        {
                                            invoiceObj?.client.user_defined_field && invoiceObj?.client.user_defined_field.length > 0 && 
                                            invoiceObj?.client.user_defined_field.map(udf => {
                                                return (udf.value !== '' && udf.show_invoice) ?
                                                <div key={udf.value}>{udf.name}: {udf.value}</div>
                                                :
                                                <div></div>
                                            })
                                        }
                                    </Typography> }
                                </Grid>

                                <Grid item md={5} sm={6} style={{textAlign:'right'}}>
                                        <Typography className='bill_label'>
                                            Bill From
                                        </Typography>
                                        <Typography className={classes.clientOwnerName}> 
                                            {entityObj?.name}
                                        </Typography>
                                        <Typography className={classes.clientOwnerAddress}> 
                                            <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                            </div>
                                            <div>{entityObj?.country}</div>
                                            <div>Tel: {entityObj?.mobile}</div>
                                            <div>Email: {entityObj?.email}</div>
                                            {entityObj?.tax_id &&
                                                <div>Tax Id: {entityObj?.tax_id}</div>
                                            }
                                        </Typography>
                                </Grid>
                        </Grid> 

                        <div className='modalBorderBottom1'></div>

                        {width < 600 ? 
                        <ShowMobileView />
                            :
                        <ShowDesktopView />
                        } 

                        <Grid item sm={12} 
                            style={{margin:width < 700 ? '-8px 8px 0px 8px' : '-16px 0px'}}>
                            <Grid item container justifyContent="space-between" 
                                className={classes.thirdRow}  
                                style={{flexWrap:'wrap-reverse'}}>
                                    
                                <Grid item md={4} sm={width < 700 ? 12 : 5} xs={12} 
                                    style={{marginTop: width < 700 ? '32px' : '0px'}}
                                    className={classes.paymentClientStyles}>
                                {invoiceObj?.show_payment_terms &&
                                    <Grid item sm={12} xs={12}>
                                        <Typography className='font_styles'>
                                            PAYMENT TERMS
                                        </Typography>

                                        <Typography className='clientOwner_address'
                                            dangerouslySetInnerHTML={{__html: invoiceObj?.payment_terms}}>
                                        </Typography>
                                    </Grid>}
                                    
                                {invoiceObj?.show_client_notes &&
                                    <Grid item sm={12} xs={12} style={{marginTop:invoiceObj?.show_payment_terms &&'24px', width:'40ch'}}>
                                        <Typography className='font_styles'> 
                                            CLIENT NOTES
                                        </Typography>

                                        <Typography className='clientOwner_address' 
                                            dangerouslySetInnerHTML={{__html: invoiceObj?.client_notes}}>
                                        </Typography>
                                    </Grid>}

                                </Grid>

                                <Grid item md={7} sm={width < 700 ? 12 : 6} xs={12} 
                                    style={{marginRight:width > 700 ? '8px' : '0px'}}>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12} className='mobile_flex_display'> 
                                            <Typography className='list_item_styles'>
                                                Subtotal
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceObj?.subtotal.toFixed(2)}₹ */}
                                                {formatAmount(invoiceObj?.subtotal, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                    {
                                        invoiceObj?.discount.map((discount) => {
                                            return <Grid item sm={12} xs={12}
                                                        key={discount.value}
                                                        className="item_marginTop mobile_flex_display"> 
                                                        <Typography className='list_item_styles'>
                                                            Discount
                                                        </Typography>
                    
                                                        <Typography className='list_item_styles'>
                                                            {`${formatAmount(discount.amount, currenciesObj)}`}
                                                        </Typography>
                                                    </Grid>
                                        })
                                    }
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                    {
                                        invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item sm={12} xs={12}
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop mobile_flex_display"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0}
                                                    </Typography>
                                                </Grid>
                                        })
                                    }
                                    </Grid>

                                    {isInvoiceAccountCountryIndia(invoiceAccountCountry) && invoiceObj?.tcs && invoiceObj.tcs?.name &&
                                    <Grid item sm={7} style={{marginLeft:'auto'}}>
                                        <Grid item container justifyContent='space-between'
                                            className="item_marginTop">

                                            <Tooltip title={invoiceObj?.tcs?.name} arrow>
                                                <Typography 
                                                    className={`list_item_styles ${classes.tdsDisplay}`}>
                                                    TCS ({invoiceObj?.tcs?.name})
                                                </Typography>
                                            </Tooltip>

                                            <Typography className='list_item_styles'>
                                                {formatAmount(invoiceObj.tcs.value, currenciesObj)}
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>}

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>

                                        <div className={width < 700 ? 'bill_invoice' : 'bill_invoice1'}></div>

                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Total
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceObj?.total.toFixed(2)}₹ */}
                                                {formatAmount(invoiceObj?.total, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Paid to date
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceObj?.paid.toFixed(2)}₹ */}
                                                {formatAmount(invoiceObj?.paid, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Amount Due (INR)
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceObj?.due.toFixed(2)}₹ */}
                                                {formatAmount(invoiceObj?.due, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {entityObj?.enable_esign ? <div className="border_bottom_two"></div> : <div className="dottedborderBottom"></div>}

                        {entityObj?.enable_esign &&
                            <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                                <Grid item container direction='column' alignItems='flex-end'>
                                    
                                    {eSignUrl &&
                                        <Grid item style={{position:'relative', top:'3px'}}>
                                            <div className={'eSign_style'}>
                                                <img src={eSignUrl} width='40%' />
                                            </div>
                                            <Typography style={{fontWeight:400, marginTop:'4px'}}>
                                                Authorized Signatory
                                            </Typography>
                                        </Grid>
                                    }

                                </Grid>
                                <div className="dashed_border"></div>
                            </Grid>}

                        {invoiceObj?.show_payment_stub &&
                    <Grid item sm={12} className={classes.thirdRow}>
                        <Grid item container justifyContent="space-between">
                                <Grid item md={5} sm={width < 700 ? 12 : 5} xs={12}
                                    className={`payment_stubContainer ${classes.paymentStubStyle}`}>
                                    <Typography className='clientOwner_address'>
                                        Bill from:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {entityObj.name}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                            <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                            </div> 
                                            <div>{entityObj?.country}</div>
                                            <div>Tel: {entityObj?.mobile}</div>
                                            <div>Email: {entityObj?.email}</div>
                                            {entityObj?.tax_id &&
                                                <div>Tax Id: {entityObj?.tax_id}</div>
                                            }
                                    </Typography>   
                                </Grid>

                            <Grid item md={5} sm={width < 700 ? 12 : 5} xs={12} 
                                style={{margin:width < 700 ? '16px 8px 0px 16px' : '0px 8px 0px 0px'}}>
                                <Grid item container justifyContent='space-between'> 
                                    <Typography className='list_item_styles'>
                                        Client
                                    </Typography>

                                    <Typography className='list_item_styles' 
                                    style={{
                                        width: '180px',
                                        wordWrap:'break-word',
                                        textAlign:'right'
                                    }}>
                                    {invoiceObj?.client?.name}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                       Invoice
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                       #{invoiceObj?.invoice_number}
                                    </Typography>
                                </Grid>

                                {
                                   invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                <div className="bill_invoice"></div>


                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Due (INR)
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {invoiceObj?.due.toFixed(2)}₹ */}
                                        {formatAmount(invoiceObj?.due, currenciesObj)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Enclosed
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {invoiceObj?.paid.toFixed(2)}₹ */}
                                        {formatAmount(invoiceObj?.paid, currenciesObj)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    {invoiceObj?.show_upi_qr_code && invoiceObj?.entity?.upi_id && <div className='modalBorderBottom1'></div>}

                    {invoiceObj?.show_upi_qr_code && invoiceObj?.entity?.upi_id &&
                        <Grid item xs={12} style={{margin:'16px 0px'}}>
                            <Typography className='font_styles' style={{textAlign:'center'}}> 
                                UPI QR Code
                            </Typography>

                            <div style={{ height: "auto", 
                                        maxWidth: 120,
                                        width: "100%",
                                        margin:'8px auto 0px auto' }}>

                                    <QRCode 
                                        value={`upi://pay?pa=${invoiceObj?.entity?.upi_id}&am=${0}`}
                                        size="120"
                                        //logoImage="https://i.postimg.cc/5tdHfkxF/118852864-1241633666213183-4722008391193475906-n.png"
                                        logoWidth="80"
                                        logoHeight="100"
                                        logoOpacity="0.6"
                                    />
                            </div>

                            {
                                invoiceObj?.entity?.upi_id &&
                                <Typography variant='subtitle1' style={{textAlign:'center', marginTop:'8px', fontWeight:500}}>
                                    UPI ID: {invoiceObj?.entity?.upi_id}
                                </Typography>
                            }
                        </Grid>} 
                    </Grid>

                    <Grid item md={3} sm={12} xs={12} className={classes.secondColumnContainer}>
                        <Grid item container direction='column' alignItems='center'>

                            <Grid item sm={12} xs={12} className="download_invoice">    
                                <Button fullWidth className={classes.buttonStyles}
                                    onClick={() => onDownloadInvoiceClick('')}
                                    variant="contained" style={{background:'#4a5568', color:'#fff'}} >
                                    <PictureAsPdfIcon fontSize='small'
                                        style={{marginRight:'5px', fontSize:'16px'}} />
                                        Download
                                        <Link
                                            style={{ display: "none" }}
                                            id="invoice_download"
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                            download
                                        ></Link>    
                                </Button>
                            </Grid>

                            {otherInvoices && otherInvoices.length > 0 &&
                            <Grid item sm={12} xs={12} className="download_invoice" 
                                style={{marginTop:'16px'}}>    
                                <Typography variant="subtitle1"
                                    style={{textAlign:'center', marginBottom:'8px'}}>
                                    Other Invoices
                                </Typography> 

                                {
                                    otherInvoices && otherInvoices.length > 0 &&
                                    otherInvoices.slice(0, 5).map((invoice) => {
                                        return <Grid container justifyContent='space-between' alignItems='center'
                                                    key={invoice.id}
                                                    style={{position:'relative'}}
                                                    className={classes.itemStyle}>
                                                    <Typography className='list_item_styles' style={{cursor:'pointer'}}>
                                                        {<span className={classes.invoiceTypeStyle}>
                                                            {displayInvoiceTypeLabel(invoice.invoice_type)}
                                                        </span>} 
                                                        <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                                            {invoice.inv_number}
                                                        </span>
                                                    </Typography>
                
                                                    <div className={classes.invoiceListContainer}>
                                                        <Typography className='list_item_styles'>
                                                        {showDate(invoice.due_date)}
                                                        </Typography>
                
                                                        <Grid item md={1} lg={1} 
                                                        style={{position:'relative', 
                                                            marginLeft: width < 992 ? '0' : '-8px', 
                                                            marginRight:width < 992 ? '0' : '12px',
                                                            top:'4px'}}>
                                                            <Tooltip title="More Actions" arrow>  
                                                                <MoreHorizOutlinedIcon fontSize='small'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setIsActive(!isActive);
                                                                    setMenuId(invoice.id);
                                                                }}
                                                                className='action__icon'/>
                                                            </Tooltip>
                                                            {!fromInvoiceModal && showDropDown(invoice)}
                                                        </Grid> 
                                                    </div>
                
                                                </Grid>
                                                })
                                }   

                                <Button variant="outlined" 
                                    color="primary" 
                                    onClick={onViewAllInvoicesClick}
                                    style={{marginTop:'24px', width:'100%'}}>
                                    <i className='flaticon-file' 
                                        style={{marginRight:'8px', display:'flex', alignItems:'center'}}></i>
                                    View All Invoices
                                </Button>
                            </Grid>}

                            {invoiceTransactions && invoiceTransactions.length > 0 &&
                                <PaymentReceipt invoiceObj={invoiceObj}
                                    invoiceTransactions={invoiceTransactions}/>
                            }
                        </Grid>
                    </Grid>              

                </Grid>
            </Container>}

            {showAllInvoicesModal &&
            <OtherInvoicesModal showAllInvoicesModal={showAllInvoicesModal}
                otherInvoices={otherInvoices}
                invoiceObj={invoiceObj}
                closeOtherInvoicesModal={closeOtherInvoicesModal}
                showDropDown={showDropDown}
                setIsActive={setIsActive}
                setMenuId={setMenuId}
                isActive={isActive}
                setFromInvoiceModal={setFromInvoiceModal}
                fromInvoiceModal={fromInvoiceModal}
            />}
        </div>
    );
}

export default PreviewAndDownloadInvoice;