import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, Grid, Typography, Button, TextField} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { isInvoiceAccountOnFreePlan, InvoicePlans, onUpgradeNowClick } from '../util/AppUtil';
import { setGoToBilling } from '../actions/invoiceAccount';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    },
    dialogActionImg: {
        width:'60px',
        marginBottom:'8px',
        display:'flex',
        justifyContent:'center'
    },
    dailogHeading: {
        fontSize:'21px',
        fontWeight:'600',
        marginBottom:'8px',
        textAlign:'center'
    },
    yesButtonStyle: {
        borderRadius: '5px',
        background: '#318415',
        color:'#fff',
        width:'130px',
        '&:hover': {
            background:'#318415'
        }
    },
    noButtonStyle: {
        borderRadius: '5px',
        background: '#888',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'rgba(0,0,0,0.3)'
        },
    },
    verifyLink: {
        color: '#0000EE',
        cursor: 'pointer',
        fontSize:'14px',
        margin:"0px 1px 0px 2px",
        '&:hover': {
            textDecoration:'underline'
        }
    },
    deleteButtonStyle: {
        borderRadius: '5px',
        background: '#EE4B2B',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'#D22B2B'
        },
    },
}))



const ShowInfoDialog = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [deleteText, setDeleteText] = useState('');

    const invoiceAccountPlan = props.selectedAccount?.plan;
    const entityCount = props.selectedAccount?.entity_count; 
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;
    const entityList = props.selectedAccount?.entities;


    const displayMessage = () => {

        if(isInvoiceAccountOnFreePlan(invoiceAccountPlan) && props.newInvoiceClicked) {
            return 'You have reached monthly limit of 3 invoices. Upgrade now to start using all features.';
        }

        if(isInvoiceAccountOnFreePlan(invoiceAccountPlan)) {
            return 'You are on free plan which allow only one entity. Upgrade now to premium plan to add more entities and use all invoicing features without limit.';
        }

        if(!isInvoiceAccountOnFreePlan(invoiceAccountPlan) && !entityLimitExceeded) {
            return `You can only add ${entityCount} entities. You have already reached the limit. Upgrade now to add more entities.`;
        }

        if(!isInvoiceAccountOnFreePlan(invoiceAccountPlan) && entityLimitExceeded) {
            return <span>
                    Your account have limit of {entityCount} entities. But you have added {entityList?.length} entities. Archive entities to start generating invoices. If you want to add more entities, you can <span className={classes.verifyLink} onClick={onPurchaseEntityClick}>purchase from here.</span>
                    </span>
        }

    }

    const onYesClick = () => {

        if(props.fromEntitiesComponent && !props.entityDelete) {
            props.callArchiveRestoreDeleteCompanyApi();
            props.handleCloseConfirmModal();
        }

        if(props.fromEntitiesComponent && props.entityDelete) {
            if(!deleteText || deleteText !== 'delete') {
                enqueueSnackbar("Please type delete to confirm", {variant: 'error'});
                return;
            } else {
                props.callArchiveRestoreDeleteCompanyApi();
                props.handleCloseConfirmModal();
            }
        }

    }

    const onPurchaseEntityClick = () => {
        onUpgradeNowClick(props);
        props.handleInfoDialogClose();
        closeNewInvoiceClicked();
    }

    const onDeleteEntityClick = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
        props.handleInfoDialogClose();
        closeNewInvoiceClicked();
    }

    const ShowActionButton = () => {
            return   <Button 
                        onClick={onPurchaseEntityClick}
                        className={classes.yesButtonStyle}>
                        {isInvoiceAccountOnFreePlan(invoiceAccountPlan) ? 'Upgrade Now' : 'Purchase Entity'}
                    </Button>
    }

    const onCancelClick = () => {
        props.handleInfoDialogClose();
        closeNewInvoiceClicked();
    }

    const closeNewInvoiceClicked = () => {
        props.newInvoiceClicked && props.setNewInvoiceClicked(false);
    }

    return (
        <Dialog fullScreen={false}
        open={props.openInfoDialog}
        onClose={props.handleInfoDialogClose}
        scroll="paper"
        classes={{
            paper: classes.MuiDialogpaper}}
        maxWidth="sm"
        fullWidth>
            {
                <DialogContent style={{padding:'16px'}}>
                    <Grid item container 
                        direction='column'
                        alignItems='center'
                        justifyContent='center'>
                        <Grid item>
                            <Typography variant='body1' >
                                {displayMessage()}
                            </Typography>
                        </Grid>
                        {props.fromEntitiesComponent && props.tabValue === 'archive' && props.entityDelete 
                            &&
                            <Grid item style={{marginTop:"8px"}}>
                                <Typography variant='body2'>
                                    Type "delete" to confirm
                                </Typography>
                                <TextField fullWidth
                                    color='secondary'
                                    value={deleteText}
                                    classes={{
                                        root: {
                                            '& .MuiOutlinedInput-input': {
                                                paddingTop: '8px',
                                                paddingBottom: '8px',
                                                paddingLeft:'8px',
                                                '& .Mui-focused' : {
                                                    border:'1px solid #ccc'
                                                }
                                            }
                                        }
                                    }}
                                    onChange={(e) => setDeleteText(e.target.value)}
                                />
                            </Grid>

                        }
                        <Grid item style={{marginTop:'16px'}}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    {ShowActionButton()}
                                </Grid>
                                {!isInvoiceAccountOnFreePlan(invoiceAccountPlan) && entityLimitExceeded &&
                                <Grid item>
                                    <Button 
                                        onClick={onDeleteEntityClick}
                                        className={classes.deleteButtonStyle}>
                                        Delete Entity
                                    </Button>
                                </Grid>}
                                <Grid item>
                                    <Button 
                                        onClick={onCancelClick}
                                        className={classes.noButtonStyle}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setGoToBilling: (goToBilling) => dispatch(setGoToBilling(goToBilling))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowInfoDialog);