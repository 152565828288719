import {getInvoiceAccountsListApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { logout } from './invoiceAuth';
import {clearSelectedInvoiceFilters} from './invoiceFilters';
import {SelectedDrawerState, setSelectedDrawerState} from './drawer';
import {clearSelectedTransactionFilters} from './transactionFilters';
import {getTDSTypeListApi} from '../services/authService';
import { setLoading } from './loading';

export const setCreateInvoiceAccount = (createInvoice) => ({
    type: 'SET_SHOW_CREATE_INVOICE',
    createInvoice
});

export const setCustomerObject = (customerObj) => ({
    type: 'SET_CUSTOMER_OBJECT',
    customerObj
});

export const setClearInvoiceAccounts= () => ({
    type: 'SET_CLEAR_INVOICE_ACCOUNTS',
});


export const setInvoiceAccountsList = (accountList) => ({
    type: 'SET_INVOICE_ACCOUNTS_LIST',
    accountList
});

export const setSelectedInvoiceAccount = (account) => ({
    type: 'SET_SELECTED_INVOICE_ACCOUNT',
    account
});

const clearRedux = (dispatch) => {
    dispatch(logout());
    dispatch(setClearInvoiceAccounts());
    dispatch(clearSelectedInvoiceFilters());
    dispatch(clearSelectedTransactionFilters());
    dispatch(setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD));
}

export const getInvoiceAccountsList = (selectedAccount) => {
    return (dispatch) => {
        getInvoiceAccountsListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response InvoiceAccountListApi ", res);
                if(res.length === 0 ) {
                    clearRedux(dispatch);
                } else {
                    dispatch(setInvoiceAccountsList(res));
                    //console.log('selected account', selectedAccount)
                    
                    let selected_invoice_account_id;
                    if (Object.keys(selectedAccount).length === 0) {
                        dispatch(setSelectedInvoiceAccount(res[0]))
                        dispatch(getTdsTypeList(res[0]?.id, res[0]?.country))
                    } else {//if selected org found, get its id
                        selected_invoice_account_id = selectedAccount.id;
                            //if found update its data
                            const updateSelectedAccount = res.find((acc) => acc.id === selected_invoice_account_id);
                            if (updateSelectedAccount) {
                                dispatch(setSelectedInvoiceAccount(updateSelectedAccount));
                                dispatch(getTdsTypeList(updateSelectedAccount?.id, updateSelectedAccount?.country))
                            }
                    }
                    
                }

            })
            .catch((e) => {
                consoleToLog('Error InvoiceAccountListApi ', e.response);
                dispatch(setLoading(false));
                if(e.response && e.response.status === 401) {
                    clearRedux(dispatch);
                }
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    };
}

export const getTdsTypeList = (invoice_account_id, country_code) => {
    return (dispatch) => {
    getTDSTypeListApi(invoice_account_id, country_code)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getTDSTypeListApi: ', res);

            dispatch(setTDSTypesList(res));
            dispatch(setLoading(false));
        })
        .catch((e) => {
            consoleToLog('Error getTDSTypeListApi', e.response);
            dispatch(setLoading(false));
            if(e.response && e.response.status === 401) {
                clearRedux(dispatch);
            }
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }
}

export const setEditInvoiceObj = (obj) => ({
    type: 'SET_EDIT_INVOICE_OBJECT',
    obj
});

export const setInvoiceItemObj = (invoice) => ({
    type: 'SET_INVOICE_OBJECT',
    invoice
});

export const setShowPreviewInvoiceScreen = (previewInvoiceScreen) => ({
    type: 'SET_SHOW_PREVIEW_INVOICE_SCREEN',
    previewInvoiceScreen
});

export const setShowSendInvoiceScreenType = (sendInvoiceScreenType) => ({
    type: 'SET_SHOW_SEND_INVOICE_SCREEN_TYPE',
    sendInvoiceScreenType
});

export const SendEmailType = {
    TAX: 'tax',
    PROFORMA: 'proforma',
    NONE: 'none'
}

export const setUserObj = (user_obj) => ({
    type: 'SET_USER_OBJ',
    user_obj
});

export const setTDSTypesList = (tdsTypeList) => ({
    type: 'SET_TDS_TYPE_LIST',
    tdsTypeList
});

export const setEntityObj = (entity) => ({
    type: 'SET_ENTITY_OBJ',
    entity
});

export const setGoToBilling = (goToBilling) => ({
    type: 'SET_GO_TO_BILLING',
    goToBilling
})