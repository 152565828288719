import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedTypeToInvoiceFilter, removeSelectedTypeFromInvoiceFilter} from '../../actions/invoiceFilters';
import { trackGAEvent } from '../../util/AppUtil';

const InvoiceTypeFilterComponent = (props) => {
    const invoiceTypeNameList = ["Proforma", "Tax", "None"];
    const invoiceTypeValueList = ["proforma", "tax", "none"];
    const invoiceTypeClassNames = ["proforma__invoice", "tax__invoice", "none__invoice"];
    const invoiceTypeClassNames1 = ["proforma__background", "tax__background", "none__background"];
    
    const invoiceTypeClassName = (index) => {
        const TYPE_CLASS = `status__filterList ${invoiceTypeClassNames1[index]}`;
        return props.selectedTypeInvoiceFilter.includes(invoiceTypeValueList[index]) ? `${TYPE_CLASS} active` : TYPE_CLASS;
    }

    const onInvoiceTypeClick = (selectedValue) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice filter - Invoice Type filter changed');
        const selectedStatus = props.selectedTypeInvoiceFilter.filter((type) => type === selectedValue);
        if(selectedStatus && selectedStatus.length > 0) {
            props.removeSelectedTypeFromInvoiceFilter(selectedValue);
        } else {
            props.addSelectedTypeToInvoiceFilter(selectedValue);
        }
    }

    return (
        <div style={{marginTop:'16px'}}>
           <span style={{fontSize:'14px'}}>
                Invoice Type            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {invoiceTypeNameList.map((type, index) => {
                        return <Grid item
                                    className={invoiceTypeClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={type}
                                    onClick={() => {
                                        onInvoiceTypeClick(invoiceTypeValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${invoiceTypeClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{type}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedTypeInvoiceFilter: state.invoiceFilters.selectedTypeInvoiceFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedTypeToInvoiceFilter: (item) => dispatch(addSelectedTypeToInvoiceFilter(item)),
    removeSelectedTypeFromInvoiceFilter: (item) => dispatch(removeSelectedTypeFromInvoiceFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTypeFilterComponent);