import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, Grid, Typography, Button, TextField} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import { isInvoiceStatusCancelled } from '../util/AppUtil';
import TransformIcon from '@material-ui/icons/Transform';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    },
    dialogActionImg: {
        width:'60px',
        marginBottom:'8px',
        display:'flex',
        justifyContent:'center'
    },
    dailogHeading: {
        fontSize:'21px',
        fontWeight:'600',
        marginBottom:'8px',
        textAlign:'center'
    },
    yesButtonStyle: {
        borderRadius: '5px',
        background: '#318415',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'#318415'
        }
    },
    noButtonStyle: {
        borderRadius: '5px',
        background: '#888',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'rgba(0,0,0,0.3)'
        },
    }
}))



const ShowConfirmDialog = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [deleteText, setDeleteText] = useState('');
    const [cancelRecurringInvoice, setCancelRecurringInvoice] = useState(false);

    const displayIcon = () => {

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return <ArchiveOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return <RestoreOutlinedIcon style={{fontSize:'45px', color:'#34A853'}}/>
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return <DeleteOutlineOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromInvoiceListComponent && !isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return <CancelOutlinedIcon style={{fontSize:'45px', color:'red'}}/>
        }

        if(props.fromInvoiceListComponent && isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return <TransformIcon style={{fontSize:'45px', color:'#828c98'}}/>
        }

        
    }

    const displayHeading = () => {

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return 'Archive Entity Confirmation';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return 'Restore Entity Confirmation';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return  `Delete Entity Confirmation`;
        }

        if(props.fromInvoiceListComponent && !isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return  `Cancel Invoice Confirmation`;
        }

        if(props.fromInvoiceListComponent && isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return  `Convert To Draft Confirmation`;
        }
    }

    const displayMessage = () => {

        if(props.fromEntitiesComponent && props.tabValue === 'active') {
            return 'Are you sure you want to archive this entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && !props.entityDelete) {
            return 'Are you sure you want to restore this entity?';
        }

        if(props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete) {
            return "Are you sure you want to delete the entity? All invoices and related data will be deleted.";
        }

        if(props.fromInvoiceListComponent && !isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return "Are you sure you want to cancel this invoice?";
        }

        if(props.fromInvoiceListComponent && isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            return "Are you sure you want to convert this invoice to draft?";
        }

    }

    const callCancelInvoiceApiOrConvertToDraftApiFromParent = () => {
        const invoice_account_id = props.invoiceItem?.invoice_account_id;
        const invoice_id = props.invoiceItem?.id;
        const cancel_recurring_invoice = cancelRecurringInvoice;
        const recurring_invoice_id = cancel_recurring_invoice ? props.invoiceItem?.recurring_invoice.id : undefined;

        if(!isInvoiceStatusCancelled(props.invoiceItem?.status)) {
            props.callCancelInvoiceApi(invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);
        } else {
            props.convertToDraftInvoice(invoice_account_id, invoice_id)
        }

    }

    const onYesClick = (e) => {

        if(props.fromEntitiesComponent && props.entityDelete) {
            if(!deleteText || deleteText !== 'delete') {
                enqueueSnackbar("Please type delete to confirm", {variant: 'error'});
                return;
            } else {
                props.callArchiveRestoreDeleteCompanyApi();
            }
        } else {
            if(props.fromInvoiceListComponent) {
                callCancelInvoiceApiOrConvertToDraftApiFromParent();
            } else {
                props.callArchiveRestoreDeleteCompanyApi();
            }
        }
        props.handleConfirmDialogClose();

    }

    const onNoClick = () => {
        props.handleConfirmDialogClose();
    }

    return (
        <Dialog fullScreen={false}
        open={props.openComfirmDialog}
        onClose={props.handleConfirmDialogClose}
        scroll="paper"
        classes={{
            paper: classes.MuiDialogpaper}}
        maxWidth="sm"
        fullWidth>
            {
                <DialogContent style={{padding:'16px'}}>
                    <Grid item container 
                        direction='column'
                        alignItems='center'
                        justifyContent='center'>
                        <Grid item className={classes.dialogActionImg}>
                            {displayIcon()}
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dailogHeading}>
                                {displayHeading()}
                            </Typography>
                            <Typography variant='body1'>
                                {displayMessage()}
                            </Typography>
                        </Grid>
                        {props.fromInvoiceListComponent && props.invoiceItem.recurring_invoice?.status === 'active' && 
                        !isInvoiceStatusCancelled(props.invoiceItem?.status) &&
                            <Grid item sm={12}>
                                <FormControlLabel control={
                                <Checkbox  
                                    onChange = {(e)=>{
                                        setCancelRecurringInvoice(e.target.checked)
                                    
                                    }}
                                    checked={cancelRecurringInvoice}
                                />
                                } 
                                label="Do you want to cancel recurring invoice as well?" />
                            </Grid>
                        }
                        {props.fromEntitiesComponent && props.tabValue === 'archived' && props.entityDelete 
                            &&
                            <Grid item style={{marginTop:"8px"}}>
                                <Typography variant='body2' style={{marginBottom:'-5px'}}>
                                    Type "delete" to confirm
                                </Typography>
                                <TextField fullWidth
                                    color='secondary'
                                    variant='outlined'
                                    value={deleteText}
                                    margin='dense'
                                    onChange={(e) => setDeleteText(e.target.value)}
                                />
                            </Grid>

                        }
                        <Grid item style={{marginTop:'16px'}}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <Button 
                                        onClick={onYesClick}
                                        className={classes.yesButtonStyle}>
                                        Yes
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        onClick={onNoClick}
                                        className={classes.noButtonStyle}>
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }
        </Dialog>
    );
}

export default ShowConfirmDialog;