import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Button,
  Typography,
  Tooltip,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import TaxPresets from "./TaxPresets";
import InvoiceUsers from "./InvoiceUsers";
import InvoiceDisplaySettings from "./InvoiceDisplaySettings";
import EmailSettings from "./EmailSettings";
import GeneralSettingsComponent from "./GeneralSettingsComponent";
import InvoiceItemsComponent from "./InvoiceItemsComponent";
import { connect } from "react-redux";
import InvoiceAccountInfo from "./InvoiceAccountInfo";
import { AccessLevel, consoleToLog } from "../util/AppUtil";
import BanksComponent from "./BanksComponent";
import AddEditBankDrawer from "./AddEditBankDrawer";
import AddEditInvoiceItemsDrawer from "./AddEditInvoiceItemsDrawer";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateTaxPresetDrawer from "./CreateTaxPresetDrawer";
import { history } from "../routers/AppRouter";
import {useLocation} from 'react-router-dom';
import BillingComponent from "./BillingComponent";
import {setHeightForComponent} from '../util/AppUtil';
import { setGoToBilling } from "../actions/invoiceAccount";
import ShowInfoDialog from './ShowInfoDialog';
import { usePropsContext } from './context';
import { AppRoutes } from "../util/AppUtil";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: theme.palette.primary.light,
    transition: ".5s",
    flexGrow: 1,
    //overflow:'hidden',
    height: "calc(100vh - 48px)",
    overflow: "auto",
    overflowX: "hidden",
  },
  container: {
    marginTop: "8px",
  },
  tab: {
    flexGrow: 1,
    borderRadius: 0,
    borderBottom: "1px solid #c5b9b9",
  },
  settingsContainer: {
    width: "100%",
    background: "#fff",
    borderRadius: "4px",
    padding: "16px 24px",
    marginTop: "16px",
    marginBottom: "32px",
  },
  addButton: {
    color: "white",
    backgroundColor: "#3d3d3d",
    marginLeft: "auto",
    height: "30px",
    marginTop: "9px",
    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#3d3d3d"
    },
    marginRight: "8px"
  },
  buttonPlusIcon: {
    //marginLeft:'8px', 
    fontSize:'1.2rem', 
    alignItems:'center'
  },
  muiTab: {
    fontSize:'14px',
    minWidth:"130px",
    [theme.breakpoints.down(1400)] : {
        minWidth: '100px'
    }
  } 
}));

const ButtonInTabs = ({ className, onClick, children, disabled }) => {
  return <Button 
          className={className} 
          onClick={onClick} 
          children={children}
          disabled={disabled}
          style={{color:'white'}}
          />;
};



const SettingsComponent = (props) => {
  const classes = useStyles();

  const [openBankDrawerFromParent, setOpenBankDrawerFromParent] = useState(false);
  const [bankAccountObj, setBankAccountObj] = useState({});
  const [value, setValue] = useState(0);
  const [itemObj, setItemObj] = useState({});
  const [itemList, setItemList] = useState([]);
  const [openTaxPresetDrawer, setOpenTaxPresetDrawer] = useState(false);
  const [taxPresetObj,setTaxPresetObj]=useState(undefined);
  const [openInvoiceItemsdrawerFromParent, setOpenInvoiceItemsdrawerFromParent]=useState(false);
  const [openTaxPresetDrawerFromParent, setOpenTaxPresetDrawerFromParent]=useState(false);
  const [openEntityDrawerFromParent, setOpenEntityDrawerFromParent] = useState(false);
  const location = useLocation();

  const { fullName, handleInfoDialogOpen } = usePropsContext();

  useEffect(() => {
    document.title = `Settings - ${fullName}`;
    if(location.state?.verifyEmail) {
      setValue(0);
      history.push(AppRoutes.SETTINGS);
    }

    if(location.state?.taxPreset) {
      setValue(2);
      history.push(AppRoutes.SETTINGS);
    }

    if(location.state?.gotToEmailSettings) {
      setValue(3);
      history.push(AppRoutes.SETTINGS);
    }

    if(location?.pathname === AppRoutes.BILLING) {
      setValue(6);
    }
}, [location?.pathname]);

  useEffect(() => {
    if(props.goToBilling) {
      setValue(6);
      props.setGoToBilling(false);
    }
  }, [props.goToBilling]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 6) {
      history.push(`${AppRoutes.SETTINGS}/billing`);
    } else {
      history.push(`${AppRoutes.SETTINGS}`);
    }
  };


  const is_email_verified = props.selectedAccount?.is_email_verified;
  const canCreateEntity = props.selectedAccount?.can_create_entity;

  const { access_level } = props.selectedAccount || {};
  const accessLevelViewer =
    AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER
      ? true
      : false;

  const handleBankDrawerOpenFromParent= () => {
    setOpenBankDrawerFromParent(true);
    };

  const handleBankDrawerCloseFromParent = () => {
    setOpenBankDrawerFromParent(false);
    };   

  const handleTaxPresetDrawerOpen = () => {
    setOpenTaxPresetDrawer(true);
    }

  const handleTaxPresetDrawerClose = () => {
    setOpenTaxPresetDrawer(false);
    }

  const handleInvoiceItemsDrawerOpenFromParent= () => {
    setOpenInvoiceItemsdrawerFromParent(true);
    }

  const handleInvoiceItemsDrawerCloseFromParent = () => {
    setOpenInvoiceItemsdrawerFromParent(false);
  }

  const handleTaxPresetDrawerOpenFromParent= () => {
    setOpenTaxPresetDrawerFromParent(true);
  }

  const handleTaxPresetDrawerCloseFromParent = () => {
    setOpenTaxPresetDrawerFromParent(false);
  }

  const handleEntityDrawerOpenFromParent = () => {
    if(canCreateEntity) {
      setOpenEntityDrawerFromParent(true);
    } else {
      handleInfoDialogOpen();
    }
  }

  const handleEntityDrawerCloseFromParent = () => {
    setOpenEntityDrawerFromParent(false);
  }


  const onAddItemsClick = (obj) => {
      setItemList(oldItems => [...oldItems,obj]);
    }

  const onUpdateLocalList = (obj) => {
  
    let updatedArray = itemList.map((item) => {
        if(item.id === obj.id) {
            item = obj;
        }
        return item;
    });
  
    setItemList(updatedArray);
    setItemObj({});
  }

  return (
    <div
      className={classes.mainContent}>
      <Container className={classes.container} maxWidth="xl">

        <Grid item container direction="column" style={{ marginTop: "3px" }}>
          <Grid item container>
            <Paper
              className={classes.tab}
              elevation={1}
              style={{ marginTop: "10px" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Entities" 
                  classes={{
                    root: classes.muiTab
                }}/>
                {!accessLevelViewer &&
                <Tab label="Preferences" 
                  classes={{
                    root: classes.muiTab
                }}/>}
                {!accessLevelViewer &&
                <Tab label="Tax Presets" 
                  classes={{
                    root: classes.muiTab
                  }}/>
                }
                <Tab label="Email" 
                  classes={{
                    root: classes.muiTab
                  }}/>
                <Tab label="Invoice Items" 
                  classes={{
                    root: classes.muiTab
                  }}/>
                <Tab label="Banks" 
                  classes={{
                    root: classes.muiTab
                  }}/>
                {!accessLevelViewer &&
                <Tab label="Billing" 
                  classes={{
                    root: classes.muiTab
                  }}/>
                }

                {
                  value === 0 && !accessLevelViewer &&
                    <ButtonInTabs
                        onClick={handleEntityDrawerOpenFromParent}
                        className={classes.addButton}
                        disabled={accessLevelViewer}
                      >
                      Add Account Entity
                    <AddCircleOutlineIcon fontSize='small' 
                        style={{fontSize:'15px', marginLeft:'4px'}}
                        className={classes.buttonPlusIcon}/>
                    </ButtonInTabs>
                }

                {

                  value === 2 && !accessLevelViewer &&
                    <ButtonInTabs
                      onClick={handleTaxPresetDrawerOpenFromParent}
                      className={classes.addButton}
                      disabled={accessLevelViewer}
                    >
                      Add Tax Preset
                    <AddCircleOutlineIcon fontSize='small' 
                      style={{fontSize:'15px', marginLeft:'4px'}}
                      className={classes.buttonPlusIcon}/>
                    </ButtonInTabs>
                  
                }
                
                {
                  value === 4 && !accessLevelViewer &&
                    <ButtonInTabs
                        onClick={handleInvoiceItemsDrawerOpenFromParent}
                        className={classes.addButton}
                        disabled={accessLevelViewer}
                      >
                      Add Invoice Items
                      <AddCircleOutlineIcon fontSize='small' 
                        style={{fontSize:'15px', marginLeft:'4px'}}
                        className={classes.buttonPlusIcon}/>
                    </ButtonInTabs>
                }

                {
                  value === 5 && !accessLevelViewer &&
                    <ButtonInTabs
                        onClick={handleBankDrawerOpenFromParent}
                        className={classes.addButton}
                        disabled={accessLevelViewer}
                      >
                      Add Bank Account
                      <AddCircleOutlineIcon fontSize='small' 
                        style={{fontSize:'15px', marginLeft:'4px'}}
                        className={classes.buttonPlusIcon}/>
                    </ButtonInTabs>
                }
              </Tabs>
            </Paper>
          </Grid>
          <Grid item style={{ backgroundColor: "white", marginBottom: "1rem" }}>
            {value === 0 && <InvoiceAccountInfo openEntityDrawerFromParent={openEntityDrawerFromParent}
                              handleEntityDrawerCloseFromParent={handleEntityDrawerCloseFromParent}
                              value={value}
                              setValue={setValue}/>}
            {value === 1 && !accessLevelViewer && <GeneralSettingsComponent />}
            {value === 2 && !accessLevelViewer && <TaxPresets 
                              openTaxPresetDrawerFromParent={openTaxPresetDrawerFromParent}
                              handleTaxPresetDrawerCloseFromParent={handleTaxPresetDrawerCloseFromParent}
                            />}
            {(value === 3 && !accessLevelViewer || accessLevelViewer && value === 1) && <EmailSettings />}
            {(value === 4 && !accessLevelViewer || accessLevelViewer && value === 2) && <InvoiceItemsComponent 
                              openInvoiceItemsdrawerFromParent={openInvoiceItemsdrawerFromParent}
                              handleInvoiceItemsDrawerCloseFromParent={handleInvoiceItemsDrawerCloseFromParent}
                            />}
            {(value === 5 && !accessLevelViewer || accessLevelViewer && value === 3) && <BanksComponent 
                              openBankDrawerFromParent={openBankDrawerFromParent}
                              handleBankDrawerCloseFromParent={handleBankDrawerCloseFromParent}
                            />}
            {value === 6 && !accessLevelViewer && window.location.pathname !== AppRoutes.SETTINGS &&
                            <BillingComponent 
                              openBankDrawerFromParent={openBankDrawerFromParent}
                              handleBankDrawerCloseFromParent={handleBankDrawerCloseFromParent}
                            />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.invoiceAccounts.selectedAccount,
  goToBilling: state.invoiceAccounts.goToBilling
});

export const mapDispatchToProps = (dispatch) => ({
  setGoToBilling: (goToBilling) => dispatch(setGoToBilling(goToBilling))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
