export const SelectedDrawerState  = {
    SHOW_DASHBOARD  : 'dashboard',
    SHOW_INVOICES : 'invoices',
    SHOW_TRANSACTIONS : 'transactions',
    SHOW_SETTINGS : 'settings',
    SHOW_CLIENTS : 'clients',
    SHOW_PROFILE : 'profile',
    SHOW_INVOICE_ACCOUNTS : 'invoice_accounts',
    SHOW_MEMBERS: 'members',
    SHOW_ONBOARDING: 'onboarding',
    SHOW_CLIENT_REPORTS: 'client_report',
    SHOW_ITEM_REPORTS: 'item_report'
};

export const setSelectedDrawerState = (drawerState) =>  ({
    type: 'SET_SELECTED_DRAWER_STATE',
    drawerState
})