import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar,Tabs, Paper, Tab} from '@material-ui/core';
import ClientInfo from './ClientInfo';
import ClientStatistics from './ClientStatistics';
import ClientInvoices from './ClientInvoices';
import ClientTransactions from './ClientTransactions';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ClientCustomFields from './ClientCustomFields';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ContactComponent from './ContactComponent';
import ContactDrawer from './ContactDrawer';
import { usePropsContext } from '../context';
import useRestoreComponentState from '../useRestoreComponentState';

const useStyles = makeStyles((theme) => ({
    topBarStyles: {
        padding:'24px 12px',
        background:'#f2f6ff',
        borderBottom:'1px solid rgba(0,0,0,0.1)'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    buttonPlusIcon: {
        //marginLeft:'8px', 
        marginRight: "8px",
        fontSize:'1.2rem', 
        alignItems:'center'
    }  
}))

const ClientDetails = (props) => {
    const classes = useStyles();

    const [value, setValue] = useState(0);
    const [addCustomField, setAddCustomField] = useState(false);
    const [openContactDrawerFromParent, setOpenContactDrawerFromParent]=useState(false);
    const [contactObj, setContactObj] = useState({});
    const [newClientObj,setNewClientObj]=useState({});
    const [contactArr , setContactArr]=useState({});
    const [userDefinedFieldsFromParent, setUserDefinedFieldsFromParent] = useState(props.showClientInfo?.user_defined_field);
    
    const {invoicesComponentState, transactionsComponentState, setInvoiceComponentState, setTransactionComponentState} = usePropsContext();

    const { restoreState } = useRestoreComponentState(
        invoicesComponentState || transactionsComponentState, 
    );

    const restoredState = restoreState();

    useEffect(() => {
        if(Object.keys(restoredState).length > 0) {
            setValue(restoredState?.tabValue);
        }

    }, [restoredState]);

    useEffect(() => {
        if(props.showClientInfo && props.scrollContainer && Object.keys(restoredState).length > 0) {
            
        }
    }, [props.showClientInfo])
    
    const handleChange = (event, newValue) => {
        clearInvoiceAndTransactionComponentState();
        setValue(newValue);
    };

    const ButtonInTabs = ({ className, onClick, children }) => {
        return <Button 
                className={className} 
                onClick={onClick} 
                children={children}
                />;
    };

    const onAddCustomFieldClick = () => {
        setAddCustomField(true);
    }

    const handleContactDrawerOpenFromParent=()=>{
        setOpenContactDrawerFromParent(true);
    }

    const handleContactDrawerCloseFromParent=()=>{
        setOpenContactDrawerFromParent(false);
    }

    const clearInvoiceAndTransactionComponentState = () => {
        invoicesComponentState && setInvoiceComponentState(undefined);
        transactionsComponentState && setTransactionComponentState(undefined);
    }

    const clearClientInfoState = () => {
        props.setShowClientInfo(undefined);
        clearInvoiceAndTransactionComponentState();
    }

    return (
        <div className={classes.container}>
            <Grid item container direction='column'>
                <Grid item className={classes.topBarStyles}>
                    <Grid item container alignItems='center'>
                        <Grid item>
                            <Button variant='outlined' color='primary' 
                                onClick={clearClientInfoState}
                                className={classes.backIcon}>
                                <ArrowLeftIcon />
                            </Button>
                        </Grid>

                        <Grid item style={{marginLeft:'16px'}}>
                            <Typography variant="body1" 
                                className={classes.clientName}>
                                {props.showClientInfo?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid item sm={12}>
                    <Grid item container direction="column">
                        <Grid item container>
                            <Paper className={classes.tab} elevation={1}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Client Statistics" style={{marginLeft:'-14px'}}/>
                                    <Tab label="Client Info" style={{marginLeft:'-14px'}}/>
                                    <Tab label="Invoices" style={{marginLeft:'-14px'}}/>
                                    <Tab label="Transactions" style={{marginLeft:'-14px'}}/>
                                    <Tab label="Custom Fields" style={{marginLeft:'-14px'}}/>
                                    <Tab label="Contact" style={{marginLeft:'-14px'}}/>
                                    {value === 4 &&
                                    <ButtonInTabs
                                        onClick={onAddCustomFieldClick}
                                        className={classes.addButton}
                                    >
                                    <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                        Add Custom Field
                                    </ButtonInTabs>}
                                    {/* {value === 5 &&
                                    <ButtonInTabs
                                        onClick={handleContactDrawerOpenFromParent}
                                        className={classes.addButton}
                                    >
                                    <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                        Add Contact
                                    </ButtonInTabs>} */}
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid item md={12} style={{backgroundColor:"white", marginBottom:"1rem", padding: value !== 2 && value !== 3 && "16px 0px"}}>
                            {value === 0 && <ClientStatistics clientInfo={props.showClientInfo}/>}
                            {value === 1 && <ClientInfo clientInfo={props.showClientInfo}/>}
                            {value === 2 && <ClientInvoices clientInfo={props.showClientInfo}
                                                handleInvoiceDialogOpen={props.handleInvoiceDialogOpen}
                                                updatedInvoiceObj={props.updatedInvoiceObj}
                                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                                clientComponentObj={props.clientComponentObj}
                                                tabValue={value}
                                                showClientInfo={props.showClientInfo}
                                                scrollContainer={props.scrollContainer}
                                            />}
                            {value === 3 && <ClientTransactions clientInfo={props.showClientInfo}
                                                updateTranscItemFromParent={props.updateTranscItemFromParent}
                                                setUpdateTranscItemFromParent={props.setUpdateTranscItemFromParent}
                                                handleInvoiceDialogOpen={props.handleInvoiceDialogOpen}
                                                setAddTransaction={props.setAddTransaction}
                                                addTransaction={props.addTransaction}
                                                clientComponentObj={props.clientComponentObj}
                                                tabValue={value}
                                                showClientInfo={props.showClientInfo}
                                                scrollContainer={props.scrollContainer}
                                                />}
                            {value === 4 && <ClientCustomFields clientInfo={props.showClientInfo} 
                                                addCustomField={addCustomField}
                                                setAddCustomField={setAddCustomField}
                                                userDefinedFieldsFromParent={
                                                Array.isArray(userDefinedFieldsFromParent) ? userDefinedFieldsFromParent : JSON.parse(userDefinedFieldsFromParent)}
                                                setUserDefinedFieldsFromParent={setUserDefinedFieldsFromParent}
                                                /> }
                            {value === 5 && <ContactComponent
                                                    clientInfo={props.showClientInfo}
                                                    setClientInfo={props.setShowClientInfo}   
                                                    newClientObj={props.newClientObj}
                                                    setNewClientObj={props.setNewClientObj}
                                                    openContactDrawerFromParent={openContactDrawerFromParent}
                                                    handleContactDrawerCloseFromParent={handleContactDrawerCloseFromParent}
                                                    fromClientDetails={true}
                                                /> }                    

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default ClientDetails;